/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TutorialModal from "../../components/Shared/TutorialModal";
import tutorialText from "config/tutorialText";

import defaultImage from "../../assets/img/header.jpg"
import "../../assets/css/custom-css.css"

// reactstrap components
import {
    Button,
    ButtonGroup,
    Container,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input,
    InputGroup

} from "reactstrap";
function HowItWorks() {

    const [podcastData, setPodcastData] = useState([])
    const [trendingData, setTrendingData] = useState([])
    const [suggestedData, setSuggestedData] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [searchBar, setSearchBar] = useState("")
    const [tagOption, setTagOption] = useState("all")
    const [selectedPodcast, setSelectedPodcast] = useState("")
    const [showTutorial, setShowTutorial] = useState(true);

    const navigation = useNavigate()

    const getPodcasts = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_BY_TOPIC}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setSearchResults(res.data)
                    setPodcastData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }


    const getTrending = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_TRENDING}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setTrendingData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }


    const getSuggested = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_SUGGESTED}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setSuggestedData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }

    const handleSearchBar = (query) => {
        setSearchBar(query)
        const originalSearch = podcastData
        const searchName = originalSearch.filter((e) => e.name.toLowerCase().includes(query.toLowerCase()))

        setSearchResults(searchName)

        // When topics come in, this should work
        //const searchTag = searchName.filter((e) => e.topic.includes(tagOption))
        //setSearchResults(searchTag)
        
    }

    const handleClearSearch = () => {
        setSearchResults(podcastData)
        setSearchBar("")
    }

    const handleSelectPodcast = (podcastId) => {
        navigation(`/player?podcastId=${podcastId}`)
    }


    useEffect(() => {
        getPodcasts();
        getTrending();
        getSuggested();
    }, []);

    
    

    return (
        <>
            <div className="content sitePadding" style={{ marginTop: 78, paddingLeft: "12%", paddingRight: "12%" }}>
                
                    <Row>
                    <Col md="12">
                                <CardBody>
                                    <Col className="text-white">
                                        <h3 className="text-center" style={{color: "white"}}><b>How It Works</b></h3>
                                        <p className="text-center pb-2">Klaxon AI was designed to democratise podcasting, enabling anyone anywhere with an internet connection to create one-off or regular podcasts without the need for any recording equipment, the creation of a script, a ‘broadcast’ voice, or any special skills or expertise.</p>
                                <p className="text-center pb-2">And best of all, you generate an income from each podcast episode you create once it’s had just 50 listens, even if you have a <strong>FREE</strong> account!</p>
                                        
                                        <p className="pb-2">Here's how it works:</p>
                                        <h3 className=" pb-2">1.	CHOOSE YOUR TOPIC</h3>
                                <p className=" pb-2">Simply choose what you want your podcast (or podcast series) to be about, anything from local news, sport or team updates, business information, home baking, fishing, or anything else. Your podcasts are unique to you so be creative and choose any topics you like (you can choose up to 5 different topics for your podcast). With Klaxon AI, you can create as many different podcasts (or podcast series) as you like, depending on the number of minutes you have available in your account (Free account users have 200 minutes per month and Premium account users have 1,000 minutes per month).</p>

                                <h3 className=" pb-2">2.	SELECT FREQUENCY & DURATION</h3>
                                <p className=" pb-2">Select the frequency of your podcasts (one-off, daily, weekly, fortnightly, or monthly) and their duration (from five minute to 30 minute episodes).</p> 

                                <h3 className=" pb-2">3.	SCRIPT CREATION (Where the magic really starts to happen!)</h3>
                                <p className=" pb-2">Our clever system automatically scans more than 60,000 sources from across the web before creating a unique podcast script for you focused specifically on the latest and most relevant content on your selected topics. And if you’ve chosen to have regular episodes of your podcast (a series), the system automatically creates a new script for every episode just before it is published so your podcasts are always up to date with the latest news and content on your topics. We call this feature ‘Set and Forget’ and it is a great way for a business or an individual to be seen as an expert in their field without having to manually create new podcasts every day, week, fortnight, or month!</p>

                                <h3 className=" pb-2">4.	EDITING (optional)</h3>
                                <p className=" pb-2">If you’re happy with the script, great, you can move onto the next step. But if you want to change anything about it, you can easily edit and update it (and you’ll receive an email before each future episode is published so you can do the same for them too before they are made live).</p>
                                <h3 className=" pb-2">5.	CHOOSE VOICE & LANGUAGE</h3>
                                <p className=" pb-2">Now you choose a voice and language for your podcast (because who likes hearing their own voice or can speak every language?!). Pick from a wide range of different voice accents, tones, and styles, and the language you want your podcast(s) to be broadcast in, and listen to examples. Free account holders have a limited number of voices and languages to choose from; Premium subscribers have access to hundreds of different voices and languages including Spanish, French, Hindi, Mandarin, and many more, meaning they can create podcasts for any audience in the world. A great feature not just for individuals but also for businesses that want to reach out to potential new customers in different markets.</p>
                                <h3 className=" pb-2">6.	PODCAST GENERATION</h3>
                                <p className=" pb-2">Your podcast is now generated based on your above selections, ready for you to preview and publish it. Once you select ‘Publish’, it goes live on our site and can be found in our podcast library (and could be featured on our homepage or in our top podcasts list!). You can see a list of your podcasts in your account and how they are performing on your dashboard.</p>
                                <h3 className=" pb-2">7.	SHARE & EARN</h3>
                                <p className=" pb-2">Share a link to your podcast(s) with your friends, contacts, and social media followers to drive up the number of listens each episode gets. We automatically insert adverts into your podcasts and share the revenue from these with you once your podcast or episode has had just 50 listens. And the more listens your podcast or episode gets, the greater the share of the revenue you earn! </p>
                                    </Col>

                                </CardBody>
                            
                        </Col>
                    </Row>
               
                
            </div>
        </>
    );
}

export default HowItWorks;
