import { Row, Col, FormGroup, Input, Label, Spinner } from "reactstrap"
import { useEffect, useState } from "react"
import GeneratedAd from "../AdWizardComps/GeneratedAd"
import { FileUploader } from "react-drag-drop-files";
import Select from "react-select";
import WizardPlayer from "../AdWizardComps/audioEditorComp/WizardPlayer";

const WizardAdCreate = (props) => {
    const { setModalPage, baseObject, setBaseObject, handleAddAdvert, regenerateContent, handlePreviewVoice, voices, music, isGenerating, singleLoadId, setIsOwnAdvert, isGenerate, setIsGenerate } = props
    //const [isGenerate, setIsGenerate] = useState(true)
    const [isExactText, setIsExactText] = useState(false)
    const [selectedAd, setSelectedAd] = useState(0)

    const fileTypes = ["MP3", "WAVE", "OGG"]
    const [file, setFile] = useState(null)
    const handleFileChange = (uploadedFile) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1]
            setFile(base64String);
            setBaseObject({...baseObject, uploadedBase64: base64String})
        };

        if (uploadedFile) {
            reader.readAsDataURL(uploadedFile); // Convert the file to base64
        }
    };

    const [singeSelectDuration, setSingleSelectDuration] = useState({ label: "30 seconds", id: 6 })
    const [durations, setDurations] = useState()
    const handleDurationChange = (value) => {
        setSingleSelectDuration(value)
        setBaseObject({ ...baseObject, length: value.id })
    }

    const handleSelectAd = (id) => {
        setSelectedAd(id)
        setBaseObject({ ...baseObject, selectedContent: id })
    }

    const generateDurations = () => {
        const options = [];
        //for (let i = 1; i <= 12; i++) {
        //    const label = `${i * 5} seconds`;
        //    options.push({ label, id: i });
        //}

        options.push(
            { label: "30 seconds", id: 6 }
        )
        setDurations(options);
    };

    useEffect(() => {
        generateDurations()
    }, [])

    useEffect(() => {
        if (baseObject?.content && selectedAd === 0) {
            setSelectedAd(baseObject.content[0].id)
        }
    }, [baseObject?.content])

    const handleSetGeneration = (isExact) => {
        setIsExactText(isExact)
        setBaseObject({ ...baseObject, isExactScript: isExact })
    }

    useEffect(() => {
        if (!isGenerate) {
            if (file) {
                setIsOwnAdvert(true)
            } else {
                setIsOwnAdvert(false)
            }
        } else {
            setIsOwnAdvert(false)
        }
    }, [isGenerate, file])

    useEffect(() => {
        if (isGenerate) {
            setBaseObject({ ...baseObject, uploadedBase64: null})
        }
    }, [isGenerate])

    useEffect(() => {
        if (baseObject.uploadedBase64 != null) {
            setFile(baseObject.uploadedBase64)
        }
    }, [baseObject])

    return (
        <div className="">
            <Col>
                <Row className="justify-content-between mt-4">
                    <Col style={{ borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column" }} className={`${isGenerate && "fadedKlaxonRed"} borderLight`} sm="" onClick={() => setIsGenerate(true)}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Generate Advert</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Use Klaxon.AI to generate an advert for you.</p>
                    </Col>
                    <Col style={{ borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column" }} className={`${!isGenerate && "fadedKlaxonRed"} borderLight`} sm="" onClick={() => setIsGenerate(false)}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Upload Advert</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Use an existing advert.</p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    {isGenerate ? <div style={{ width: "100%" }}>

                        Generate Advert

                        <Row>
                            {/*<Col className="pr-0">*/}
                            {/*    <FormGroup>*/}
                            {/*        <Label>Campaign Name</Label>*/}

                            {/*        <Input style={{height: 40}} className="inputStyle placeholderWhite" placeholder="Campaign Name" value={baseObject.name} onChange={(e) => setBaseObject({ ...baseObject, name: e.target.value }) } />*/}

                            {/*    </FormGroup>*/}
                            {/*</Col>*/}
                            <Col sm="6">
                                <FormGroup className="">
                                    <Label>Length</Label>

                                    <Select
                                        className="react-select "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={singeSelectDuration}
                                        onChange={(value) => handleDurationChange(value)}
                                        options={durations}

                                    />

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6" style={{ paddingRight: 15 }}>
                                <FormGroup className="">
                                    <Label>Script</Label>

                                    <Input className="inputStyle placeholderWhite reviewInput" placeholder="Enter script" type="textarea" value={baseObject.script} onChange={(e) => {
                                        if (e.target.value.length <= 250) {
                                            // Adjust textarea height
                                            e.target.style.height = 'auto'; // Reset height to auto
                                            e.target.style.height = `${e.target.scrollHeight}px`; // Set height to scrollHeight
                                            e.target.style.maxHeight = '200px'; // Remove maxHeight limit;
                                            e.target.style.overflowY = 'auto'; // Enable vertical scrollbar when content exceeds maxHeight
                                            e.target.style.overflowX = 'auto'; // Enable horizontal scrollbar if content overflows horizontally
                                            e.target.style.setProperty('border', '1px solid transparent', 'important');

                                        } else {
                                            e.target.style.setProperty('border', '1px solid #F33B30', 'important');
                                            return
                                        }
                                        setBaseObject({ ...baseObject, script: e.target.value });
                                    }} style={{ minHeight: 100 }}
                                        onBlur={(e) => {
                                            e.target.style.setProperty('border', '1px solid transparent', 'important');
                                        }}
                                    />
                                    <p style={{ margin: 0, textAlign: "right" }}>{baseObject.script ? baseObject.script.length : 0}/250</p>
                                    <div style={{ display: "flex", gap: 15 }}>
                                        <FormGroup check>
                                            <Label check >
                                                <Input type="checkbox" checked={!isExactText} onClick={() => handleSetGeneration(false)} />
                                                <span className="form-check-sign" />
                                                Suggest Text Using Klaxon.AI
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check >
                                                <Input type="checkbox" checked={isExactText} onClick={() => handleSetGeneration(true)} />
                                                <span className="form-check-sign" />
                                                Use Exact Text
                                            </Label>
                                        </FormGroup>
                                    </div>

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                {baseObject.id === 0 &&
                                    (
                                        isGenerating ?
                                            <button type="submit" disabled style={{ minWidth: "100%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}><i className="fa fa-list-alt" />
                                                GENERATE ADS (1 CREDIT) <Spinner size="sm" />
                                            </button>
                                            :
                                            <button onClick={() => handleAddAdvert()} type="submit" style={{ minWidth: "100%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}><i className="fa fa-list-alt" />
                                                GENERATE ADS (1 CREDIT)
                                            </button>
                                    )
                                }

                                {/*<button onClick={() => setModalPage("voice")} type="submit" style={{ minWidth: "100%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}><i className="fa fa-list-alt" />*/}
                                {/*    GENERATE ADS (1 CREDIT)*/}
                                {/*    {isGenerating &&*/}
                                {/*        <Spinner size="sm" />*/}
                                {/*    }*/}
                                {/*</button>*/}
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <div className="mt-2 mb-2">
                                    Generated Ads
                                </div>
                                {!baseObject?.content ?
                                    <div style={{ width: "100%", backgroundColor: "#F33B30", border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", padding: 10, margin: "auto", display: "flex", flexDirection: "column", gap: 10 }}>
                                        <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                                            <i className="fa fa-info-circle" />
                                            Your Generated Ads
                                        </div>
                                        <div>Your Klaxon.AI ads will appear here once you have generated them.</div>
                                    </div>
                                    :
                                    <div>
                                        {baseObject?.content && baseObject?.content.length > 0 &&
                                            baseObject?.content.map((ad, index) => {
                                                return (
                                                    <Row key={index} >
                                                        <Col style={{ padding: 0, display: "flex", marginTop: 15, justifyContent: "flex-end" }}>
                                                            <FormGroup check>
                                                                <Label check >
                                                                    <Input type="checkbox" checked={selectedAd === ad.id} onClick={() => handleSelectAd(ad.id)} />
                                                                    <span className="form-check-sign" />
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="11">
                                                            <GeneratedAd advert={ad} setModalPage={setModalPage} regenerateContent={regenerateContent} handlePreviewVoice={handlePreviewVoice} voices={voices} music={music} isFirst={index === 0} singleLoadId={singleLoadId} />

                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </Col>
                        </Row>

                    </div>
                        :
                        <div style={{ width: "100%" }}>
                            {!file ?
                                <Col>
                                    <div>Upload Advert</div>
                                    <div style={{ backgroundColor: "" }}>
                                        <FileUploader handleChange={handleFileChange} name="file" types={fileTypes} classes="wideUpload klaxonColorUpload" label="Click to upload or drag and drop" />
                                    </div>
                                </Col> :
                                <Col className="mt-4 mb-4 pb-4">
                                    <div>Preview Advert</div>
                                    <div>
                                        <WizardPlayer fileURL={file} />
                                    </div>
                                </Col>
                            }

                        </div>
                    }

                </Row>


            </Col>
        </div>
    )

}

export default WizardAdCreate 