import { useState, useEffect } from "react"
import { Row, Col, Button, FormGroup, Form, Label, Badge, Spinner } from "reactstrap"
import WizardPlayer from "./audioEditorComp/WizardPlayer"

const GeneratedAd = ({ advert, isLanguage, setModalPage, regenerateContent, handlePreviewVoice, voices, music, isFirst, singleLoadId }) => {

    const [isExpanded, setIsExpanded] = useState(false)
    const [selectedTitleVoice, setSelectedTitleVoice] = useState("")
    const [selectedTitleMusic, setSelectedTitleMusic] = useState("")
    const [isMusicPlaying, setIsMusicPlaying] = useState(false)
    const [isVoicePlaying, setIsVoicePlaying] = useState(false)

    const tempMusicLookup = {
        1: "Smooth Jazz",
        2: "John Johnson and John's son",
        3: "Awesome music",
        4: "Real song 32"
    }

    const handleGenerateAds = (id) => {
        if (!isLanguage) {
            regenerateContent(id)
        }
    }

    const handleExpand = (e) => {

        if (!e.target.closest('.ignorBut')) {
            setIsExpanded(!isExpanded)
        }

    }

    const [voiceTags, setVoiceTags] = useState([])
    const [musicTags, setMusicTags] = useState([])
    const formatTags = (vt, mt) => {

        const colorCodes = {
            3: "#bf7fff",
            5: "#aec6cf",
            2: "#ff7f7f",
            1: "#ffa94d",
            4: "#7fc7ff"
        }


        if (vt) {
            const labelArr = vt.labels;
            let labelArrObject
            //console.log(labelArr)
            if (labelArr && labelArr.length > 0) {
                labelArrObject = labelArr.map((tag) => {
                    return {
                        label: tag.name,
                        value: tag.name,
                        textColor: colorCodes[tag.typeId]
                    }
                })
            } else {
                return null
            }


            setVoiceTags(labelArrObject)
        }

        if (mt) {
            const labelArr = mt.tags
            let labelArrObject

            if (labelArr && labelArr.length > 0) {
                const colorValues = Object.values(colorCodes)
                labelArrObject = labelArr.map((tag, index) => {
                    return {
                        label: tag.name,
                        value: tag.name,
                        textColor: colorValues[index % colorValues.length]
                    }
                })

            } else {
                return null
            }

            setMusicTags(labelArrObject)
        }

    }

    const populateTitles = () => {


        const voiceTemp = voices.find((voice) => voice?.voiceId == advert.voiceId)
        const musicTemp = music.find((song) => song?.id == advert.musicId)

        //console.log(advert)
        //console.log("selectedmusic", voiceTemp)
        if (!voiceTemp) {
            setSelectedTitleVoice(null)
        } else {
            setSelectedTitleVoice(voiceTemp)
        }

        if (!musicTemp) {
            setSelectedTitleMusic(null)
        } else {
            setSelectedTitleMusic(musicTemp)
        }

        formatTags(voiceTemp, musicTemp)
    }

    //console.log(selectedTitleVoice)

    useEffect(() => {
        populateTitles()
    }, [advert.voiceId, advert.musicId])

    useEffect(() => {
        if (advert && voices && voices.length > 0 && music && music.length > 0) {
            populateTitles()
        }
    }, [advert, voices, music])

    const handleSetupPreviewVoice = (id) => {
        setIsMusicPlaying((prevIsMusicPlaying) => !prevIsMusicPlaying);
        if (id) {
            const findSongUrl = music.find((song) => song.id === id).url
            handlePreviewVoice(findSongUrl)
        } else {
            handlePreviewVoice()
        }
    }

    const handleVoicePreview = () => {
        if (!isVoicePlaying) {
            const findVoiceValue = voices.find((voice) => voice?.voiceId == advert.voiceId).voiceAiValue
            handlePreviewVoice(findVoiceValue)
        } else {
            handlePreviewVoice()
        }

        setIsVoicePlaying(!isVoicePlaying)
    }

    useEffect(() => {
        setIsExpanded(isFirst)
    }, [isFirst])

    const [audioToPlay, setAudioToPlay] = useState(null)

    const handleAdvertAudio = () => {
        if (advert?.urls && advert?.urls.length > 0) {

            setAudioToPlay(advert.urls[0].url)
        } else {
            setAudioToPlay(advert)
        }
    }

    useEffect(() => {
        if (advert) {
            handleAdvertAudio()
        }
    }, [advert])

    return (
        <div>
            <div style={{ backgroundColor: "#404040", display: "flex", alignItems: "center", justifyContent: "center", gap: 15, width: "90%", margin: "auto", padding: 15 }}>
                <div style={{ width: "90%" }}>
                    
                    <div style={{ backgroundColor: "" }}>
                        <WizardPlayer fileURL={audioToPlay} />
                    </div>
                </div>
            </div>
            <div style={{
                width: "90%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column"
            }} className="mt-2 mb-2">
                {!isLanguage &&
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between", cursor: "pointer" }} onClick={handleExpand}>
                        <p style={{ margin: 0 }}>{!isLanguage ? "Ad" : "Translation"} Preview</p>
                        {!isExpanded ? <i className="fas fa-chevron-down" /> : <i className="fas fa-chevron-up" />}
                    </div>    
                }
                {isExpanded &&
                    <div style={{ disaply: "flex", width: "95%" }} className="mt-2 mb-2">
                        <Row className="justify-content-between">
                            <Col style={{ border: "1px solid rgba(225, 225, 225, 0.3)", borderRadius: 7, padding: 0, marginLeft: 5, marginRight: 5 }} sm="">
                                <p style={{ margin: 0, backgroundColor: "rgb(64, 64, 64)", width: "100%", padding: 10 }}>Voice</p>
                                <div style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "", width: "50%", gap: 25 }}>
                                        <div>
                                            <p style={{ borderRadius: 35, minHeight: 35, maxHeight: 35, minWidth: 35, maxWidth: 35, backgroundColor: "rgb(64, 64, 64)" }}></p>
                                        </div>

                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "50%" }}>
                                            <div>
                                                <p>{selectedTitleVoice ? selectedTitleVoice.voiceName : "No voice selected"}</p>
                                                <div style={{display: "flex", flexWrap: "wrap", gap: 5} }>
                                                    {voiceTags.map((e) => {
                                                        return (
                                                            <Badge
                                                                pill
                                                                style={{ color: "#fff", backgroundColor: e.textColor, border: e.textColor, margin: 0 }}
                                                            >{e.value}</Badge>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div>
                                                <i className={`${!isVoicePlaying ? "fa fa-play" : "fa fa-pause"}`} style={{ fontSize: 26, cursor: "pointer" }} onClick={() => handleVoicePreview()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button onClick={() => setModalPage("voice", advert)} type="submit" className="buttonStyle buttonPrimary ignorBut">Change</button>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ border: "1px solid rgba(225, 225, 225, 0.3)", borderRadius: 7, padding: 0, marginLeft: 5, marginRight: 5 }} sm="">
                                <p style={{ margin: 0, backgroundColor: "rgb(64, 64, 64)", width: "100%", padding: 10 }}>Music</p>
                                <div style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "", width: "50%", gap: 25 }}>
                                        <div>
                                            <p style={{ borderRadius: 35, minHeight: 35, maxHeight: 35, minWidth: 35, maxWidth: 35, backgroundColor: "rgb(64, 64, 64)" }}></p>
                                        </div>

                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "50%" }}>
                                            <div>
                                                <p>{selectedTitleMusic ? selectedTitleMusic.name : "No music selected"}</p>
                                                <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                                                    {musicTags.map((e) => {
                                                        return (
                                                            <Badge
                                                                pill
                                                                style={{ color: "#fff", backgroundColor: e.textColor, border: e.textColor, margin: 0 }}
                                                            >{e.value}</Badge>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div>
                                                <i className={`${!isMusicPlaying ? "fa fa-play" : "fa fa-pause"}`} style={{ fontSize: 26, cursor: "pointer" }} onClick={!isMusicPlaying ? () => handleSetupPreviewVoice(advert.musicId) : () => handleSetupPreviewVoice()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button onClick={() => setModalPage("music", advert)} type="submit" className="buttonStyle buttonPrimary ignorBut">Change</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-2">
                            <Col className="text-center">
                                <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
                                    {isLanguage &&
                                        <button onClick={() => handleGenerateAds()} type="submit" style={{ minWidth: "20%", backgroundColor: "transparent", minHeight: 40, maxHeight: 40, border: "2px solid #F33B30", boxShadow: "none", borderRadius: 5, color: "#FFF" }}>Delete</button>
                                    }
                                    <button className="ignorBut" onClick={() => handleGenerateAds(advert.id)} type="submit" style={{ minWidth: "20%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: advert.updateReq ? "2px solid white" : "2px solid transparent", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}> REGENERATE (1 CREDIT) {singleLoadId && singleLoadId == advert.id && <Spinner size="sm" />}</button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        </div>
    )
}

export default GeneratedAd