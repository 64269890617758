/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import moment from "moment"

// reactstrap components
import { FormGroup, Input, Row, Col, Label, Button, UncontrolledTooltip, Spinner } from "reactstrap";
import MiniPlayer from "../components/MiniPlayer";

const BStep5 = React.forwardRef((props, ref) => {

    //useEffect(() => {

    //    if (props.generationStatus === "Available") setGenerationStep(2)
        

    //}, [props.generationStatus])
    

    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            if (props.generationStep === 1) {
                return false
            } else {
                return true
            }
        },
    }));

    

    return (
        <>
            {props.generationStep === 1 && !props.generationError &&
                <Col>
                    <Row>
                        <Col className="text-center">
                            <h4 className="">Your podcast is being created</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Spinner style={{ color: "#F33B30" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <h4 className="text-white">{props.generationStatus}</h4>
                        </Col>
                    </Row>
                </Col>    
            }

            {props.generationStep === 2 && !props.generationError &&
                <Col>
                    <Row>
                        <Col className="text-center">
                            <h4 className="">Your podcast is ready</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <button className="buttonPrimary buttonStyle" onClick={() => props.handleDownloadClicked(props.baseObject.name) }>Download</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center pt-4">
                            <div style={{width: "50%", margin: "auto"} }>
                                <MiniPlayer podcast={props.B2BPreviewAudio} podcastDuration={props.podcastDuration} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            }

            {props.generationError &&
                <Col>
                    <Row>
                        <Col className="text-center">
                            <h4 className="">There has been an error generating your podcast</h4>
                        </Col>
                    </Row>
                </Col>
            }
        </>
    );
});

export default BStep5;
