import React, { useState } from 'react'; // Don't forget to import useState
import { useFormik } from 'formik';
import apiConfig from "config/apiConfig";
import * as Yup from 'yup';
import "assets/css/custom-css.css"
import klaxonLogo from "assets/img/klaxonImages/Logo-removebg-preview.png"
import klaxonLogoNew from "assets/img/klaxonImages/klaxonFull.png"
import axios from "axios"

import Select from "react-select";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input,
    Label

} from "reactstrap";

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    companyName: Yup.string().required('Company name is required'),
    companyWebsite: Yup.string().required('Website is required'),
    telephone: Yup.number().integer('Telephone must be a number').required('Telephone is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    //message: Yup.string().min(20, 'Message must be 20 or more characters'),
});

function AdvertiseForm() {

    const [singleSelectType, setSingleSelectType] = useState([])
    const [singleSelectLocation, setSingleSelectionLocation] = useState([])
    const [messageSent, setMessageSent] = useState(false);
    const [sendError, setSendError] = useState(false);


    const handleTypeChange = (value) => {
        setSingleSelectType(value)

    }

    const handleLocationChange = (value) => {
        setSingleSelectionLocation(value)
    }
    // Move the MyForm component outside of the Contact function
    const MyForm = () => {
        const formik = useFormik({
            initialValues: {
                name: '',
                companyName: '',
                companyWebsite: '',
                telephone: '',
                email: '',
                message: '',
            },
            validationSchema: validationSchema,
            onSubmit: async values => { // Add async keyword to onSubmit function
                try {
                    const contactUs = {
                        name: values.name,
                        companyName: values.companyName,
                        companyWebsite: values.companyWebsite,
                        telephone: values.telephone,
                        email: values.email,
                        message: `I am a ${singleSelectType.label}, looking to target ${singleSelectLocation.label}. My message is ${values.message}`
                    };

                    
                    //let url = `${apiConfig.baseUrl}${apiConfig.HOME.CONTACT_US}`;
                    //await axios
                    //    .post(url, contactUs)
                    //    .then((res) => {
                    //        if (res.status === 200) {
                    //            setMessageSent(true)
                    //            setSendError(false)
                    //        }

                    //    })
                        
                } catch (err) {
                    console.error(err)
                    setSendError(true); // Handle fetch errors by setting error state
                    setMessageSent(false); // Reset the success state
                }
            },
        });

        return (
            <form onSubmit={formik.handleSubmit}>
                <div>
                   
                    <label htmlFor="type" className="text-white">I am a</label>
                        <Select
                            className="react-select "
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={singleSelectType}
                            onChange={(value) => handleTypeChange(value)}
                            options={[
                                { label: "Advertiser", value: 1 },
                                { label: "Ad agency (representing advertisers)", value: 2 },
                                { label: "Ad network (representing publishers)", value: 3 },
                                { label: "Technology provider", value: 4 },
                            ]}
                            placeholder="eg Advertiser"
                        />
                    
                </div>

                <div>

                    <label htmlFor="location" className="text-white">Looking to target</label>
                    <Select
                        className="react-select "
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={singleSelectLocation}
                        onChange={(value) => handleLocationChange(value)}
                        options={[
                            { label: "United States", value: 1 },
                            { label: "United Kingdom", value: 2 },
                            { label: "Ireland", value: 3 },
                            { label: "Canada", value: 4 },
                            { label: "Other", value: 5 },
                            { label: "All", value: 6 },
                        ]}
                        placeholder="eg UK"
                    />

                </div>

                <div>
                    <label htmlFor="name" className="text-white">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        placeholder="Name"
                        className="inputStyle placeholderWhite"
                        style={{ borderRadius: 5 }}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className="contact-error">{formik.errors.name}</div>
                    ) : null}
                </div>

                <div>
                    <label htmlFor="companyName" className="text-white">Company</label>
                    <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyName}
                        placeholder="Company Name"
                        className="inputStyle placeholderWhite"
                        style={{ borderRadius: 5 }}
                    />
                    {formik.touched.companyName && formik.errors.companyName ? (
                        <div className="contact-error">{formik.errors.companyName}</div>
                    ) : null}
                </div>

                <div>
                    <label htmlFor="companyWebsite" className="text-white">Company Website</label>
                    <input
                        type="text"
                        id="companyWebsite"
                        name="companyWebsite"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyWebsite}
                        placeholder="Company Website"
                        className="inputStyle placeholderWhite"
                        style={{ borderRadius: 5 }}
                    />
                    {formik.touched.companyWebsite && formik.errors.companyWebsite ? (
                        <div className="contact-error">{formik.errors.companyWebsite}</div>
                    ) : null}
                </div>

                <div>
                    <label htmlFor="telephone" className="text-white">Telephone</label>
                    <input
                        type="number"
                        id="telephone"
                        name="telephone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.telephone}
                        placeholder="Telephone"
                        className="inputStyle placeholderWhite"
                        style={{ borderRadius: 5 }}
                    />
                    {formik.touched.telephone && formik.errors.telephone ? (
                        <div className="contact-error">{formik.errors.telephone}</div>
                    ) : null}
                </div>

                <div>
                    <label htmlFor="email" className="text-white">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        placeholder="Email"
                        className="inputStyle placeholderWhite"
                        style={{ borderRadius: 5 }}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="contact-error">{formik.errors.email}</div>
                    ) : null}
                </div>

                <div>
                    <label htmlFor="message" className="text-white">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                        placeholder="Message"
                        rows={6}
                        className="inputStyle placeholderWhite"
                        style={{ borderRadius: 5 }}
                    />
                    {formik.touched.message && formik.errors.message ? (
                        <div className="contact-error">{formik.errors.message}</div>
                    ) : null}
                </div>
                <center>
                    <button className="buttonStyle buttonPrimary mb-2 mt-2" type="submit">Send</button>
                    <p className="formTerms text-white mt-2">By submitting this form, you agree to our <a style={{
                        color: "white",
                        textDecoration: "underline white",
                        cursor: "pointer"
                    }} href="/privacy">Privacy Policy</a>.</p>
                </center>
            </form>
        );
    };

    return (
        <>
            {!messageSent &&
                <Row className="contact-form formStyle" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center" }}>
                    {!messageSent && (
                        <>
                            <img className="mb-4 mt-2" src={klaxonLogoNew} style={{ width: "60%" }} />
                            <h3 class="readForm">Have <strong>your</strong> say!</h3>
                            <MyForm />
                        </>
                    )}
                    {sendError && (
                        <div className="d-flex justify-content-center align-items-center text-white text-center">
                            <h5 style={{ width: "90%", margin: 5 }}>There was an error sending your message. If this persists, please send an email to  <a style={{ textDecoration: "underline", color: "white" }} href="mailto:hello@klaxon.ai">hello@klaxon.ai</a></h5>
                        </div>
                    )}
                </Row>
            }
            {messageSent && (
                <div className="contacted d-flex align-items-center text-white">
                    <h3>Thanks for contacting us. We will get back to you shortly.</h3>
                </div>
            )}
        </>
    );
}

export default AdvertiseForm;

