/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import classnames from "classnames";

// reactstrap components
import { Row, Col, Label, Input, FormGroup, Button, UncontrolledTooltip, Spinner } from "reactstrap";

import Select from "react-select";
import ReactDatetime from "react-datetime";
import CustomImageUpload from "components/CustomUpload/CustomImageUpload";
import tooltipText from "../../../config/tooltipText";

const Step4 = React.forwardRef((props, ref) => {

    const [holdImage, setHoldImage] = useState("")
    const [alert, setAlert] = useState(null)

    

    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            const isValid = [props.baseObject.name, props.baseObject.description].every(e => e.length > 0)
            if (!isValid) {
                setAlert("Please enter a name and description")
            }
            return isValid
        },
    }));

    useEffect(() => {

        if (holdImage.length > 0) {
            const base64Image = holdImage.split(",")[1]
            props.setBaseObject({ ...props.baseObject, image: base64Image })
        }
    }, [holdImage])

    return (
        <>
            {props.preparingPodcast === false ?
                <>
                    <h4 className="text-center" style={{ color: "#F33B30" }}><strong>{alert}</strong></h4>
                    <Row className="justify-content-center">
                        <Col sm="12" md="12" lg="8">
                            <FormGroup>
                                <FormGroup>
                                    <Label>Podcast Name</Label>
                                    <Button
                                        className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                        id="tooltip8089663905252"
                                        size="sm"
                                        style={{ backgroundColor: "white" }}
                                    >
                                        <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip8089663905252"
                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                    >
                                        <p style={{ margin: 0 }}>{tooltipText.NAME.PODCAST_NAME}</p>
                                    </UncontrolledTooltip>
                                </FormGroup>
                                <Input className="inputStyle" type="text" onChange={(e) => props.setBaseObject({ ...props.baseObject, name: e.target.value })} />
                            </FormGroup>

                            <FormGroup>
                                <FormGroup>
                                    <Label>Description</Label>
                                    <Button
                                        className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                        id="tooltip8089663917878"
                                        size="sm"
                                        style={{ backgroundColor: "white" }}
                                    >
                                        <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip8089663917878"
                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                    >
                                        <p style={{ margin: 0 }}>{tooltipText.NAME.DESCRIPTION}</p>
                                    </UncontrolledTooltip>
                                </FormGroup>
                                <Input className="inputStyle" type="textarea" onChange={(e) => props.setBaseObject({ ...props.baseObject, description: e.target.value })} />
                            </FormGroup>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} sm="12" md="12" lg="4">
                            <FormGroup>
                                <Label>Image</Label>
                                <Button
                                    className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                    id="tooltip8089663925555"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip8089663925555"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                >
                                    <p style={{margin: 0}}>{tooltipText.NAME.IMAGE}</p>
                                </UncontrolledTooltip>
                            </FormGroup>
                            <CustomImageUpload userImage={holdImage} setUserImage={setHoldImage} widthProp={150} heightProp={150} />
                        </Col>
                    </Row>
                </> : null
            }
        </>
    );
});

export default Step4;
