import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

import { Spinner } from "reactstrap"



function MiniPlayer(props) {
    const playerRef = useRef(null);
    const [audioLoaded, setAudioLoaded] = useState(false); // Handles loading screen and avoids breaking from instant plays

    //Audio State
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentPlayingAudio, setCurrentPlayingAudio] = useState(null); // Start as null so ReactPlayer recognises we changed URL

    const [playbackSpeedSelection, setPlaybackSpeedSelection] = useState(0) // Stores array position (1, 1.5 or 2)
    const [playbackSpeedSelectionUser, setPlaybackSpeedSelectionUser] = useState(0)
    const [playbackSpeed, setPlaybackSpeed] = useState(1) // Current playback speed

    const [advertPlaying, setAdvertPlaying] = useState(false)
    const [podcastAudioNumber, setPodcastAudioNumber] = useState(0)
    const [podcastCurrentSecond, setPodcastCurrentSecond] = useState(0)

    const [waitingSeek, setWaitingSeek] = useState(false)
    const [seekTo, setSeekTo] = useState(null)

    const [advertArray, setAdvertArray] = useState([])
    const [listenedAds, setListenedAds] = useState([])

    const [userResume, setUserResume] = useState(false)

    //console.log(props)

    // Not sure if these needed anymore
    const [currentAudioElapsedFraction, setCurrentAudioElapsedFraction] = useState(0.0); // Holds decimal for progress bar
    // UI CONTROLS
    const togglePlay = () => {
        setIsPlaying(!isPlaying);

        if (currentPlayingAudio === null) {
            setCurrentPlayingAudio(props.podcast[0].url)
            setPodcastAudioNumber(0)
            setUserResume(true)
        }
    };

    const stopAudio = () => {
        setPodcastCurrentSecond(0)
        setIsPlaying(false);
        setCurrentPlayingAudio(null)
    };

    // AUDIO LOGIC
    const handleAudioReady = () => {
        setAudioLoaded(true)

        if (!!advertPlaying) {
            // Force 1x playspeed
            setPlaybackSpeedSelection(0)
        } else {
            setPlaybackSpeedSelection(playbackSpeedSelectionUser)
        }

        if (waitingSeek) {
            setAdvertPlaying(false)
            //setPodcastAudioNumber(podcastAudioNumber + 1)
            playerRef.current.seekTo(seekTo)
            setWaitingSeek(false)
        }


        if (userResume) {
            setIsPlaying(true)
            setUserResume(false)
        }
    }
    // Main function
    const handleTimes = (e) => {
        if (!advertPlaying) {
            // This handles updating the progress bar and duration counter
            setPodcastCurrentSecond((prevPodcastCurrentSecond) => {
                const newPodcastCurrentSecond = prevPodcastCurrentSecond + 0.9 * playbackSpeed; // Weird number, needs to be a tiny bit higher
                setCurrentAudioElapsedFraction(
                    +(newPodcastCurrentSecond / props.podcastDuration.durationSeconds).toFixed(6)
                );
                return newPodcastCurrentSecond;
            });
        }

        if (e.playedSeconds >= convertTimeSpan(props.podcast[podcastAudioNumber].duration)) {


            //setIsPlaying(false)
            if (podcastAudioNumber <= props.podcast.length - 2) {
                
                setAdvertPlaying(!props.podcast[podcastAudioNumber + 1].canSeek) // Look at next
                setCurrentPlayingAudio(props.podcast[podcastAudioNumber + 1].url)
                setPodcastAudioNumber(podcastAudioNumber + 1)
                //setIsPlaying(true)
            } else {
                
                setPodcastCurrentSecond(0)
                setIsPlaying(false) // Podcast over including all adverts
            }


        }
    };
    
    // UTILITY/CALLBACk
    const handleSeek = async (position) => {
        const posInSeconds = position * props.podcastDuration.durationSeconds // Get seconds of where user clicked
        const onlyPodcast = props.podcast.map((e, index) => ({
            index: index, duration: convertTimeSpan(e.duration), canSeek: e.canSeek, advertId: e.advertId, url: e.url
        }))// Map timespans and index
        let totalTime = 0
        for (const item of onlyPodcast) { // Loop through durations
            if (item.canSeek === true) {
                totalTime += item.duration // Add durations on to total time
            }

            if (posInSeconds <= totalTime) {
                const timeInCurrentClip = posInSeconds - (totalTime - item.duration) // totaltime holds the item we're looking at, remove it
                const newTime = +(timeInCurrentClip / item.duration).toFixed(6) // Get the time in current clip as a fraction

                setPodcastCurrentSecond(posInSeconds) // set seconds in relation to entire duration, main function handles slider position


                
                

                if (item.index === podcastAudioNumber) {
                    playerRef.current.seekTo(newTime)
                } else {
                    setIsPlaying(false)
                    setPodcastAudioNumber(item.index) // Set current audio index number
                    setCurrentPlayingAudio(props.podcast[item.index].url) // Set the current playing audio with the index number too as it won't be available in state yet
                    setIsPlaying(true)
                    setSeekTo(newTime) // Set location to seek to
                    setWaitingSeek(true) // Set bool for audioReady from player so we don't skip on nothing
                }
                break
            }
        }

    };

    const handleProgressBar = (e) => {
            handleSeek(e.target.value);
        
    };

    const convertTimeSpan = (timeString) => {
        const [timePart, milliseconds] = timeString.split('.');
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        let totalSeconds = hours * 3600 + minutes * 60 + seconds;

        if (milliseconds) {
            totalSeconds += parseFloat(`0.${milliseconds}`);
        }

        return totalSeconds;
    };


    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const formattedHours = hours > 0 ? String(hours).padStart(2, '0') : '';
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        const formattedTime = [formattedHours, formattedMinutes, formattedSeconds]
            .filter((value) => value !== '')
            .join(':');

        return formattedTime;
    };

    useEffect(() => {
        if (props.podcast) {
            setCurrentPlayingAudio(props.podcast[podcastAudioNumber].url)
        }
    }, [props.podcast])
    

    return (
        <>
            <ReactPlayer
                width={0}
                url={currentPlayingAudio}
                height="0"
                playing={isPlaying}
                progressInterval={1000}
                ref={playerRef}
                playbackRate={playbackSpeed}
                onReady={() => handleAudioReady()}
                onProgress={(e) => handleTimes(e)} //Calls every second
            />

            {audioLoaded ? (
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#404040",
                        borderRadius: 5
                    }}
                >
                    <div
                        style={{
                            width: "25%",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >
                        <svg
                            width="50"
                            height="50"
                            viewBox="0 0 24 24"
                            style={{
                                fill: "#FFF",
                                transition: "fill 0.3s ease",
                                cursor: "pointer",
                            }}
                            onClick={togglePlay}
                        >
                            {isPlaying ? (
                                <path d="M14 5h4v14h-4zm-10 0h4v14h-4z" />
                            ) : (
                                <path d="M8 5v14l11-7z" />
                            )}
                        </svg>

                        <svg
                            width="50"
                            height="50"
                            viewBox="0 0 24 24"
                            style={{
                                fill: "#FFF",
                                transition: "fill 0.3s ease",
                                cursor: "pointer",
                            }}
                            onClick={stopAudio}
                        >
                            <rect width="12" height="12" y="6" x="6" />
                        </svg>
                    </div>

                    <div style={{ width: "35%" }}>
                        <input
                            type="range"
                            min={0}
                            max={0.999999}
                            step="any"
                            style={{ width: "100%" }}
                            onChange={(e) => handleProgressBar(e)}
                            value={currentAudioElapsedFraction}
                        />
                    </div>

                    <div style={{ width: "30%" }}>
                        <p style={{ marginBottom: 0, textAlign: "center", color: "white" }}>
                            {formatDuration(podcastCurrentSecond)} / {props.podcastDuration.durationTimeSpan}
                        </p>
                    </div>

                    
                </div>
            ) : (
                    <div style={{ display: "flex", width: "100%", justifyContent: "center"}} >
                        <Spinner style={{ color: "#F33B30" }} />
                </div>
            )}
        </>
    );
}


export default MiniPlayer;