/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";

import Select from "react-select";

import defaultImage from "../../assets/img/header.jpg"
import MyModal from "components/Shared/MyModal";
import CreatePodcast from "./components/CreatePodcast";
import RandomImage from "assets/img/bg/jeff-sheldon.jpg"

import AdvertiseForm from "./components/AdvertiseForm";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input,
    Label

} from "reactstrap";


function Advertise() {

    const [singleSelectType, setSingleSelectType] = useState({})
    const [singleSelectLocation, setSingleSelectionLocation] = useState({})

    const handleTypeChange = (value) => {
        setSingleSelectType(value)
       
    }

    const handleLocationChange = (value) => {
        setSingleSelectionLocation(value)
    }

    return (
        <>

            <div className="content" style={{ marginTop: 78, paddingLeft: "12%", paddingRight: "12%" }}>
                <Col>
                    <Row>
                        <Col md="12" className="text-white">
                            <Col>
                                <Row className="justify-content-center">
                                    <h3 className="text-center" style={{ color: "white", marginTop: 20 }}><b>Advertise</b></h3>
                                </Row>

                            </Col>

                            <CardBody>

                                <Row>

                                    <Col>
                                        <p style={{fontStyle: "italic"}}>Studies including by <a style={{ textDecoration: "underline" }} href="https://www.theguardian.com/gnm-press-office/2022/mar/16/new-research-podcast-advertising-commands-highest-levels-of-attention" target="_blank">The Guardian</a> have found that podcast advertising generates more attention and engagement than any other form of advertising around. In fact, research revealed that 64% of listeners give their full attention to podcasts and a huge 62% acted on a brand message heard within a podcast.</p>
                                        <p style={{ fontStyle: "italic" }}>This makes podcast advertising far more effective than TV, online, social media, print, display, or any other form of advertising. This is why global podcast advertising revenue is forecast to grow from &pound;23bn in 2023 to &pound;130bn by 2030. </p>
                                        <p>Depending on your marketing objectives, we can offer you a range of cost-effective advertising, sponsorship, and promotional opportunities.</p>
                                        <p>For more information, and so that we can direct your enquiry to the right person, please fill out the form below.</p>
                                    </Col>
                                    <Col
                                        md="6"
                                        style={{
                                            display: "flex",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            src={RandomImage}
                                            style={{
                                                width: "100%",
                                                height: "80%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </Col>

                                </Row>
                               
                                <Col>
                                    <CardBody>
                                        <Col className="formStyle" style={{display: "flex", justifyContent: "center"} }>
                                            
                                            <AdvertiseForm />
                                        </Col>

                                    </CardBody>
                                </Col>
                            </CardBody>


                        </Col>
                    </Row>
                </Col>
            </div>
        </>
    );
}

export default Advertise;
