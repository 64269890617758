/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";

import klaxonLogo from "assets/img/klaxonImages/klaxonFull.png"

import "assets/css/custom-css.css"
import axios from "axios";
import { useEffect } from "react";


function Register() {

    const [newUser, setNewUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
    })

    const [confirmPassword, setConfirmPassword] = useState("")
    const [isFocused, setIsFocused] = useState("")
    const navigation = useNavigate();
    const [alert, setAlert] = useState(null)

    const handleBlur = () => {
        setIsFocused("")
    }

    const createUser = async (e) => {
        e.preventDefault()
        setAlert(null)

        if (!Object.values(newUser).every((value) => value !== "")) {
            setAlert("Please enter all fields")
            return
        }

        if (newUser.password === confirmPassword && newUser.password.length > 0) {
            try {

                const resp = await axios.post(
                    `${apiConfig.baseUrl}${apiConfig.USER.REGISTER}`,
                    newUser,
                );

                if (resp.status === 200) {

                    navigation(`/auth/verification?email=${newUser.email}`)
                }
            } catch (error) {
                console.error(error.message);
                if (error.response?.data && error.response?.data.startsWith("The system already")) {
                    setAlert("This email is already in use")
                } else {
                    setAlert("Something went wrong, try again")
                }
            }
        } else {
            setAlert("Passwords do not match")
        }
    };

    const handleLoginNavigation = () => {
        navigation("/auth/login");
    }

    React.useEffect(() => {
        document.body.classList.toggle("register-page");
        return function cleanup() {
            document.body.classList.toggle("register-page");
        };
    }, []);

    const handleNavigateHome = () => {
        navigation("/home")
    }

    useEffect(() => {
        setAlert(null)
    }, [newUser.password, confirmPassword])
    
    return (
        <div className="register-page">
            <Container style={{
                position: "relative",
                zIndex: 2
            }}>
                <Row>
                    <Col className="ml-auto" lg="5" md="6">
                                <Form action="" className="form" method="">


                            <Card className="card-signup text-center p-4 formStyle" style={{ minHeight: 600 }}>
                                <div style={{ color: "white", position: "absolute", top: "3%", right: "5%", justifyContent: "center", alignItems: "center", display: "flex", cursor: "pointer" }} onClick={handleNavigateHome}>
                                    <i className="nc-icon nc-minimal-left" style={{ marginRight: 10 }} />
                                    <p style={{ marginBottom: 0 }}>Back to site</p>
                                </div>
                            <CardHeader className="d-flex justify-content-center">

                                <img src={klaxonLogo} style={{ width: "80%" }} />

                            </CardHeader>
                            <CardBody style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center" }}>
                                    <p className="text-center text-white">Fill out the information below to get started</p>
                                    <p className="text-center text-white">{alert}</p>
                                    <InputGroup style={{ width: "90%", justifyContent: "center", display: "flex" }} className={isFocused === "firstName" ? "fakeHoverEffect" : ""}>
                                       
                                        <Input placeholder="First Name" type="text" onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })} className="inputRemoveHover inputStyle placeholderWhite" onFocus={() => setIsFocused("firstName")}
                                            onBlur={handleBlur}/>
                                    </InputGroup>
                                    <InputGroup style={{ width: "90%", justifyContent: "center", display: "flex" }} className={isFocused === "lastName" ? "fakeHoverEffect" : ""}>
                                       
                                        <Input placeholder="Last Name" type="text" onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })} className="inputRemoveHover inputStyle placeholderWhite" onFocus={() => setIsFocused("lastName")}
                                            onBlur={handleBlur}/>
                                    </InputGroup>
                                    <InputGroup style={{ width: "90%", justifyContent: "center", display: "flex" }} className={isFocused === "email" ? "fakeHoverEffect" : ""}>
                                        
                                        <Input placeholder="Email" type="email" onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} className="inputRemoveHover inputStyle placeholderWhite" onFocus={() => setIsFocused("email")}
                                            onBlur={handleBlur}/>
                                    </InputGroup>
                                    <InputGroup style={{ width: "90%", justifyContent: "center", display: "flex" }} className={isFocused === "password" ? "fakeHoverEffect" : ""}>
                                       
                                        <Input placeholder="Password" type="password" onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} className="inputRemoveHover inputStyle placeholderWhite" onFocus={() => setIsFocused("password")}
                                            onBlur={handleBlur}/>
                                    </InputGroup>
                                    <InputGroup style={{ width: "90%", justifyContent: "center", display: "flex" }} className={isFocused === "confirmPassword" ? "fakeHoverEffect" : ""}>
                                       
                                        <Input placeholder="Confirm Password" type="password" onChange={(e) => setConfirmPassword(e.target.value)} className="inputRemoveHover inputStyle placeholderWhite" onFocus={() => setIsFocused("confirmPassword")}
                                            onBlur={handleBlur}/>
                                    </InputGroup>

                                
                                </CardBody>
                                <CardFooter style={{ marginTop: 0, display: "flex", alignItems: "center", flexDirection: "column" }}>
                                    <button className="buttonStyle buttonPrimary" onClick={createUser} style={{width: "90%"} }>
                                    Register
                                </button>
                                    <p className="text-center text-white" style={{ marginBottom: 0, marginTop: 16 }}>Already have an account? <u onClick={handleLoginNavigation} style={{
                                    color: "white",
                                    textDecoration: "underline white",
                                    cursor: "pointer",
                                }}>Login here</u></p>
                            </CardFooter>

                        </Card>

                                </Form>
                    </Col>
                </Row>
            </Container>
            <div
                className=""
                style={{
                    backgroundImage: `url(${require("assets/img/bg/bg.jpg")})`,
                    position: "absolute",
                    zIndex: 1,
                    display: "block",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                    backgroundSize: "cover",

                }}
            />
        </div>
    );
}

export default Register;
