import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import apiConfig from "../../../config/apiConfig"

import { Spinner } from "reactstrap"
import apiCall from "../../../utils/apiCall";
import getJWTToken from "../../../config/jwtToken";

import { v4 as uuidv4 } from "uuid"
import Cookies from "js-cookie";
import axios from "axios"

function AudioPlayerLight(props) {
    const playerRef = useRef(null);
    const [audioLoaded, setAudioLoaded] = useState(false); // Handles loading screen and avoids breaking from instant plays

    //Audio State
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentPlayingAudio, setCurrentPlayingAudio] = useState(null); // Start as null so ReactPlayer recognises we changed URL

    const [playbackSpeedSelection, setPlaybackSpeedSelection] = useState(0) // Stores array position (1, 1.5 or 2)
    const [playbackSpeedSelectionUser, setPlaybackSpeedSelectionUser] = useState(0)
    const [playbackSpeed, setPlaybackSpeed] = useState(1) // Current playback speed

    const [advertPlaying, setAdvertPlaying] = useState(false)
    const [podcastAudioNumber, setPodcastAudioNumber] = useState(0)
    const [podcastCurrentSecond, setPodcastCurrentSecond] = useState(0)
    const [currentAudioCurrentSecond, setCurrentAudioCurrentSecond] = useState(0)
    const [currentAudioLength, setCurrentAudioLength] = useState(0)

    const [waitingSeek, setWaitingSeek] = useState(false)
    const [seekTo, setSeekTo] = useState(null)

    const [advertArray, setAdvertArray] = useState([])
    const [listenedAds, setListenedAds] = useState([])

    const [userResume, setUserResume] = useState(false)

    const [currentAudioElapsedFraction, setCurrentAudioElapsedFraction] = useState(0.0); // Holds decimal for progress bar

    const advertPlayed = async (advertId, episodeId, position) => {

        let anonId;
        const anonCookie = Cookies.get("anonymousId")
        if (anonCookie != null) {
            anonId = anonCookie
        } else {
            const newUUID = uuidv4()
            Cookies.set("anonymousId", newUUID)
            anonId = newUUID
        }
        const baseUrl = `${apiConfig.baseUrl}${apiConfig.ADVERT.RECORD_PLAY}`
        const extraParams = getJWTToken().decoded != null ? `?id=${advertId}&podcastEpisodeId=${episodeId}&position=${position}&userId=${getJWTToken().decoded.nameid}` : `?id=${advertId}&podcastEpisodeId=${episodeId}&position=${position}&anonymousUserId=${anonId}`
        const url = `${baseUrl}${extraParams}`
        await axios
            .post(url)
            .then((res) => {
            })
            .catch((err) => {
            });
    }

    const podcastPlay = async (seconds) => {

        const userId = getJWTToken().decoded
        let setUserId;
        let anonId;
        if (userId !== null) {
            setUserId = getJWTToken().decoded.nameid
            anonId = null

        } else {
            const anonCookie = Cookies.get("anonymousId")
            if (anonCookie != null) {
                setUserId = 0
                anonId = anonCookie
            } else {
                const newUUID = uuidv4()
                Cookies.set("anonymousId", newUUID)
                setUserId = 0
                anonId = newUUID
            }

        }

        const podcastObject = {
            id: 0,
            podcastEpisodeId: props.podcast.podcastId,
            dateTime: new Date().toISOString('O'),
            userId: setUserId,
            anonymousUserId: anonId,
            duration: formatDurationHours(seconds)
        }


        let url = `${apiConfig.baseUrl}${apiConfig.PODCAST_EPISODE_PLAYS.ADD_OR_UPDATE}?userId=${userId != null ? userId.nameid : 0}`;

        await axios
            .post(url, podcastObject)
            .then((res) => {

                //console.log(res)
            })
            .catch((err) => {
                //setError(err.response.data);
                //SET ERROR HERE
                console.error("error")
            });
    };

    // UI CONTROLS
    const togglePlay = () => {
        setIsPlaying(!isPlaying);


        if (currentPlayingAudio === null) {
            setCurrentPlayingAudio(props.podcast.audioUrls[0].url)
            setPodcastAudioNumber(0)
            setUserResume(true)
        }
    };

    // AUDIO LOGIC
    const handleAudioReady = () => {
        setAudioLoaded(true)

        setCurrentAudioCurrentSecond(0);
        setCurrentAudioElapsedFraction(0);


        if (advertPlaying) {
            // Force 1x playspeed
            setPlaybackSpeedSelection(0);

            // Set the current and end durations to the advert values
            setCurrentAudioCurrentSecond(0);
            setCurrentAudioLength(formatDuration(convertTimeSpan(props.podcast.audioUrls[podcastAudioNumber].duration)));
        } else {
            setPlaybackSpeedSelection(playbackSpeedSelectionUser);

            // Set the current and end durations back to stored values for whole podcast
            setCurrentAudioCurrentSecond(podcastCurrentSecond);
            setCurrentAudioLength(props.podcastDuration.durationTimeSpan);
        }

        if (waitingSeek) {
            setAdvertPlaying(false)
            playerRef.current.seekTo(seekTo)
            setWaitingSeek(false)
        }

        if (listenedAds.includes(props.podcast.audioUrls[podcastAudioNumber].advertId)) { // Skip advert if already listened to
            setAdvertPlaying(false)
            setPodcastAudioNumber(podcastAudioNumber + 1)
            setCurrentPlayingAudio(props.podcast.audioUrls[podcastAudioNumber + 1].url)
        }

        if (userResume) {
            setIsPlaying(true)
            setUserResume(false)
        }
    }

    // Main function
    const handleTimes = (e) => {
        if (isPlaying) {
            if (!advertPlaying) {
                //If 10 seconds send call to update listen time
                if (Math.floor(currentAudioCurrentSecond) % 10 === 0 && currentAudioCurrentSecond !== 0) {
                    podcastPlay(currentAudioCurrentSecond)
                }

                // This handles updating the progress bar and duration counter
                setCurrentAudioCurrentSecond((prevPodcastCurrentSecond) => {
                    const newPodcastCurrentSecond = prevPodcastCurrentSecond + 1 * playbackSpeed; // Weird number, needs to be a tiny bit higher
                    setCurrentAudioElapsedFraction(
                        +(newPodcastCurrentSecond / props.podcastDuration.durationSeconds).toFixed(6)
                    );
                    return newPodcastCurrentSecond;
                });
            } else {
                // This handles updating the progress bar and duration counter
                setCurrentAudioCurrentSecond((prevPodcastCurrentSecond) => {
                    const newPodcastCurrentSecond = prevPodcastCurrentSecond + 1 * playbackSpeed; // Weird number, needs to be a tiny bit higher
                    setCurrentAudioElapsedFraction(
                        +(newPodcastCurrentSecond / convertTimeSpan(props.podcast.audioUrls[podcastAudioNumber].duration)).toFixed(6)
                    );
                    return newPodcastCurrentSecond;
                });
            }


            if (e.playedSeconds >= convertTimeSpan(props.podcast.audioUrls[podcastAudioNumber].duration)) {
                // The previous item is what's currently being dealt with
                if (props.podcast.audioUrls[podcastAudioNumber].advertId === null && props.podcast.audioUrls[podcastAudioNumber].canSeek === false) {
                    //console.log("indent finshed")
                } else if (props.podcast.audioUrls[podcastAudioNumber].canSeek === false && advertPlaying) {
                    // props.podcast.audioUrls[podcastAudioNumber].advertId here for api to say it's been listened
                    const copyArray = listenedAds
                    copyArray.push(props.podcast.audioUrls[podcastAudioNumber].advertId)
                    setListenedAds(copyArray)
                    //Add call to triger add listened to.

                    advertPlayed(props.podcast.audioUrls[podcastAudioNumber].advertId, props.podcast.podcastId, props.podcast.audioUrls[podcastAudioNumber].position)

                    //console.log("advert finished")
                } else {
                    //console.log("section finished and/or skipped")
                }

                //setIsPlaying(false)
                if (podcastAudioNumber <= props.podcast.audioUrls.length - 2) {
                    setAdvertPlaying(!props.podcast.audioUrls[podcastAudioNumber + 1].canSeek) // Look at next
                    setCurrentPlayingAudio(props.podcast.audioUrls[podcastAudioNumber + 1].url)
                    setPodcastAudioNumber(podcastAudioNumber + 1)
                    //setIsPlaying(true)
                } else {
                    setPodcastCurrentSecond(0)
                    setIsPlaying(false) // Podcast over including all adverts
                }
            }
        }
    };

    // UTILITY/CALLBACk
    const handleSeek = async (position) => {
        const posInSeconds = position * props.podcastDuration.durationSeconds // Get seconds of where user clicked
        const onlyPodcast = props.podcast.audioUrls.map((e, index) => ({
            index: index, duration: convertTimeSpan(e.duration), canSeek: e.canSeek, advertId: e.advertId, url: e.url, position: e.position
        }))// Map timespans and index
        let totalTime = 0
        for (const item of onlyPodcast) { // Loop through durations

            if (item.canSeek === true) {
                totalTime += item.duration // Add durations on to total time
            }

            if (item.advertId !== null && !listenedAds.includes(item.advertId)) { // Add any skipped adverts that haven't been listened  
                const copyArray = advertArray
                copyArray.push(item)
                setAdvertArray(copyArray)
                continue;
            }

            if (posInSeconds <= totalTime) {
                const timeInCurrentClip = posInSeconds - (totalTime - item.duration) // totaltime holds the item we're looking at, remove it
                const newTime = +(timeInCurrentClip / item.duration).toFixed(6) // Get the time in current clip as a fraction

                setPodcastCurrentSecond(posInSeconds) // set seconds in relation to entire duration, main function handles slider position

                for (const advert of advertArray) { // If adverts skipped, force play them here
                    setAdvertPlaying(true)
                    setIsPlaying(false)
                    setCurrentPlayingAudio(advert.url)
                    setPodcastAudioNumber(advert.index) // Set the current playing audio with the index number too as it won't be available in state yet
                    setIsPlaying(true)
                    await new Promise(resolve => setTimeout(resolve, advert.duration * 1000))
                    const copyArray = listenedAds
                    copyArray.push(advert.advertId)


                    //Add call to triger add listened to.
                    advertPlayed(advert.advertId, props.podcast.podcastId, advert.position)
                    setListenedAds(copyArray)
                    //console.log("advert finished playing, advert Id: ", advert.advertId)
                }
                setAdvertArray([]) // Clear array after loop finishes

                if (item.index === podcastAudioNumber) {
                    playerRef.current.seekTo(newTime)
                } else {
                    setIsPlaying(false)
                    setPodcastAudioNumber(item.index) // Set current audio index number
                    setCurrentPlayingAudio(props.podcast.audioUrls[item.index].url) // Set the current playing audio with the index number too as it won't be available in state yet
                    setIsPlaying(true)
                    setSeekTo(newTime) // Set location to seek to
                    setWaitingSeek(true) // Set bool for audioReady from player so we don't skip on nothing
                }

                break;
            }
        }
    };

    const handleProgressBar = (e) => {
        if (!advertPlaying) {
            handleSeek(e.target.value);
        }
    };

    const convertTimeSpan = (timeString) => {
        const [timePart, milliseconds] = timeString.split('.');
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        let totalSeconds = hours * 3600 + minutes * 60 + seconds;

        if (milliseconds) {
            totalSeconds += parseFloat(`0.${milliseconds}`);
        }

        return totalSeconds;
    };

    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const formattedHours = hours > 0 ? String(hours).padStart(2, '0') : '';
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        const formattedTime = [formattedHours, formattedMinutes, formattedSeconds]
            .filter((value) => value !== '')
            .join(':');

        return formattedTime;
    };

    const formatDurationHours = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    useEffect(() => {
        if (props.podcast) {
            setCurrentPlayingAudio(props.podcast.audioUrls[podcastAudioNumber].url)
            if (props.isAdvertPlaying !== undefined) {
                setAdvertPlaying(props.isAdvertPlaying);
            } else {
                setAdvertPlaying(true)
            }

            setPodcastCurrentSecond(0);
            setCurrentAudioCurrentSecond(0);
            setCurrentAudioElapsedFraction(0);
            setListenedAds([]);
            setPodcastAudioNumber(0);
        }
    }, [props.podcast])



    useEffect(() => {
        const speedOptions = [1, 1.5, 2]
        setPlaybackSpeed(speedOptions[playbackSpeedSelection])
    }, [playbackSpeedSelection])

    return (
        <>
            <ReactPlayer
                url={currentPlayingAudio}
                height="0"
                width="100%"
                playing={isPlaying}
                progressInterval={1000}
                ref={playerRef}
                playbackRate={playbackSpeed}
                onReady={() => handleAudioReady()}
                onProgress={(e) => handleTimes(e)} //Calls every second

            />

            {audioLoaded ? (
                <>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        width: "25%",*/}
                        {/*        display: "flex",*/}
                        {/*        justifyContent: "space-around",*/}
                        {/*        alignItems: "center",*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <svg*/}
                        {/*        width="50"*/}
                        {/*        height="50"*/}
                        {/*        viewBox="0 0 24 24"*/}
                        {/*        style={{*/}
                        {/*            fill: "#000",*/}
                        {/*            transition: "fill 0.3s ease",*/}
                        {/*            cursor: "pointer",*/}
                        {/*        }}*/}
                        {/*        onClick={togglePlay}*/}
                        {/*    >*/}
                        {/*        {isPlaying ? (*/}
                        {/*            <path d="M14 5h4v14h-4zm-10 0h4v14h-4z" />*/}
                        {/*        ) : (*/}
                        {/*            <path d="M8 5v14l11-7z" />*/}
                        {/*        )}*/}
                        {/*    </svg>*/}

                        {/*    <svg*/}
                        {/*        width="50"*/}
                        {/*        height="50"*/}
                        {/*        viewBox="0 0 24 24"*/}
                        {/*        style={{*/}
                        {/*            fill: "#000",*/}
                        {/*            transition: "fill 0.3s ease",*/}
                        {/*            cursor: "pointer",*/}
                        {/*        }}*/}
                        {/*        onClick={stopAudio}*/}
                        {/*    >*/}
                        {/*        <rect width="12" height="12" y="6" x="6" />*/}
                        {/*    </svg>*/}
                        {/*</div>*/}

                        <div style={{ width: "100%" }}>
                            <input
                                type="range"
                                min={0}
                                max={0.999999}
                                step="any"
                                style={{ width: "100%" }}
                                onChange={(e) => handleProgressBar(e)}
                                value={currentAudioElapsedFraction}
                            />
                        </div>

                        {/*<div style={{ width: "20%" }}>*/}
                        {/*    <p style={{ marginBottom: 0, textAlign: "center" }}>*/}
                        {/*        {formatDuration(podcastCurrentSecond)} / {props.podcastDuration.durationTimeSpan}*/}
                        {/*    </p>*/}
                        {/*</div>*/}

                        {/*<div style={{ width: "10%", textAlign: "center", cursor: "pointer" }}>*/}
                        {/*    <p onClick={!advertPlaying ? () => ChangePlaybackSpeed() : null}>x{playbackSpeed}</p>*/}
                        {/*</div>*/}
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "100%" }}>
                        <div className="time-col">
                            <p>{formatDuration(currentAudioCurrentSecond)}</p>
                        </div>
                        <div

                        >
                            <svg
                                width="50"
                                height="50"
                                viewBox="0 0 24 24"
                                style={{
                                    fill: "#000",
                                    transition: "fill 0.3s ease",
                                    cursor: "pointer",
                                }}
                                onClick={togglePlay}
                            >
                                {isPlaying ? (
                                    <path d="M14 5h4v14h-4zm-10 0h4v14h-4z" />
                                ) : (
                                    <path d="M8 5v14l11-7z" />
                                )}
                            </svg>

                            {/*<svg*/}
                            {/*    width="50"*/}
                            {/*    height="50"*/}
                            {/*    viewBox="0 0 24 24"*/}
                            {/*    style={{*/}
                            {/*        fill: "#000",*/}
                            {/*        transition: "fill 0.3s ease",*/}
                            {/*        cursor: "pointer",*/}
                            {/*    }}*/}
                            {/*    onClick={stopAudio}*/}
                            {/*>*/}
                            {/*    <rect width="12" height="12" y="6" x="6" />*/}
                            {/*</svg>*/}
                        </div>
                        <div className="time-col" style={{ textAlign: "right" }}>
                            <p>{currentAudioLength}</p>
                        </div>
                    </div>
                </>
            ) : (
                <div style={{ display: "flex", width: "100%", justifyContent: "center" }} >
                    <Spinner style={{ color: "#F33B30" }} />
                </div>
            )}
        </>
    );
}


export default AudioPlayerLight;