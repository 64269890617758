/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import ReactPlayer from "react-player";

// reactstrap components
import { FormGroup, Input, Row, Col, Label, Button, UncontrolledTooltip, Spinner } from "reactstrap";
import GBFlag from "assets/img/flags/GB.png"
import ESFlag from "assets/img/flags/ES.png"
import CNFlag from "assets/img/flags/CN.png"
import FRFlag from "assets/img/flags/FR.png"
import USFlag from "assets/img/flags/US.png"
import IEFlag from "assets/img/flags/IE.png"
import AUFlag from "assets/img/flags/AU.png"
import CAFlag from "assets/img/flags/CA.png"
import NZFlag from "assets/img/flags/NZ.png"
import SAFlag from "assets/img/flags/SA.png"

import tooltipText from "../../../config/tooltipText";

import VoiceCard from "./VoiceCard";


const BStep2 = React.forwardRef((props, ref) => {

    const [searchResults, setSearchResults] = useState([])
    const [singleSelectLanguages, setSingleSelectLanguages] = useState({
        value: 11,
        label: "English"
    })
    const [selectedVoiceName, setSelectedVoiceName] = useState(null)
    const [alert, setAlert] = useState(null)

    const handleLanguagesChange = (value) => {
        setSingleSelectLanguages(value)
        props.setBaseObject({ ...props.baseObject, defaultLanguageId: value.value })

        const originalSearch = props.dropdownVoices
       
        setSearchResults(originalSearch.filter((e) => e.voiceAiValue.substring(0, 2) === value.aiValue))
    }

    const [selectedVoice, setSelectedVoice] = useState(null)
    const handleSelectVoice = (voice) => {
        setSelectedVoice(voice.voiceName)
        props.setBaseObject({ ...props.baseObject, defaultVoiceId: voice.id})
    }

    const [selectedVoiceUrl, setSelectedVoiceUrl] = useState(null)
    const [isVoicePreview, setIsVoicePreview] = useState(false)
    const handlePreviewVoice = (previewUrl) => {
        setIsVoicePreview(false)
        setSelectedVoiceUrl(previewUrl)
        setIsVoicePreview(true)
    }

    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            if (selectedVoice != null) {
                props.setTitleNumber(props.titleNumber + 1)
                return true
            }
            setAlert("Please select a voice")
            return false
        },
    }));

    const [isDarkMode, setIsDarkMode] = useState(() => {
        // Set initial state based on localStorage
        return localStorage.getItem("light-mode") !== "lightmode";
    });

    useEffect(() => {
        const handleStorageChange = () => {
            setIsDarkMode(localStorage.getItem("light-mode") !== "lightmode");
        };

        // Add the event listener
        window.addEventListener("lightmodechanged", handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("lightmodechanged", handleStorageChange);
        };
    }, []);

  return (
    <>
          <Col>
              <h4 className="text-center" style={{ color: "#F33B30" }}><strong>{alert}</strong></h4>
              
                  <ReactPlayer url={selectedVoiceUrl}
                      playing={isVoicePreview}
                      controls={false}
                      height='0px'
                      width='0px'
                      className="podcastPlayer"
                      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  />    
              
              {props.isVoicesLoaded ?
                  <>
                      {props.dropdownVoices.length > 0 &&
                          <>
                          {/* 
                          <Row className="justify-content-center">
                              <Col sm="12" md="12" lg="6">
                                  <FormGroup>
                                      <Label>Languages</Label>
                                      <Button
                                          className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                          id="tooltip8089663901212"
                                          size="sm"
                                          style={{ backgroundColor: "white" }}
                                      >
                                          <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                      </Button>
                                      <UncontrolledTooltip
                                          delay={0}
                                          target="tooltip8089663901212"
                                          style={{ backgroundColor: "#F33B30", color: "white" }}
                                      >
                                          <p style={{ margin: 0 }}>{tooltipText.VOICE.LANGUAGE}</p>
                                      </UncontrolledTooltip>
                                  </FormGroup>
                                  <Select
                                      className="react-select "
                                      classNamePrefix="react-select"
                                      name="singleSelect"
                                      value={singleSelectLanguages}
                                      onChange={(value) => handleLanguagesChange(value)}
                                      options={props.dropdownLanguages}
                                  />
                              </Col>
                          </Row>    
                          */ }
                          <Row>
                              <Col className="text-center  text-white pb-2">
                                  <h4>Selected voice: {selectedVoice != null ? selectedVoice : "No selection"}</h4>
                              </Col>
                          </Row>
                      </>   
                      }
                      <Row className="justify-content-center">
                          <Col style={{ maxHeight: "50vh", overflow: "auto" }}>
                              {props.dropdownVoices.length > 0 ? (

                                  <Row style={{overflowY: "auto"}}>
                                      {props.dropdownVoices.map((e, index) => {
                                          return (
                                              <Col sm="6">
                                                  <VoiceCard voiceData={e} index={index} handlePreviewVoice={handlePreviewVoice} handleSelectVoice={handleSelectVoice} showId={false} selectedVoice={selectedVoice} isAdVoice={true} colorSchemeTheme={isDarkMode ? "dark" : "light"} />
                                              </Col>
                                          )
                                      })}
                                  </Row>


                              ) : 
                                  <Row>
                                      <Col className="text-center">
                                          <h4>No voices found, please refresh or try again later</h4>
                                      </Col>
                                  </Row> 
                          }
                          </Col>
                      </Row>
                  </> : <Row>
                      <Col className="text-center text-white">
                          <h4>Loading voices</h4>
                          <Spinner style={{ color: "#F33B30" }} />
                      </Col>
                  </Row> 
              }
      </Col>
    </>
  );
});

export default BStep2;
