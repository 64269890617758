/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";


// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input

} from "reactstrap";

function TermsAndConditions() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>

            <div className="content" style={{ marginTop: 78, paddingLeft: "5vh", paddingRight: "5vh" }}>
                <CardBody>
                    <Col className="text-black">
                        <h1>Terms and Conditions</h1>
                        <h3>Please read the following Legal Notice carefully.</h3>

                        <p>This Legal Notice relates to our website at <a style={{ textDecoration: "underline", color: "#F33B30" }} href="/home">www.klaxon.ai</a> and any other websites and online services operated by us (collectively and individually, the <strong>"Site"</strong>). Where the context allows, references to <strong>"Klaxon", "we", "us"</strong> or <strong>"our"</strong> include any of our affiliates.</p>

                        <p>If you do not wish to use the Site on the basis set out below, please do not use the Site.</p>

                        <h3>A special note about children</h3>
                        <p>Some content that is accessible on or via the Site may not be appropriate for children under the age of 18. For this reason, parents or guardians who wish to allow their children to access the Site should carefully supervise their children's access to and use of the Site and determine whether or not such content is appropriate for their children.</p>

                        <h3>Limitations of liability</h3>
                        <p>While we make every effort to ensure the accuracy and reliability of our services, we make no warranties or guarantees as to their suitability for any purpose. We accept no responsibility for any use made of the information provided.</p>
                        <p>Certain links on the Site lead to websites over which we have no control. We cannot accept any responsibility for such websites, or for your use of them, or for their accuracy, completeness, legality, satisfactory quality, fitness for purpose, freedom from a computer virus, operability or availability. The presentation of links to external websites on the Site does not imply any association with, nor endorsement by or of, the publishers of such websites. We encourage you to be aware when you leave the Site and to read the legal notices and policies of each other website that you visit.</p>
                        <p>Nor do we warrant, guarantee, endorse or assume responsibility for any product or service advertised or offered by a third party through the Site or any linked website.</p>
                        <p>Accordingly, we shall not be liable to you for any loss or damage of any kind arising out of or in any way relating to the Site, our services, your use of (or inability to use) the Site and/or the materials, information or other content accessible on or via the Site (whether or not we have been advised of, or should have been aware of, the possibility of any such loss or damage arising). Nothing, however, in this Legal Notice excludes or limits our responsibility for any liability that cannot be excluded or limited under applicable law.</p>

                        <h3>International use</h3>
                        <p>The Site is maintained, controlled and operated by us from our facilities in the United Kingdom. We make no representation that the Site or content accessible on or via the Site is appropriate or available for use in other locations. If you access and use the Site from any other jurisdiction, you do so on your own initiative and at your own risk and are responsible for complying with all laws and regulations that apply to your use of the Site and such content.</p>

                        <h3>Language</h3>
                        <p>If we have provided you with a translation of the original English-language version of any notice, policy or other document posted on the Site, the translation is provided for your convenience only. If there is any inconsistency between the English-language version and the translation, the English-language version shall take precedence. In each case, the English-language version is available on the Site.</p>

                        <h3>Governing law</h3>
                        <p>The Site is provided on the basis that these terms and any dispute or claim connected with the Site (contractual or non-contractual) shall be governed by, and construed in accordance with, the law of England, whose courts shall have exclusive jurisdiction.</p>

                        <h3>Changes to this Legal Notice</h3>
                        <p>We may change this Legal Notice from time to time. If so, we will post the revised version on the Site. You are encouraged to check the terms of such revised versions. If the changes are significant, we will provide a more prominent notice and/or email notification of the changes. Each version of this Legal Notice will be identified at the top of the page by its effective date, and we will also keep previous versions of this Legal Notice in an archive for your review. If you use the Site after the date on which this Legal Notice has been changed, we will treat such use as your consent to the revised Legal Notice.</p>

                        <h3>About us</h3>
                        <p>Klaxon AI Limited is a company registered in England and Wales under company number 15110517, trading under the names ‘Klaxon’, ‘Klaxon AI’, ‘Klaxon.ai’, ‘Klaxonai.com’.</p>
                        <p>If you have any queries, comments or feedback about the Site, please contact us <a style={{ textDecoration: "underline", color: "#F33B30" }} href="mailto:hello@klaxon.ai">here</a> or write to us at our registered office: Klaxon AI Ltd, 2 Water Street, Stamford, Lincolnshire, PE9 2NJ, UK.</p>

                    </Col>
                </CardBody>
            </div>
        </>
    );
}

export default TermsAndConditions;
