/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";


// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input

} from "reactstrap";

function ModernSlaveryStatement() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>

            <div className="content" style={{ marginTop: 78, paddingLeft: "5vh", paddingRight: "5vh" }}>
                <CardBody>
                    <Col className="text-white">
                        <h1>Modern Slavery Statement</h1>
                        <p>Our business, Klaxon AI Ltd is committed to combatting slavery and human trafficking in its business and supply chains, and we make this statement to assist with compliance with the Modern Slavery Act 2015. This statement relates to the financial year ending 31 March 2025.</p>
                        <p>As our business has a turnover of less than £36 million, we do not have a legal obligation to produce a modern slavery statement. However:</p>

                        <ol type="A">
                            <li>We agree that exploitation within all supply chains ending in the UK is a blight on our society, and we are committed to playing our part in eliminating exploitation;</li>
                            <li>We understand that customers with obligations under the Modern Slavery Act 2015 cannot comply with those duties without our cooperation.</li>
                        </ol>

                        <p>To that end, we confirm that we have examined our own business and, to the extent that it is reasonably practicable, businesses within our supply chain and we confirm the following:</p>

                        <ol type="A">
                            <li>We confirm that within our own business, no relevant offence relating to slavery or human tracking has been committed.</li>
                            <li>We have made enquiries of businesses that supply directly to us and we are confident that no relevant offence is committed in that business.</li>
                            <li>Insofar as it was reasonably practicable, we have examined our supply chains and found no evidence of slavery or human trafficking. We will keep the situation under review and will alter our supply chain if necessary.</li>
                        </ol>

                        <p>Further details about our business and supply chain are provided below.</p>
                        <p>Our business provides an online podcast creation, publishing, and advertising service.</p>

                        <p>Our business structure is as an independent UK limited company led by a Chief Executive Officer (CEO) and directors operating from different locations.</p>
                        <p>We operate in the following countries:</p>
                        <ul>
                            <li>UK</li>
                        </ul>

                        <p>Our supply chain is as follows:</p>

                        <ul>
                            <li>US and UK based software providers.</li>
                            <li>UK based marketing agencies.</li>
                        </ul>
                        

                        <p>We understand that certain industry sectors and geographical regions entail greater risk of exploitation than others. We do not believe that our supply chain is in one of those sectors.</p>
                        <p>Where it is reasonably practicable, we ensure that businesses in our supply chain have made a similar statement relating to slavery and human trafficking.</p>
                        <p>The person in our business responsible for assessing matters relating to slavery and human trafficking is CEO Arup Biswas.</p>
                        <p>We also encourage all employees to report on any matters relating to slavery or human trafficking in our supply chains of which they become aware.</p>

                    </Col>
                </CardBody>
            </div>
        </>
    );
}

export default ModernSlaveryStatement;
