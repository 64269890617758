import MusicWaveform from "./musicEditor/MusicWaveform"
import UploadMusic from "./musicEditor/UploadMusic"
import {useState, useEffect } from "react"

const MusicEditor = (props) => {
    const [fileURL, setFileURL] = useState(null)

    return (
        <>
            {!fileURL ?
                <UploadMusic fileURL={fileURL} setFileURL={setFileURL} setBaseObject={props.setBaseObject} baseObject={props.baseObject} /> : <MusicWaveform fileURL={fileURL} setFileURL={setFileURL} setBaseObject={props.setBaseObject} baseObject={props.baseObject} />
            }
        </>
    )
}

export default MusicEditor