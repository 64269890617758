/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import classnames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import klaxonLogo from "assets/img/klaxonImages/KlaxonLogoBlack.png";
import {Row, Col  } from "reactstrap"

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
  DropdownItem
} from "reactstrap";

import getJWTToken from "../../config/jwtToken";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function LightNavbar(props) {
  const location = useLocation();
  const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [color, setColor] = React.useState("navbar-transparent");
    const [navType, setNavType] = useState("search")
  const [searchBar, setSearchBar] = useState("")

  const navigation = useNavigate();

  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
    setCollapseOpen(!collapseOpen);
  };

    const navigateSite = (link) => {
        navigation(`/${link}`)
    }

  const handleNavigateHome = () => {
      if (location.pathname !== "/voices") {
          navigation("/home");
      } else {
          window.location.href = "https://www.klaxon.ai/waitlist"
      }
  };

  const handleNavigateContactUs = () => {
    navigation("/contact-us");
    };

    const handleNavigateLogin = () => {
        //navigation("/auth/login")
        Swal.fire({
            position: "center",
            title: "Accounts coming soon",
            showConfirmButton: true,
        });
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            
                handleSearch();
            
        }
    }

    const handleSearch = () => {
        if (location.pathname !== "/voices") {
            if (searchBar !== "") {
                navigation(`/home?search=${searchBar}`)
            } else {
                navigation(`/home`)
            }
        } else {
            if (searchBar !== "") {
                handleVoiceSearch()
            } else {
                handleVoiceSearch(null)
            }
        }
    }

    const handleVoiceSearch = (searchString = searchBar) => {
        const searchEvent = new CustomEvent('voiceSearch', {
            detail: { searchString: searchString }
        });
        document.dispatchEvent(searchEvent);
    }

    const [isNav, setIsNav] = useState(true)
    useEffect(() => {
        if (location.pathname == "/clone" || location.pathname == "/waitlist") {
            setIsNav(false)
        } else {
            setIsNav(true)
        }
    }, [location.pathname])

  return (
    <Navbar
          className={classnames("navbar-absolute fixed-top", color)}
          expand="lg"
          style={{ display: !isNav && "none"} }
    >
      <Container fluid>
              <Row className="pt-2 pb-2" style={{ width: "100%", margin: "auto", backgroundColor: "#F0F0F0", borderRadius: 45 }}>
                  <Col style={{display: "flex"}} sm="2">
                      <div className="navbar-wrapper">
                          <NavbarBrand onClick={handleNavigateHome} style={{cursor: "pointer"}}>
                              <img src={klaxonLogo} style={{ width: 150, marginLeft: 15 }} />
                          </NavbarBrand>
                      </div>
                  </Col>
                  <Col style={{ display: "flex", justifyContent: "center" }} sm="1" lg="7" className="_respNav">
                      <Collapse isOpen={collapseOpen} className="justify-content-center" navbar>
                          <Nav navbar style={{ padding: 5, border: "", borderRadius: 30, width: "100%", display: "flex", justifyContent: "center", minHeight: 50, backgroundColor: ""}}>
                              {navType === "navigation" ?
                                  <>
                                      <NavItem>
                                          <NavLink
                                              to="/home"
                                              className={`lightmodeNavLink navSize mr-4 nav-link ${location.pathname === "/home" && "activeLink"
                                                  }`}
                                          >
                                              Home
                                          </NavLink>
                                      </NavItem>
                                      <NavItem>
                                          <NavLink
                                              to="/how-it-works"
                                              className={`lightmodeNavLink navSize mr-4 nav-link ${location.pathname === "/how-it-works" && "activeLink"
                                                  }`}
                                          >
                                              Features
                                          </NavLink>
                                      </NavItem>
                                      <NavItem>
                                          <NavLink
                                              to="/pricing"
                                              className={`lightmodeNavLink navSize mr-4 nav-link ${location.pathname === "/pricing" && "activeLink"
                                                  }`}
                                          >
                                              Pricing
                                          </NavLink>
                                      </NavItem>
                                      <NavItem>
                                          <NavLink
                                              to="/about-us"
                                              className={`lightmodeNavLink navSize mr-4 nav-link ${location.pathname === "/about-us" && "activeLink"
                                                  }`}
                                          >
                                              About Us
                                          </NavLink>
                                      </NavItem>
                                      <NavItem>
                                          <NavLink
                                              to="/advertise"
                                              className={`lightmodeNavLink navSize nav-link ${location.pathname === "/advertise" && "activeLink"
                                                  }`}
                                          >
                                              Advertise
                                          </NavLink>
                                      </NavItem>
                                      <NavItem>
                                          <NavLink
                                              to="/faq"
                                              className={`lightmodeNavLink navSize nav-link ${location.pathname === "/faq" && "activeLink"
                                                  }`}
                                          >
                                              FAQ
                                          </NavLink>
                                      </NavItem>
                                  </> : null
                                  //<input type="text" placeholder="Search" className="navSearch" value={searchBar} onChange={(e) => setSearchBar(e.target.value)} onKeyDown={handleKeyPress} />
                          }
                              
                          </Nav>
                          <div style={{ position: "relative", right: "5%", fontSize: 22, margin: 0, display: "flex" }}></div>
                      </Collapse>

                  </Col>
                  <Col style={{ alignItems: "center", display: "flex", justifyContent: "flex-end" }} sm="3">
                      <Row>
                          <Col className="pt-1">
                              {/*<NavLink*/}
                              {/*    to="/contact-us"*/}
                              {/*    className={`lightmodeNavLink navSize nav-link ${location.pathname === "/contact-us" && "activeLink"*/}
                              {/*        }`}*/}
                              {/*>*/}
                              {/*    Contact Us*/}
                              {/*</NavLink>*/}
                          </Col>
                          <Col>
                              {location.pathname !== "/voices" && location.pathname !== "/home" && location.pathname !== "/privacy" && location.pathname !== "/terms-and-conditions" && !location.pathname.startsWith('/podcasts') &&<button className="lightmodeLogin" onClick={handleNavigateLogin}>Login</button> }
                          </Col>
                      </Row>
                      <Nav>
                          <Row style={{display: "flex", justifyContent: "center", alignItems: "center"} }>
                              

                              

                              <NavItem className="mobileNav">
                                  <UncontrolledDropdown nav style={{ display: "flex", alignItems: "center", border: 0 }}>
                                      <DropdownToggle
                                          className="noBorder changeP"
                                          aria-haspopup={false}
                                          caret
                                          color="default"
                                          data-toggle="dropdown"
                                          id="navbarDropdownMenuLink"
                                          nav
                                      >
                                          <i className="nc-icon nc-single-02" />

                                      </DropdownToggle>
                                      
                                      <DropdownMenu
                                          persist
                                          aria-labelledby="navbarDropdownMenuLink"
                                          right
                                      >
                                          
                                          <DropdownItem
                                              onClick={() => navigateSite("home")}

                                          >
                                              Home
                                          </DropdownItem>
                                          <DropdownItem
                                              onClick={() => navigateSite("auth/login")}
                                              className="loginMobile"

                                          >
                                              {getJWTToken().decoded === null ? "Login" : "Account"}
                                          </DropdownItem>
                                          <DropdownItem
                                              onClick={() => navigateSite("how-it-works")}

                                          >
                                            Features
                                          </DropdownItem>
                                          <DropdownItem

                                              onClick={() => navigateSite("library")}
                                          >
                                            Library
                                          </DropdownItem>
                                          <DropdownItem
                                              onClick={() => navigateSite("pricing")}

                                          >
                                            Pricing
                                          </DropdownItem>
                                          <DropdownItem
                                              onClick={() => navigateSite("about-us")}

                                          >
                                            About us
                                          </DropdownItem>
                                          <DropdownItem
                                              onClick={() => navigateSite("advertise")}

                                          >
                                            Advertise
                                          </DropdownItem>
                                          <DropdownItem
                                              onClick={() => navigateSite("faq")}

                                          >
                                            FAQ
                                          </DropdownItem>
                                          <DropdownItem
                                              onClick={() => navigateSite("contact-us")}

                                          >
                                            Contact us
                                          </DropdownItem>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                              </NavItem>
                              


                          </Row>
                          
                      </Nav>
                  </Col>
              </Row>
      </Container>
    </Navbar>
  );
}

export default LightNavbar;
