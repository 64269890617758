/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";

import defaultImage from "../../assets/img/header.jpg"
import MyModal from "components/Shared/MyModal";
import CreatePodcast from "./components/CreatePodcast";
import FeedbackForm from "./components/FeedbackForm"

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input

} from "reactstrap";

function Feedback() {

    const [userInfo, setUserInfo] = useState([])

    const getUser = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER.GET_SINGLE + `?id=${getJWTToken().decoded.nameid}`,
                "get"
            );
            
            setUserInfo(resp.data)


        } catch (error) {
            console.error(error.message);
        }

    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <>

            <div className="content" style={{ marginTop: 78, paddingLeft: "5%", paddingRight: "5%" }}>
                <Row>
                    <Col md="12">
                        
                            
                            <CardBody className="mt-4 mb-4">
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <FeedbackForm name={ userInfo.name } email={ userInfo.email } />
                                </Col>
                                    
                                </Row>
                            </CardBody>
                        
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Feedback;
