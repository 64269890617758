import { useState } from "react";
import VoiceCard from "../BusinessWizardSteps/VoiceCard";
import { useEffect } from "react";

const AdMusic = ({ setSelectedModalObject, selectedModalObject, music, handlePreviewVoice, currentPlayingVoice }) => {

    const [selectedMusic, setSelectedMusic] = useState(null)

    useEffect(() => {
        const isValidMusic = music.find(music => music.id == selectedModalObject.musicId)?.name

        if (isValidMusic) {
            setSelectedMusic(isValidMusic)
        } else {
            setSelectedMusic(null)
        }
    }, [selectedModalObject.musicId])

    return (
        <div className="formStyle pt-2">
            <p className="pl-4">Choose from our library of music.</p>
            <div style={{ display: "flex", flexWrap: "wrap", gap: 10, justifyContent: "center" }}>
                {music.map((e, index) => {
                    return (
                        <div style={{ width: "47%", backgroundColor: "" }}>
                            <VoiceCard voiceData={e} index={index} colorSchemeTheme={"dark"} selectedVoice={selectedMusic && selectedMusic} isAdMusic={true} setSelectedModalObject={setSelectedModalObject} selectedModalObject={selectedModalObject} showId={false} handlePreviewVoice={handlePreviewVoice} currentPlayingVoice={currentPlayingVoice} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AdMusic