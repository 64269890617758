import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import axios from "axios";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";

import { useLocation, useNavigate } from "react-router-dom"

import ReactPlayer from "react-player";



// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input

} from "reactstrap";

import { Shimmer } from "react-shimmer"
import AudioPlayer from "../components/AudioPlayer";
import AudioPlayerLight from "views/klaxonai/lightmodeTest/AudioPlayerLight"


function PodcastPlayerLight() {
    const location = useLocation()
    const navigation = useNavigate()



    const [podcastId, setPodcastId] = useState(new URLSearchParams(location.search).get('podcastId'))
    const [trendingData, setTrendingData] = useState([])
    const [suggestedData, setSuggestedData] = useState([])
    const [podcast, setPodcast] = useState("")
    const [podcastDuration, setPodcastDuration] = useState(0)
    const [topicData, setTopicData] = useState([])
    const [tagOption, setTagOption] = useState("suggested")

    const [isPodastInvalid, setIsPodcastInvalid] = useState(false)

    const getPodcast = async () => {
        //setIsPodcastInvalid(false)
        let url = `${apiConfig.baseUrl}${apiConfig.PODCAST_EPISODE.GET_SINGLE}?id=${podcastId}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.audioUrls.length > 0) {
                        setPodcast(res.data)
                    } else {
                        setIsPodcastInvalid(true)
                    }

                }

            })
            .catch((err) => {
                setIsPodcastInvalid(true)
            });

    }

    const getTrending = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_TRENDING}`;

        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setTrendingData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }


    const getSuggested = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_SUGGESTED}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setSuggestedData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }

    //Probably better just to load the id straight in to state and remake the call
    const handleSelectPodcast = (podcastId) => {

        setPodcastId(podcastId)


    }

    const getTopics = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_SUGGESTED_TOPICS}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setTopicData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }


    useEffect(() => {
        getSuggested()
        getTrending()
        getPodcast()
        getTopics()
        setIsPodcastInvalid(false)
    }, [location]);

    useEffect(() => {
        //getPodcast()// <- Is currently making a second call, but not super sure if I fixed this part
        navigation(`/player?podcastId=${podcastId}`)
    }, [podcastId])

    //Test stuff
    const convertTimeSpan = (timeString) => {
        const [timePart, milliseconds] = timeString.split('.');
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        let totalSeconds = hours * 3600 + minutes * 60 + seconds;

        if (milliseconds) {
            totalSeconds += parseFloat(`0.${milliseconds}`);
        }

        return totalSeconds;
    };


    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const formattedHours = hours > 0 ? String(hours).padStart(2, '0') : '';
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        const formattedTime = [formattedHours, formattedMinutes, formattedSeconds]
            .filter((value) => value !== '')
            .join(':');

        return formattedTime;
    };


    const getDuration = () => {
        let durationObject = { durationSeconds: 0, durationTimeSpan: "00:00:00" };
        podcast.audioUrls.forEach((e) => {
            if (e.canSeek === true) {
                durationObject.durationSeconds += convertTimeSpan(e.duration)
            }
        })
        durationObject.durationTimeSpan = formatDuration(durationObject.durationSeconds)
        setPodcastDuration(durationObject)
    }

    useEffect(() => {
        if (podcast) {
            getDuration()
        }
    }, [podcast])


    return (
        <>

            <div className="content" style={{ marginTop: 156, paddingLeft: "5%", paddingRight: "5%", paddingBottom: "3%" }}>

                <Row className="justify-content-around">
                    <Col className="playerContainer pt-4 pb-4" style={{ justifyContent: "center", backgroundColor: "#F8F8F8", borderRadius: 25 }} sm="5">
                        {!isPodastInvalid ?
                            <div className="player-check" style={{ backgroundColor: "", padding: 25, borderRadius: 5, color: "black", fontWeight: 600 }}>
                                <Row className="mb-3">
                                    <Col>
                                        {podcast ? <img src={podcast.imageUrl ? podcast.imageUrl : null} style={{ height: "100%", width: "100%", borderRadius: 25 }} /> : <div style={{ display: "flex", justifyContent: "center" }}><Shimmer width={150} height={150} /></div>}
                                    </Col>
                                    <Col style={{}} sm="7">
                                        <Row><h2 style={{ fontWeight: "bold", color: "lightgray" }}>Now Playing</h2></Row>
                                        <Row><h3 style={{ fontWeight: "bold" }}>{podcast.name}</h3></Row>
                                        <Row><p style={{ color: "red" }}>{podcast ? `Episode ${podcast.episodeNumber}` : ""}</p></Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <AudioPlayerLight podcast={podcast} podcastDuration={podcastDuration} />
                                </Row>
                            </div>
                            : <div className="text-center">
                                <p>Podcast not found</p>
                                <p>Please try again later</p>
                            </div>
                        }
                    </Col>

                    <Col sm="5" style={{ backgroundColor: "#F8F8F8", borderRadius: 25, padding: 25 }}>



                        <Row className="mb-2">
                            <p className={`mr-1 mr- 1 topicItem lightmodeTopic ${tagOption === "suggested" ? "topicItemActiveLight" : ""}`} onClick={() => setTagOption("suggested")}>Suggested</p>
                            <p className={`mr-1 mr- 1 topicItem lightmodeTopic ${tagOption === "trending" ? "topicItemActiveLight" : ""}`} onClick={() => setTagOption("trending")}>Trending</p>
                            {/*<p className={`mr-1 mr- 1 topicItem lightmodeTopic ${tagOption === "saved" ? "topicItemActiveLight" : ""}`} onClick={() => setTagOption("saved")}>Saved</p>*/}

                        </Row>
                        <Row>
                            {tagOption === "suggested" &&
                                <div className="scrollingItemsPodcast" style={{ gap: 15 }}>
                                    {
                                        suggestedData.length > 0 ?
                                            suggestedData.map((e) => {
                                                return (
                                                    <div className="lightmodeCardPodcast" onClick={() => handleSelectPodcast(e.id)} key={e.id}>
                                                        <img src={e.image ? e.image : null} className="lightmodeCardImage" />
                                                        <div>
                                                            <p style={{ fontWeight: "bold", marginLeft: 5, marginTop: 5 }}>{e.name}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }).concat(
                                                Array.from({ length: (3 - (suggestedData.length % 3)) % 3 }).map((_, index) => (
                                                    <div className="lightmodeCardPodcast emptyCard" key={`empty-${index}`} />
                                                ))
                                            )
                                            : null
                                    }


                                </div>
                            }
                            {tagOption === "trending" &&
                                <div className="scrollingItemsPodcast" style={{ gap: 15 }}>
                                    {trendingData.length > 0 ?
                                        trendingData.map((e) => {
                                            return <div className="lightmodeCardPodcast" onClick={() => handleSelectPodcast(e.id)}>
                                                <img src={e.image ? e.image : null} className="lightmodeCardImage" />
                                                <div>
                                                    <p style={{ fontWeight: "bold", marginLeft: 5, marginTop: 5 }}>{e.name}</p>
                                                </div>
                                            </div>
                                        }).concat(
                                            Array.from({ length: (3 - (trendingData.length % 3)) % 3 }).map((_, index) => (
                                                <div className="lightmodeCardPodcast emptyCard" key={`empty-${index}`} />
                                            ))
                                        )
                                        : null}

                                </div>
                            }
                            {tagOption === "saved" && null}

                        </Row>
                    </Col>
                </Row>



            </div>
        </>
    );
}

export default PodcastPlayerLight;
