import { useState, useEffect } from "react"
import { Row, Col, Button, Input, FormGroup, Label, Collapse } from "reactstrap"
import CollapseForm from "../AdWizardComps/CollapseForm"
import ReactDatetime from "react-datetime";
import Select from "react-select";

const WizardAdTarget = (props) => {

    const { advertTargeting, categories, baseObject, setBaseObject, requiredMissing, invalidDateRange, advertCost, setAdvertCost } = props

    const [isNetwork, setIsNetwork] = useState(true)
    const [isDownload, setIsDownload] = useState(false)
    const [isBadTargeting, setIsBadTargeting] = useState(true)
    const [categoryTargetType, setCategoryTargetType] = useState("all")


    //Selects
    const [multipleSelectCategory, setMultipleSelectCategory] = useState(null);
    const handleSelectCategory = (value) => {

        if (value === null) {
            setMultipleSelectCategory([])
            props.setBaseObject({ ...props.baseObject, categories: [] })
            return
        }
        setMultipleSelectCategory(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))

        props.setBaseObject({ ...props.baseObject, categories: reformatObject })
    }

    const [multipleSelectLocation, setMultipleSelectLocation] = useState(null);
    const handleSelectLocation = (value) => {

        if (value === null) {
            setMultipleSelectLocation([])
            props.setBaseObject({ ...props.baseObject, geoLocations: [] })
            return
        }
        setMultipleSelectLocation(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))


        props.setBaseObject({ ...props.baseObject, geoLocations: reformatObject })
    }


    const [multipleSelectAudienceAge, setMultipleSelectAudienceAge] = useState(null);
    const handleSelectAudienceAge = (value) => {

        if (value === null) {
            setMultipleSelectAudienceAge([])
            props.setBaseObject({ ...props.baseObject, age: [] })
            return
        }
        setMultipleSelectAudienceAge(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))


        props.setBaseObject({ ...props.baseObject, age: reformatObject })
    }

    const [multipleSelectAudienceInterest, setMultipleSelectAudienceInterest] = useState(null);
    const handleSelectAudienceInterest = (value) => {

        if (value === null) {
            setMultipleSelectAudienceInterest([])
            props.setBaseObject({ ...props.baseObject, interests: [] })
            return
        }
        setMultipleSelectAudienceInterest(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))


        props.setBaseObject({ ...props.baseObject, interests: reformatObject })
    }

    const [multipleSelectTime, setMultipleSelectTime] = useState(null);
    const handleSelectTime = (value) => {

        if (value === null) {
            setMultipleSelectTime([])
            props.setBaseObject({ ...props.baseObject, time: [] })
            return
        }
        setMultipleSelectTime(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))


        props.setBaseObject({ ...props.baseObject, time: reformatObject })
    }

    const [multipleSelectInventory, setMultipleSelectInventory] = useState(null);
    const handleSelectInventory = (value) => {

        if (value === null) {
            setMultipleSelectInventory([])
            props.setBaseObject({ ...props.baseObject, adPositions: [] })
            return
        }
        setMultipleSelectInventory(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))

        props.setBaseObject({ ...props.baseObject, adPositions: reformatObject })
    }

    const [selectedTab, setSelectedTab] = useState("network")
    const handleTabChange = (tab) => {
        if (tab === "network") {
            setIsNetwork(true)
            setIsDownload(false)
        } else if (tab === "download") {
            setIsNetwork(false)
            setIsDownload(true)
        } else {
            setIsNetwork(true)
            setIsDownload(true)
        }
        setSelectedTab(tab)
    }

    const locationTypes = [{ value: 1, label: "Region" }, { value: 2, label: "Country" }]
    const audienceTypes = [{ value: 1, label: "Age" }, { value: 2, label: "Interests" }]
    const timeTypes = [{ value: 1, label: "Time" }, { value: 2, label: "Day" }]
    const positionTypes = [{ value: 1, label: "Ad Position" }]
    const filterTypes = [{ value: 1, label: "Is any of" }, { value: 2, label: "Is not" }]

    const [isCampaignOpen, setIsCampaignOpen] = useState(true)

    //useEffect(() => {
    //    if (categoryTargetType === "all") {
    //        setMultipleSelectCategory([])
    //        props.setBaseObject({ ...props.baseObject, categories: [] })
    //    }
    //}, [categoryTargetType])

    useEffect(() => {
        if (selectedTab == "network") {
            setAdvertCost({ ...advertCost, isDownload: false, isNetwork: true })
        } else if (selectedTab === "download") {
            setAdvertCost({ ...advertCost, isDownload: true, isNetwork: false })
        } else {
            setAdvertCost({ ...advertCost, isDownload: true, isNetwork: true })
        }
    }, [selectedTab])

    const returnMappedArray = (arr) => {
        const newArr = arr.map((a) => {
            return ({
                value: a.id,
                label: a.name
            })
        })
        return newArr
    }

    const populateCategory = () => {
        const mapped = returnMappedArray(baseObject.categories)
        setMultipleSelectCategory(mapped)
    }

    const populateTargets = () => {
        if (baseObject.geoLocations && baseObject.geoLocations.length > 0) {
            const savedLocations = returnMappedArray(baseObject.geoLocations)
            setMultipleSelectLocation(savedLocations)

        }

        if (baseObject.age && baseObject.age.length > 0) {
            const savedAgess = returnMappedArray(baseObject.age)
            setMultipleSelectAudienceAge(savedAgess)

        }

        if (baseObject.interests && baseObject.interests.length > 0) {
            const savedInterests = returnMappedArray(baseObject.interests)
            setMultipleSelectAudienceInterest(savedInterests)

        }

        if (baseObject.time && baseObject.time.length > 0) {
            const savedTimes = returnMappedArray(baseObject.time)
            setMultipleSelectTime(savedTimes)
        }

        if (baseObject.adPositions && baseObject.adPositions.length > 0) {
            const savedAdPositions = returnMappedArray(baseObject.adPositions)
            setMultipleSelectInventory(savedAdPositions)
        }
    }

    useEffect(() => {
        if (baseObject.categories && baseObject.categories.length > 0) {
            populateCategory()
        }

        populateTargets()
    }, [baseObject])

    return (
        <div className=" pb-4">

            <Col>

                <Row className="justify-content-between mt-4">
                    <Col style={{ border: "1px solid rgba(225, 225, 225, 0.3)", borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column" }} className={`${isNetwork && !isDownload && "fadedKlaxonRed"}`} sm="" onClick={() => handleTabChange("network")}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Klaxon Ad Network</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Serve in Klaxon generated Podcasts.</p>
                    </Col>
                    <Col style={{ border: "1px solid rgba(225, 225, 225, 0.3)", borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column" }} className={`${!isNetwork && isDownload && "fadedKlaxonRed"} `} sm="" onClick={() => handleTabChange("download")}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Download</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Upload to other networks.</p>
                    </Col>
                    <Col style={{ border: "1px solid rgba(225, 225, 225, 0.3)", borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column" }} className={`${isNetwork && isDownload && "fadedKlaxonRed"}`} sm="" onClick={() => handleTabChange("networkanddownload")}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Klaxon Ad Network & Download</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Serve in Klaxon generated Podcasts & upload to other networks.</p>
                    </Col>
                </Row>

                {isDownload && !isNetwork &&
                    <Row className="mt-3">
                        <Col>
                            <CollapseForm title={"Campaign"} setOpen={setIsCampaignOpen} startOpen={isCampaignOpen}>
                                <div style={{ backgroundColor: "rgba(225, 225, 225, 0.4)", padding: 10 }}>
                                    <Row md={12}>
                                        <Col sm={6}>
                                            <FormGroup className="">
                                                <Label className="whiteLabel">Name {requiredMissing && requiredMissing.includes("name") && <span style={{ color: "red", fontWeight: "bold" }}>required</span>}</Label>

                                                <Input className="inputStyle placeholderWhite" placeholder="Enter Campaign Name" value={baseObject.name} onChange={(e) => setBaseObject({ ...baseObject, name: e.target.value })} />

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </CollapseForm>
                            <div style={{ width: "100%", backgroundColor: "#F33B30", border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", padding: 10, margin: "auto", display: "flex", flexDirection: "column", gap: 10 }}>
                                <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                                    <i className="fa fa-info-circle" />
                                    Download will be available after payment
                                </div>
                                <div>Advert .mp3 file will be available to download after successful payment and from the Ads page.</div>
                            </div>
                        </Col>
                    </Row>
                }

                {isNetwork &&
                    <Row className="mt-3">
                        <Col>
                            <CollapseForm title={"Campaign"} setOpen={setIsCampaignOpen} startOpen={isCampaignOpen}>
                                <div style={{ backgroundColor: "rgba(225, 225, 225, 0.4)", padding: 10 }}>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup className="">
                                                <Label className="whiteLabel">Name {requiredMissing && requiredMissing.includes("name") && <span style={{ color: "red", fontWeight: "bold" }}>required</span>}</Label>

                                                <Input className="inputStyle placeholderWhite" placeholder="Enter Campaign Name" value={baseObject.name} onChange={(e) => setBaseObject({ ...baseObject, name: e.target.value })} />

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label className="whiteLabel">Budget {requiredMissing && requiredMissing.includes("budget") && <span style={{ color: "red", fontWeight: "bold" }}>required</span>}</Label>

                                                <Input className="inputStyle placeholderWhite" type="number" placeholder="Enter Campaign Budget" value={baseObject.budget} onChange={(e) => setBaseObject({ ...baseObject, budget: e.target.value })} />

                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            {isCampaignOpen &&
                                                <Row>
                                                    <Col>
                                                        <FormGroup className="">
                                                            <Label className="whiteLabel">From {requiredMissing && requiredMissing.includes("runningFrom") && <span style={{ color: "red", fontWeight: "bold" }}>required</span>}</Label>

                                                            <ReactDatetime
                                                                inputProps={{
                                                                    className: "form-control",
                                                                    initialValue: ""
                                                                }}
                                                                value={new Date(baseObject.runningFrom)}
                                                                dateFormat="DD/MM/YYYY"
                                                                onChange={(date) => setBaseObject({ ...baseObject, runningFrom: date.toISOString() })}
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup className="">
                                                            <Label className="whiteLabel">To {requiredMissing && requiredMissing.includes("runningTo") && <span style={{ color: "red", fontWeight: "bold" }}>required</span>}</Label>

                                                            <ReactDatetime
                                                                inputProps={{
                                                                    className: "form-control",
                                                                    initialValue: ""
                                                                }}
                                                                value={new Date(baseObject.runningTo)}
                                                                dateFormat="DD/MM/YYYY"
                                                                onChange={(date) => setBaseObject({ ...baseObject, runningTo: date.toISOString() })}
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>

                                    </Row>
                                    {invalidDateRange &&
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Label className="whiteLabel" style={{ fontWeight: "bold" }}>The time your advert starts cannot be after it ends</Label>
                                        </div>
                                    }
                                </div>
                            </CollapseForm>

                            <CollapseForm title={"Category Targeting"}>
                                <div style={{ backgroundColor: "rgba(225, 225, 225, 0.4)", padding: 10 }}>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label className="whiteLabel">Categories</Label>

                                                <Row>

                                                    <Col>
                                                        <FormGroup check>
                                                            <Label check className="whiteLabel">
                                                                <Input type="checkbox" checked={baseObject.isAllCat} onClick={() => setBaseObject({ ...baseObject, isAllCat: true })} />
                                                                <span className="form-check-sign" />
                                                                All categories
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col>
                                                        <FormGroup check>
                                                            <Label check className="whiteLabel">
                                                                <Input type="checkbox" checked={!baseObject.isAllCat} onClick={() => setBaseObject({ ...baseObject, isAllCat: false })} />
                                                                <span className="form-check-sign" />
                                                                Set own
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                {!baseObject.isAllCat &&
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select klaxon-multi"
                                                            classNamePrefix="react-select"
                                                            placeholder="Categories"
                                                            name="multipleSelect"
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            value={multipleSelectCategory}
                                                            onChange={(value) => handleSelectCategory(value)}
                                                            options={categories}
                                                        />
                                                    </FormGroup>
                                                }

                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </div>
                            </CollapseForm>

                            <CollapseForm title={"Advanced Targeting - Optional"}>
                                <div style={{ backgroundColor: "rgba(225, 225, 225, 0.4)", padding: 10 }}>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label className="whiteLabel">
                                                        Location
                                                    </Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <Row className="mb-2">
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 2, label: "Country" }]}
                                                                    value={{ value: 2, label: "Country" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 1, label: "Is any of" }]}
                                                                    value={{ value: 1, label: "Is any of" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select klaxon-multi"
                                                                        classNamePrefix="react-select"
                                                                        placeholder="Locations"
                                                                        name="multipleSelect"
                                                                        closeMenuOnSelect={false}
                                                                        isMulti
                                                                        value={multipleSelectLocation}
                                                                        onChange={(value) => handleSelectLocation(value)}
                                                                        options={advertTargeting.filter(targ => targ.typeId === 1)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </Col>

                                            </Row>

                                        </Col>
                                    </Row>

                                    {/* <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label className="whiteLabel">Audience</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <Row className="mb-2">
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ id: 1, label: "Age" }]}
                                                                    value={{ id: 1, label: "Age" }}
                                                                    disabled
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 1, label: "Is any of" }]}
                                                                    value={{ value: 1, label: "Is any of" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select klaxon-multi"
                                                                        classNamePrefix="react-select"
                                                                        placeholder="Ages"
                                                                        name="multipleSelect"
                                                                        closeMenuOnSelect={false}
                                                                        isMulti
                                                                        value={multipleSelectAudienceAge}
                                                                        onChange={(value) => handleSelectAudienceAge(value)}
                                                                        options={advertTargeting.filter(targ => targ.typeId === 2)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <div>
                                                        <Row className="mb-2">
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ id: 2, label: "Interests" }]}
                                                                    value={{ id: 2, label: "Interests" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 1, label: "Is any of" }]}
                                                                    value={{ value: 1, label: "Is any of" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select klaxon-multi"
                                                                        classNamePrefix="react-select"
                                                                        placeholder="Interests"
                                                                        name="multipleSelect"
                                                                        closeMenuOnSelect={false}
                                                                        isMulti
                                                                        value={multipleSelectAudienceInterest}
                                                                        onChange={(value) => handleSelectAudienceInterest(value)}
                                                                        options={advertTargeting.filter(targ => targ.typeId === 3)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row> */}

                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label className="whiteLabel">Day & Time</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <Row className="mb-2">
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 1, label: "Time" }]}
                                                                    value={{ value: 1, label: "Time" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 1, label: "Is any of" }]}
                                                                    value={{ value: 1, label: "Is any of" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select klaxon-multi"
                                                                        classNamePrefix="react-select"
                                                                        placeholder="Time"
                                                                        name="multipleSelect"
                                                                        closeMenuOnSelect={false}
                                                                        isMulti
                                                                        value={multipleSelectTime}
                                                                        onChange={(value) => handleSelectTime(value)}
                                                                        options={advertTargeting.filter(targ => targ.typeId === 4)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>

                                    {/* <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label className="whiteLabel">Inventory</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <Row className="mb-2">
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 1, label: "Ad Position" }]}
                                                                    value={{ value: 1, label: "Ad Position" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 1, label: "Is any of" }]}
                                                                    value={{ value: 1, label: "Is any of" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select klaxon-multi"
                                                                        classNamePrefix="react-select"
                                                                        placeholder="Inventory"
                                                                        name="multipleSelect"
                                                                        closeMenuOnSelect={false}
                                                                        isMulti
                                                                        value={multipleSelectInventory}
                                                                        onChange={(value) => handleSelectInventory(value)}
                                                                        options={advertTargeting.filter(targ => targ.typeId === 5)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row> */}

                                </div>
                            </CollapseForm>
                        </Col>
                        {/*<Col sm="4">*/}
                        {/*    <div style={{ backgroundColor: "rgba(225, 225, 225, 0.3)", padding: "5px 10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>*/}
                        {/*        <p style={{ margin: 0 }}>Reach Forecast</p>*/}
                        {/*    </div>*/}
                        {/*    <div style={{ backgroundColor: "rgba(225, 225, 225, 0.4)", padding: "5px 10px 5px", display: "flex", flexDirection: "column" }}>*/}
                        {/*        <div className="mb-2">*/}
                        {/*            <h3 style={{ margin: 0 }}>Target Impressions</h3>*/}
                        {/*            <p style={{ margin: 0 }}>Target number of Listens</p>*/}
                        {/*            <h3 style={{ margin: 0 }}>1,000,000</h3>*/}
                        {/*        </div>*/}
                        {/*        <div className="mb-2">*/}
                        {/*            <h3 style={{ margin: 0 }}>Estimated Available Impressions</h3>*/}
                        {/*            <p style={{ margin: 0 }}>Number of estimated podcast listens within the specified timeframe that match your target audience.</p>*/}
                        {/*            <h3 style={{ margin: 0 }}>250,000</h3>*/}
                        {/*        </div>*/}
                        {/*        {isBadTargeting &&*/}
                        {/*            <div style={{ width: "90%", margin: "auto", backgroundColor: "rgba(225, 225, 225, 0.3)", padding: 10, borderRadius: 7, marginBottom: 5 }}>*/}
                        {/*                <h4 style={{ margin: 0 }}>Target likely to be missed</h4>*/}
                        {/*                <p style={{ margin: 0 }}>Given the budget, targeting criteria and campaign length, there is a chance the campaign will not reach the desired target impressions.</p>*/}
                        {/*                <p style={{ margin: 0, fontWeight: "bold" }}>Steps to resolve:</p>*/}
                        {/*                <ul>*/}
                        {/*                    <li>Increase the budget</li>*/}
                        {/*                    <li>Add more categories</li>*/}
                        {/*                    <li>Increase the campaign length</li>*/}
                        {/*                    <li>Reduce Advance Targeting criteria</li>*/}
                        {/*                </ul>*/}
                        {/*                <div className="text-center">*/}
                        {/*                    <button type="submit" style={{ minWidth: 70, backgroundColor: "#F33B30", minHeight: 35, maxHeight: 35, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", fontWeight: "bold" }}>RECALCULATE</button>*/}
                        {/*                </div>*/}

                        {/*            </div>*/}
                        {/*        }*/}
                        {/*    </div>*/}

                        {/*</Col>*/}
                    </Row>
                }

            </Col>
        </div>
    )
}

export default WizardAdTarget 