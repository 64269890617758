/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

import {
    EmailShareButton,
    FacebookShareButton,
    GabShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    GabIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon,
    XIcon,
} from "react-share";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";
import ReactBSAlert from "react-bootstrap-sweetalert"
import axios from "axios";
import { useNavigate } from "react-router-dom";

import ReactPlayer from "react-player";
import defaultImage from "../../assets/img/header.jpg"
import MyModal from "components/Shared/MyModal";
import CreatePodcast from "./components/CreatePodcast";
import TutorialModal from "../../components/Shared/TutorialModal";

import { Spinner } from "reactstrap"



import "../../assets/css/custom-css.css"

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input,
    Tooltip

} from "reactstrap";
import tutorialText from "../../config/tutorialText";
import MiniPlayer from "./components/MiniPlayer";
function Podcasts() {
    const modalWidth = window.innerWidth < 1050 ? "90%" : "1000px"
    const navigation = useNavigate()
    const [podcastData, setPodcastData] = useState([])
    const [expandedPodcasts, setExpandedPodcasts] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [podcastSelected, setPodcastSelected] = useState(false)
    const [selectedPodcastId, setSelectedPodcastId] = useState(null)
    const [selectedPodcastEpisode, setSelectedPodcastEpisode] = useState(null)
    const [modalOpen, setModalOpen] = useState(false);
    const [podcastToEdit, setPodcastToEdit] = useState({})
    const [alert, setAlert] = useState(null)
    const [showTutorial, setShowTutorial] = useState(true);
    const [podcastToShare, setPodcastToShare] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [revalidate, setRevalidate] = useState(false)
    const [podcastDuration, setPodcastDuration] = useState(0)

    const [isB2BLogin, setIsB2BLogin] = useState(localStorage.getItem("accountType") == 0 ? false : true)
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)

    const userId = getJWTToken().decoded !== null ? getJWTToken().decoded.nameid : 0

    const getScript = async (podcastId) => {
        try {
            const resp = await apiCall(
                apiConfig.PODCAST_EPISODE_SECTIONS.GET_SCRIPT + `?podcastEpisodeId=${podcastId}`,
                "get"
            );
            if (resp.status === 200) {
                handleCopyToClipboard(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const handleCopyToClipboard = (scriptString) => {
        if (!navigator.clipboard) {
            return
        }

        navigator.clipboard.writeText(scriptString)
            .then(() => {
                setIsTooltipOpen(true)
                setTimeout(() => {
                    setIsTooltipOpen(false)
                }, 1500)
            })
            .catch(err => {
                console.error("Something went wrong", err)
            })
    }

    
    const getPodcasts = async () => {
        setIsLoading(true)
        let b2bLogin = localStorage.getItem("accountType") == 0 ? false : true
        let url = `?userId=${userId}`
        
        let clientId = localStorage.getItem("cId")
        
        if (clientId === undefined || !b2bLogin) {
            clientId = null
        } else {
            const storageCID = localStorage.getItem("cId")
            url = url + `&clientId=${storageCID}`
        }
        if (clientId !== null) {
            clientId = Number(clientId)
        }

        try {
            const resp = await apiCall(
                apiConfig.PODCAST.GET_ALL + url,
                "get"
            );
            if (resp.status === 200) {
                setIsLoading(false)
                //set as podcast data to maintain original
                setPodcastData(resp.data.length > 0 ? resp.data : [])
                //set as search results, this is what is actually mapped
                setSearchResults(resp.data.length > 0 ? resp.data : [])
            }

        } catch (error) {
            console.error(error.message);
        }
    };


    //Keep track of which tr is expanded
    const handlePodcastExpand = (index, event) => {


        if (!event.target.closest('.dropdownIgnore')) {
            const copyExpansion = [...expandedPodcasts];

            if (copyExpansion[index] === true) {
                copyExpansion[index] = false
                setExpandedPodcasts(copyExpansion)
            } else {
                copyExpansion[index] = true;

                const disableOthers = copyExpansion.map((_, i) => i === index);
                setExpandedPodcasts(disableOthers);
            }

            setPodcastSelected(false)
            setPodcastToShare(false)
            setSelectedPodcastId(null)
        }

    }

    const handleDownloadEpisode = async (id) => {
        try {
            const resp = await apiCall(
                apiConfig.PODCAST_EPISODE.DOWNLOAD_BUSINESS_EPISODE + `?id=${id}`,
                "get"
            )

            const B2BDownloadLink = resp.data
            try {
                if (/^[A-Za-z0-9+/]+={0,2}$/.test(B2BDownloadLink)) {

                    const decodedData = atob(B2BDownloadLink);
                    const dataArray = new Uint8Array(decodedData.length);

                    for (let i = 0; i < decodedData.length; i++) {
                        dataArray[i] = decodedData.charCodeAt(i);
                    }

                    const blob = new Blob([dataArray], { type: "audio/mpeg" });
                    const link = document.createElement("a");

                    link.href = window.URL.createObjectURL(blob);
                    link.download = "Podcast";
                    link.click();
                } else {
                    console.error("Invalid base64 string");
                }

            } catch (err) {
                console.error(err)
            }


        } catch (error) {
            console.error(error.message)
        }
    }
    // Filter against name and description and remove duplicates
    const handleSearchBar = (query) => {
        const originalSearch = podcastData
        const searchName = originalSearch.filter((e) => e.name.toLowerCase().includes(query.toLowerCase()))
        const searchDescription = originalSearch.filter((e) => e.description.toLowerCase().includes(query.toLowerCase()))
        setSearchResults(Array.from(new Set([...searchName, ...searchDescription])))
    }

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const getPodcastEpisode = async () => {
        if (selectedPodcastId.isClient) {

            let url = `${apiConfig.baseUrl}${apiConfig.PODCAST_EPISODE.GET_BUSINESS_EPISODE}?id=${selectedPodcastId.id}`;
            await axios
                .get(url)
                .then((res) => {
                    if (res.status === 200) {
                        setSelectedPodcastEpisode([res.data])
                        setPodcastSelected(true)
                    }

                })
                .catch((err) => {
                    //setError(err.response.data);
                });

        } else {
            let url = `${apiConfig.baseUrl}${apiConfig.PODCAST_EPISODE.GET_URLS}?id=${selectedPodcastId.id}`;
            await axios
                .get(url)
                .then((res) => {
                    if (res.status === 200) {
                        setSelectedPodcastEpisode(res.data)
                        setPodcastSelected(true)
                    }

                })
                .catch((err) => {
                    //setError(err.response.data);
                });
        }
    }
    const handleSelectPodcast = (podcastId) => {
        navigation(`/player?podcastId=${podcastId}`)
    }

    const handleReviewPodcast = (podcastId) => {
        navigation(`/admin/review?episodeId=${podcastId}`)
    }

    const closePlayer = () => {
        setPodcastSelected(false)
        setSelectedPodcastId(null)
    }

    const getPodcastSingle = async (id) => {
        try {
            const resp = await apiCall(
                apiConfig.PODCAST.GET_SINGLE + `?id=${id}`,
                "get"
            );
            if (resp.status === 200) {
                setPodcastToEdit(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const handlePodcastEditClicked = async (id) => {
        await getPodcastSingle(id)
        toggleModal()
    }

    const handlePodcastDeleteClicked = (id) => {
        setPodcastToEdit(podcastData.find((podcast) => podcast.id === id))
        const fastData = podcastData.find((podcast) => podcast.id === id)
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handlePodcastDelete(id)}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, delete!"
                cancelBtnText="Cancel"
                showCancel
                btnSize=""
            >
                You are about to delete {fastData.name}.
            </ReactBSAlert>
        )
    }

    const handleValidatePodcast = async () => {
        try {
            const resp = await apiCall(
                apiConfig.PODCAST.VALIDATE_CONTENT + `?userId=${getJWTToken().decoded.nameid}`,
                "post", podcastToEdit
            );
            if (resp.status === 204) {
                console.error("error no content")
                return false
            } else if (resp.status === 200) {
                return true
            }

        } catch (error) {
            console.error(error)
            return false
        }
    }

    const handleSavePodcast = async () => {
        if (revalidate) {
            const isValid = await handleValidatePodcast()

            if (!isValid) return
        }

        if (podcastToEdit.clientId == null && (podcastToEdit.categories === "" || podcastToEdit.categories == "0")) return

        try {
            const resp = await apiCall(
                apiConfig.PODCAST.UPDATE + `?id=${podcastToEdit.id}`,
                "put", podcastToEdit
            );
            if (resp.status === 200) {
                getPodcasts()
                toggleModal()
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const handlePodcastDelete = async (id) => {
        try {
            const resp = await apiCall(
                apiConfig.PODCAST.DELETE + `?id=${id}&userId=${getJWTToken().decoded.nameid}`,
                "delete"
            );
            if (resp.status === 200) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Podcast Deleted",
                    showConfirmButton: false,
                    timer: 2500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                })
                hideAlert()
                getPodcasts()
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Something went wrong",
                    showConfirmButton: false,
                    timer: 2500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                })
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const hideAlert = () => {
        setAlert(null)
    }

    const convertTimeSpan = (timeString) => {
        const [timePart, milliseconds] = timeString.split('.');
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        let totalSeconds = hours * 3600 + minutes * 60 + seconds;

        if (milliseconds) {
            totalSeconds += parseFloat(`0.${milliseconds}`);
        }

        return totalSeconds;
    };


    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const formattedHours = hours > 0 ? String(hours).padStart(2, '0') : '';
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        const formattedTime = [formattedHours, formattedMinutes, formattedSeconds]
            .filter((value) => value !== '')
            .join(':');

        return formattedTime;
    };

    const getDuration = () => {
        let durationObject = { durationSeconds: 0, durationTimeSpan: "00:00:00" };
        selectedPodcastEpisode.forEach((e) => {
            if (e.canSeek === true) {
                durationObject.durationSeconds += convertTimeSpan(e.duration)
            }
        })
        durationObject.durationTimeSpan = formatDuration(durationObject.durationSeconds)
        setPodcastDuration(durationObject)
    }

    useEffect(() => {
        if (selectedPodcastEpisode !== null) {
            getDuration()
        }
    }, [selectedPodcastEpisode])

    const [categories, setCategories] = useState([])
    const getCategories = async () => {
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.GET_CATEGORIES,
                "get"
            );
            if (resp.status === 200) {
                setCategories(
                    resp.data.map((cat) => {
                        return ({
                            label: cat.name, value: cat.id
                        })
                    })
                )
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        getPodcasts();
        getCategories()
    }, []);

    useEffect(() => {
        if (selectedPodcastId != null) {
            getPodcastEpisode()
        }
    }, [selectedPodcastId])


    useEffect(() => {
        const handleClientChanged = () => getPodcasts();

        // Add the event listener when the component mounts
        window.addEventListener("clientChanged", handleClientChanged);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("clientChanged", handleClientChanged);
        };

    }, [])

    const handleChangeAccountType = async () => {
        await checkB2BStatus()
        getPodcasts()
    }

    useEffect(() => {
        const handleTypeChanged = () => handleChangeAccountType()

        window.addEventListener("accountTypeChanged", handleTypeChanged)
        return () => {
            window.removeEventListener("accountTypeChanged", handleTypeChanged)
        }
    }, [])

    const checkB2BStatus = () => {
        let b2bLogin = localStorage.getItem("accountType") == 0 ? false : true
        setIsB2BLogin(b2bLogin)
    }

    useEffect(() => {
        checkB2BStatus()
    }, [])

    return (
        <>
            {alert}
            <TutorialModal
                showTutorial={showTutorial}
                pageName='podcasts'
                title="Podcasts"
                text={tutorialText.PODCASTS}
                setShowTutorial={setShowTutorial} />
            <MyModal isOpen={modalOpen} toggle={toggleModal} title="Edit Podcast" width={modalWidth} buttonAction={handleSavePodcast}>
                <CreatePodcast baseObject={podcastToEdit} setBaseObject={setPodcastToEdit} setRevalidate={setRevalidate} categories={categories} />
            </MyModal>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card className="formStyle text-white">
                            <CardHeader>
                                <Col>
                                    <Row className="justify-content-between">
                                        <CardTitle tag="h4">Podcasts</CardTitle>
                                    </Row>
                                    <Row sm="1" md="2" lg="4"><Input className="placeholderWhite inputStyle" type="text" placeholder="Search" onChange={(e) => handleSearchBar(e.target.value)} /></Row>
                                </Col>
                            </CardHeader>
                            <CardBody>
                                <div className="tableOverflow">
                                    <Table className="table">
                                        <thead className="text-primary">
                                            <tr style={{ color: "grey" }}>
                                                <th>Name</th>
                                                <th></th>
                                                <th>Description</th>
                                                <th>Frequency</th>
                                                <th>Episodes</th>
                                                <th>Listens</th>
                                                <th>Next Release Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isLoading ? podcastData?.length > 0 ? (
                                                searchResults.map((podcast, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index} onClick={(event) => handlePodcastExpand(index, event)} style={{ cursor: "pointer" }}>
                                                                <td className="text-center"><img src={podcast.image ? podcast.image : defaultImage} style={{ width: "50px", height: "50px" }} /></td>
                                                                <td>{podcast.name}</td>
                                                                <td>{podcast.description}</td>
                                                                <td>{podcast.frequencyName}</td>
                                                                <td>{podcast.currentEpisode} / {podcast.maxEpisodes === 0 ? "1" : podcast.maxEpisodes}</td>
                                                                <td>{podcast.listens}</td>
                                                                <td>{podcast.currentEpisode !== podcast.maxEpisodes ? new Date(podcast.nextEpisode).toLocaleString('en-GB') : "Podcast completed"}</td>
                                                                <td className="text-right">
                                                                    <Button
                                                                        className="btn-icon btn-neutral dropdownIgnore editButton"
                                                                        style={{ color: "" }}
                                                                        id="tooltip159182735"
                                                                        size="sm"
                                                                        type="button"
                                                                        onClick={() => handlePodcastEditClicked(podcast.id)}
                                                                    >
                                                                        <i className="fa fa-edit" style={{ fontSize: 18 }} />
                                                                    </Button>
                                                                    <UncontrolledTooltip
                                                                        delay={0}
                                                                        target="tooltip159182735"
                                                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                                                    >
                                                                        Edit
                                                                    </UncontrolledTooltip>
                                                                    <Button
                                                                        className="btn-icon btn-neutral dropdownIgnore"
                                                                        style={{ color: "#f33b30" }}
                                                                        id="tooltip808966390"
                                                                        size="sm"
                                                                        type="button"
                                                                        onClick={() => handlePodcastDeleteClicked(podcast.id)}
                                                                    >
                                                                        <i className="fa fa-times" style={{ fontSize: 18 }} />
                                                                    </Button>
                                                                    <UncontrolledTooltip
                                                                        delay={0}
                                                                        target="tooltip808966390"
                                                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                                                    >
                                                                        Delete
                                                                    </UncontrolledTooltip>
                                                                </td>
                                                            </tr>
                                                            {expandedPodcasts[index] && (
                                                                <>

                                                                    {podcast.episodes.map((extraInfo, index) => {
                                                                        return (
                                                                            <>
                                                                                <tr className="expandable-row">
                                                                                    <td colSpan="9" className="expandable-test">
                                                                                        <div className="expandable-content mb-1">
                                                                                            <Row>
                                                                                                <Col className="align-items-center d-flex justify-content-center">
                                                                                                    <p><strong>Episode {extraInfo.episodeNumber}</strong> - <span onClick={extraInfo.statusName === "Released" ? () => handleSelectPodcast(extraInfo.id) : null} style={{
                                                                                                        cursor: extraInfo.statusName === "Released" && "pointer",
                                                                                                        color: extraInfo.statusName === "Released" && "#F33B30",
                                                                                                        borderBottom: extraInfo.statusName === "Released" && "0.5px solid #F33B30"
                                                                                                    }}>{extraInfo.name}</span></p>
                                                                                                </Col>
                                                                                                <Col className="align-items-center d-flex justify-content-center">
                                                                                                    <p className="mr-3" style={{ color: extraInfo.statusName === "Released" ? "#8cff8c" : "#ffd699" }}><strong>{extraInfo.statusName}</strong></p>

                                                                                                </Col>
                                                                                                <Col className="align-items-center d-flex justify-content-center"> <p className="mr-3"><strong>Release Date</strong>: {new Date(extraInfo.releaseDate).toLocaleString('en-GB')}</p></Col>
                                                                                                <Col className="align-items-center d-flex justify-content-center"> <p className="mr-3"><strong>Duration</strong>: {extraInfo.duration.split(".")[0]}</p></Col>
                                                                                                <Col className="align-items-center d-flex justify-content-center">
                                                                                                    <p className="mr-4"><strong>Listens</strong>: {extraInfo.listens}</p>
                                                                                                </Col>
                                                                                            </Row>

                                                                                            {podcastSelected === true ? (
                                                                                                <div style={{ display: "flex", justifyContent: "center" }}>

                                                                                                    <MiniPlayer podcast={selectedPodcastEpisode} podcastDuration={podcastDuration} />
                                                                                                    <p onClick={closePlayer} style={{ marginLeft: 10, marginBottom: 0, color: "white", fontWeight: 600, borderRadius: 50, height: "55px", width: "55px", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}><i className="nc-icon nc-simple-remove" /></p>
                                                                                                </div>) : podcastToShare === true ? (
                                                                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                                                                        <FacebookShareButton url={`http://klaxonai.com/player?podcastId=${extraInfo.id}`}>
                                                                                                            <FacebookIcon round size={40} />
                                                                                                        </FacebookShareButton>
                                                                                                        <TwitterShareButton url={`http://klaxonai.com/player?podcastId=${extraInfo.id}`} style={{ marginLeft: 5 }}>
                                                                                                            <XIcon round size={40} />
                                                                                                        </TwitterShareButton>
                                                                                                        <LinkedinShareButton url={`http://klaxonai.com/player?podcastId=${extraInfo.id}`} style={{ marginLeft: 5 }}>
                                                                                                            <LinkedinIcon round size={40} />
                                                                                                        </LinkedinShareButton>
                                                                                                        <RedditShareButton url={`http://klaxonai.com/player?podcastId=${extraInfo.id}`} style={{ marginLeft: 5 }}>
                                                                                                            <RedditIcon round size={40} />
                                                                                                        </RedditShareButton>
                                                                                                        <WhatsappShareButton url={`http://klaxonai.com/player?podcastId=${extraInfo.id}`} style={{ marginLeft: 5 }}>
                                                                                                            <WhatsappIcon round size={40} />
                                                                                                        </WhatsappShareButton>
                                                                                                        <EmailShareButton url={`http://klaxonai.com/player?podcastId=${extraInfo.id}`} style={{ marginLeft: 5 }}>
                                                                                                            <EmailIcon round size={40} />
                                                                                                        </EmailShareButton>
                                                                                                        <p onClick={() => setPodcastToShare(false)} style={{ marginBottom: 0, color: "white", fontWeight: 600, borderRadius: 50, height: "55px", width: "55px", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}><i className="nc-icon nc-simple-remove" /></p>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                <Row>
                                                                                                    {extraInfo.statusName === "Released" ?
                                                                                                        <Row className="justify-content-around">
                                                                                                                    <Col sm="3"> <button className="buttonStyle buttonPrimary" onClick={() => setSelectedPodcastId({ id: extraInfo.id, isClient: extraInfo.isClient})}>Play</button></Col>
                                                                                                                    {isB2BLogin && <Col sm="3"> <button className="buttonStyle buttonPrimary" onClick={() => handleDownloadEpisode(extraInfo.id)}>Download</button></Col> }
                                                                                                                    <Col sm="3"> <button className="buttonStyle buttonPrimary" onClick={() => setPodcastToShare(true)}>Share</button></Col>
                                                                                                                    <Col sm="3"> <button className="buttonStyle buttonPrimary" id={`scriptButton${extraInfo.id}`} onClick={() => getScript(extraInfo.id)}>Script</button></Col>
                                                                                                                    <Tooltip
                                                                                                                        isOpen={isTooltipOpen}
                                                                                                                        target={`scriptButton${extraInfo.id}`}
                                                                                                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                                                                                                    >
                                                                                                                        Copied to clipboard!
                                                                                                                    </Tooltip>
                                                                                                        </Row> :
                                                                                                        (extraInfo.statusName !== "Awaiting Creation" ?
                                                                                                            <Row>
                                                                                                                <Col><button className="buttonStyle buttonPrimary" onClick={() => handleReviewPodcast(extraInfo.id)}>Review</button></Col>
                                                                                                            </Row> :
                                                                                                            null
                                                                                                        )
                                                                                                    }
                                                                                                </Row>
                                                                                            )}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })}


                                                                </>
                                                            )}
                                                        </>


                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="8">
                                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                                                            <p>No podcasts found</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : <tr>
                                                <td colSpan="8">
                                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                                                        <Spinner color="danger" />
                                                    </div>
                                                </td>
                                            </tr>}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Podcasts;