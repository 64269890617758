/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";

import {useNavigate } from "react-router-dom"

import defaultImage from "../../assets/img/header.jpg"
import MyModal from "components/Shared/MyModal";
import CreatePodcast from "./components/CreatePodcast";

import pricingImageOne from "assets/img/klaxonImages/c1.jpg"
// reactstrap components
import {
    Button,
    Container,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input

} from "reactstrap";
import FAQTabs from "../../config/FAQTabs";

function FAQ() {

    const navigation = useNavigate()
    const [openedCollapses, setOpenedCollapses] = React.useState([
        
    ]);
   

    const collapsesToggle = (collapse) => {
        if (openedCollapses.includes(collapse)) {
            setOpenedCollapses(openedCollapses.filter((item) => item !== collapse));
        } else {
            openedCollapses.push(collapse);
            setOpenedCollapses([...openedCollapses, collapse]);
        }
    };

    return (
        <>

            <div className="content" style={{ marginTop: 78 }}>
                <Container>
                    <Row>
                        <Col>
                            <h3 className="text-center" style={{ color: "white", marginTop: 20 }}><b>Frequently Asked Questions</b></h3>
                            <h3 className="text-white">General</h3>
                            <Card className="card-plain">
                                {FAQTabs.GeneralFAQ.map((e, index) => {
                                    return (
                                        <Card className="card-plain">
                                            <CardHeader role="tab" className="faqCard">
                                                <a
                                                    aria-expanded={openedCollapses.includes(`collapse${index}`)}
                                                    href="#pablo"
                                                    data-parent="#accordion"
                                                    data-toggle="collapse"
                                                    onClick={() => collapsesToggle(`collapse${index}`)}
                                                    style={{ color: "white" }}
                                                >
                                                    {e.question }
                                                    <i className="nc-icon nc-minimal-down" />
                                                </a>
                                            </CardHeader>
                                            <Collapse
                                                role="tabpanel"
                                                isOpen={openedCollapses.includes(`collapse${index}`)}
                                            >
                                                <CardBody>
                                                    <p style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: e.answer }}></p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    )
                                }) }
                            </Card>
                            <h3 className="text-white">For Advertisers / Business</h3>
                            <Card className="card-plain">
                                {FAQTabs.BusinessFAQ.map((e, index) => {
                                    return (
                                        <Card className="card-plain">
                                            <CardHeader role="tab" className="faqCard">
                                                <a
                                                    aria-expanded={openedCollapses.includes(`collapse${index+30}`)}
                                                    href="#pablo"
                                                    data-parent="#accordion"
                                                    data-toggle="collapse"
                                                    onClick={() => collapsesToggle(`collapse${index+30}`)}
                                                    style={{ color: "white" }}
                                                >
                                                    {e.question}
                                                    <i className="nc-icon nc-minimal-down" />
                                                </a>
                                            </CardHeader>
                                            <Collapse
                                                role="tabpanel"
                                                isOpen={openedCollapses.includes(`collapse${index+30}`)}
                                            >
                                                <CardBody>
                                                    <p style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: e.answer} }></p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    )
                                })}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default FAQ;
