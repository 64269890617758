/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

function PodcastEditImage(props) {
    //console.log("image props", props)
    const [fileState, setFileState] = React.useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
        props.avatar ? defaultAvatar : defaultImage
    );
    const fileInput = React.useRef();
    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setFileState(file);
            setImagePreviewUrl(reader.result);
            props.setData({ ...props.currentData, image: reader.result.substring(reader.result.indexOf(',') + 1) })
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // fileState is the file/image uploaded
        // in this function you can save the image (fileState) on form submit
        // you have to call it yourself
    };
    const handleClick = () => {
        fileInput.current.click();
    };
    const handleRemove = () => {
        fileInput.current.value = null;
        setFileState(null);
        setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    };

    useEffect(() => {
        if (props.currentData.image === "") {
            setImagePreviewUrl(defaultImage)
        }
        else if (props.currentData.image !== null && props.currentData.image !== undefined) {
            if (props.currentData?.image.startsWith("http")) {
                setImagePreviewUrl(props.currentData.image)
            } else {
                setImagePreviewUrl(`data:image/jpeg;base64,${props.currentData.image}`)
            }
        }

    }, [props.currentData])

    return (
        <div className="fileinput text-center">
            <input type="file" onChange={handleImageChange} ref={fileInput} />
            <div className={"thumbnail" + (props.avatar ? " img-circle" : "")}>
                <img src={imagePreviewUrl} alt="..." />
            </div>
            <div>
                {fileState === null ? (
                    <button className="buttonStyle buttonPrimary" onClick={() => handleClick()}>
                        {props.avatar ? "Add Photo" : "Select image"}
                    </button>
                ) : (
                    <span>
                            <button className="buttonStyle buttonPrimary" onClick={() => handleClick()}>
                            Change
                        </button>
                        {props.avatar ? <br /> : null}
                            <button className="buttonStyle buttonSecondary"
                            onClick={() => handleRemove()}
                        >
                            <i className="fa fa-times" />
                            Remove
                        </button>
                    </span>
                )}
            </div>
        </div>
    );
}

PodcastEditImage.propTypes = {
    avatar: PropTypes.bool,
};

export default PodcastEditImage;
