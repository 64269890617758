import React, { useState, useEffect, useContext, useRef } from 'react';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'
import WaveSurfer from 'wavesurfer.js';

const MusicWaveform = ({ fileURL, setFileURL, baseObject, setBaseObject }) => {
    const [playRegionOnly, setPlayRegionOnly] = useState(false);
    const wavesurferRef = useRef(null);
    const timelineRef = useRef(null);


    const wsRef = useRef(null)

    const [playing, setPlaying] = useState(true);
    const regions = RegionsPlugin.create()
    const timeline = TimelinePlugin.create({ container: '#wave-timeline' })

    const [isShowRegion, setIsShowRegion] = useState(true)

    let ws
    useEffect(() => {
        ws = WaveSurfer.create({
            container: '#waveform',
            scrollParent: true,
            autoCenter: true,
            cursorColor: '#F33B30',
            waveColor: '#404040',
            progressColor: '#F33B30',
            responsive: true,
            barHeight: 0.5,
            plugins: [regions, timeline
            ],
        })

        ws.on('decode', () => {
            regions.addRegion({
                start: 0,
                end: 30,
                color: isShowRegion ? "rgba(225, 225, 225, 0.2)" : "rgba(0, 0, 0, 0)",
                resize: false,
                drag: isShowRegion
            })
        })

        wsRef.current = ws

        return () => {
            if (ws) ws.destroy();
        }
    }, [])

    const togglePlayPause = () => {
        const ws = wsRef.current;
        if (ws.isPlaying()) {
            ws.pause();
            setPlaying(false);
        } else {
            // TODO: Once looping it doesn't stop
            if (playRegionOnly) {

                let findRegions
                for (let i = 0; i < ws.plugins.length; i++) {
                    if (ws.plugins[i].regions) {
                        findRegions = ws.plugins[i]
                        break;
                    }
                }

                if (findRegions) {
                    findRegions.regions[0].play()

                    const handleAudioProcess = () => {
                        if (ws.getCurrentTime() >= findRegions.regions[0].end) {
                            ws.seekTo(findRegions.regions[0].start / ws.getDuration()); // Restart from the start
                        }
                    }

                    ws.on('audioprocess', handleAudioProcess);
                    return () => {
                        ws.un('audioprocess', handleAudioprocess);
                        setPlayRegionOnly(false)
                    };


                }
            } else {
                ws.play();
            }
            setPlaying(true);
        }
    };

    const handleToggleShowRegion = () => {
        setIsShowRegion(!isShowRegion)
    };


    useEffect(() => {
        if (fileURL && ws) {
            ws.load(fileURL)
        }
    }, [fileURL, ws]);



    const getB64 = async (blobUrl) => {
        return new Promise((resolve, reject) => {
            fetch(blobUrl)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64String = reader.result.split(',')[1];
                        resolve(base64String);
                    };
                    reader.onerror = () => {
                        reject(new Error('Failed to read Blob as Data URL'));
                    };
                    reader.readAsDataURL(blob);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    useEffect(() => {
        if (wsRef.current) {
            regions.on('region-updated', async (r) => {
                const regionInside = wsRef.current.plugins[0].regions[0];
                const b64 = await getB64(fileURL);

                // Use functional setState to preserve fileType and other properties
                setBaseObject((prevState) => ({
                    ...prevState,
                    base64: b64,
                    startTime: Math.round(regionInside.start),
                    endTime: Math.round(regionInside.end),
                }));
            });
        }
    }, [wsRef, fileURL]);



    return (
        <section className='waveform-container'>
            <div ref={wavesurferRef} id='waveform' />
            <div ref={timelineRef} id='wave-timeline' />
            <div className='all-controls text-white'>
                <div className='left-container'>
                    <svg
                        width="50"
                        height="50"
                        viewBox="0 0 24 24"
                        style={{
                            fill: "#000",
                            transition: "fill 0.3s ease",
                            cursor: "pointer",
                            backgroundColor: "#fff",
                            borderRadius: "50%"
                        }}
                        onClick={togglePlayPause}
                    >
                        {playing ? (
                            <g>
                                <path d="M14 5h4v14h-4z" />
                                <path d="M6 5h4v14H6z" />
                            </g>
                        ) : (
                            <path d="M8 5v14l11-7z" />
                        )}
                    </svg>
                    <div>
                        {/*<p style={{ margin: 0 }} onClick={handleSelectArea}>*/}
                        {/*    Select Area*/}
                        {/*</p>*/}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MusicWaveform