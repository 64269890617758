import { CardBody, CardHeader, CardFooter, CardTitle, Row, Col, FormGroup, Input, Button, Label, UncontrolledTooltip } from "reactstrap"

import { useState, useEffect } from 'react'
import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import Swal from "sweetalert2";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import ReactDatetime from "react-datetime";
import MP3Upload from "../../../components/CustomUpload/MP3Upload";
import Select from "react-select";
import tooltipText from "../../../config/tooltipText";



function CreateMusicTag(props) {
    

    
    return (
        <>
            <div className="content">
                <CardBody>
                    <Row>
                        <Col>

                            <FormGroup>
                                <Label className="labelText">Name</Label>
                                <Button
                                    className="btn-icon dropdownIgnore tooltipHover"
                                    color="danger"
                                    id="tooltip808966390111"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390111"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                    
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.ADVERTS.ADVERT_NAME}</p>
                                </UncontrolledTooltip>
                                <Input className="inputStyle placeholderWhite" type="text" value={props.baseObject.name} onChange={(e) => props.setBaseObject({ ...props.baseObject, name: e.target.value })} />
                                {props.missingFields.includes("name") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>Please enter a name for the advert</p> : null }
                            </FormGroup>


                        </Col>

                    </Row>

                    <Row>
                        <Col>

                        </Col>
                    </Row>
                    
                </CardBody>
            </div>

        </>
    )
}

export default CreateMusicTag