
import { Row, Col, Button, Input, FormGroup } from "reactstrap"

import KlaxonLogo from "assets/img/klaxonImages/klaxonFull.png"

import BBC from "assets/img/clonepage/bbc.jpg"
import MIC from "assets/img/clonepage/mic.jpg"
import PODCASTINGTODAY from "assets/img/clonepage/podcastingtoday.jpg"
import PODNEWS from "assets/img/clonepage/podnews.jpg"
import DIGWATCH from "assets/img/clonepage/digwatch.jpg"

import CARD1 from "assets/img/clonepage/card1.jpg"
import CARD2 from "assets/img/clonepage/card2.jpg"
import CARD3 from "assets/img/clonepage/card3.jpg"

import TESTIMONIAL1 from "assets/img/clonepage/tesimonial1Try.jpg"
import TESTIMONIAL2 from "assets/img/clonepage/tesimonial2Try.jpg"
import { useState } from "react"
import { useEffect } from "react"
import apiConfig from "../../../config/apiConfig"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const Home = () => {
    const [isContactFormOpen, setIsContactFormOpen] = useState(false)
    const [contactForm, setContactForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        industry: "",
        message: ""
    })

    const [invalidFirstName, setInvalidFirstName] = useState(false)
    const [invalidLastName, setInvalidLastName] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)

    const [listJoined, setListJoined] = useState(false)
    const [listError, setListError] = useState("")

    const handleSubmit = async () => {
        let isError = false
        if (contactForm.firstName === "") {
            setInvalidFirstName(true)
            isError = true
        }

        if (contactForm.lastName === "") {
            setInvalidLastName(true)
            isError = true
        }

        if (contactForm.email === "") {
            setInvalidEmail(true)
            isError = true
        }

        if (isError)
            return

        const {email, firstName, lastName, phone, industry, message } = contactForm
        let url = `${apiConfig.baseUrl}${apiConfig.MAILING_LIST.JOIN_CONTACT_LIST}?email=${email}&firstName=${firstName}&lastName=${lastName}`

        if (phone != "") {
            url = url + `&phoneNumber=${phone}`
        }

        if (industry != "") {
            url = url + `&industry=${industry}`
        }

        if (message != "") {
            url = url + `&message=${message}`
        }


        await axios
            .post(url)
            .then((res) => {
                if (res?.status === 200) {
                    setListJoined(true)
                } 
            })
            .catch((err) => {    
                if (err.response?.data) {
                setListError(err.response.data);
            } else {
                setListError("An unexpected error occurred."); // Fallback message
            }
            });
    }

    const resetContactForm = () => {
        setIsContactFormOpen(false)
        setListError("")
        setListJoined(false)
    }

    useEffect(() => {
        setInvalidFirstName(false)
    }, [contactForm.firstName])
    useEffect(() => {
        setInvalidLastName(false)
    }, [contactForm.lastName])
    useEffect(() => {
        setInvalidEmail(false)
    }, [contactForm.email])

    return (
        <>
            {isContactFormOpen &&
                <div style={{ position: "fixed", width: "100%", height: "100vh", top: 0, left: 0, backgroundColor: "rgba(0, 0, 0, 0.3)", zIndex: 9999, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ backgroundColor: "#fff", borderRadius: 15, display: "flex", flexDirection: "column", justifyContent: "", width: "", minHeight: "", alignItems: "center", padding: 10, gap: 10 }} className="formWidth">

                        <div style={{position: "relative", width: "100%", textAlign: "right", paddingRight: 5} }>
                            <i className="fa fa-times" style={{ cursor: "pointer" }} onClick={() => setIsContactFormOpen(false)} />

                        </div>

                        {!listJoined && listError === "" ?
                            <>
                                <div>
                                    Leave your details and we'll be in touch very shortly
                                </div>

                                <div>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <FormGroup className="backgroundOutside">
                                                    <Input style={{ border: !invalidFirstName ? "1px solid black" : "1px solid red" }} placeholder="First name *" onChange={(e) => setContactForm({ ...contactForm, firstName: e.target.value })} className="softerBorder"/>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup className="backgroundOutside">
                                                    <Input style={{ border: !invalidLastName ? "1px solid black" : "1px solid red" }} placeholder="Last name *" onChange={(e) => setContactForm({ ...contactForm, lastName: e.target.value })} className="softerBorder" />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup className="backgroundOutside">
                                                    <Input className="softerBorder" style={{ border: !invalidEmail ? "1px solid black" : "1px solid red" }} placeholder="Email *" onChange={(e) => setContactForm({ ...contactForm, email: e.target.value })} />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup className="backgroundOutside">
                                                    <Input className="softerBorder" style={{ border: "1px solid black" }} placeholder="Phone" onChange={(e) => setContactForm({ ...contactForm, phone: e.target.value })} />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup className="backgroundOutside">
                                                    <Input className="softerBorder" style={{ border: "1px solid black" }} placeholder="Industry" onChange={(e) => setContactForm({ ...contactForm, industry: e.target.value })} />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup className="backgroundOutside">
                                                    <Input className="softerBorder" style={{ border: "1px solid black", maxHeight: "none" }} placeholder="Message" onChange={(e) => setContactForm({ ...contactForm, message: e.target.value })} type="textarea" rows={4} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>

                                <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center" }}>
                                    <Button style={{ backgroundColor: "darkorange", color: "#fff", width: "90%", fontWeight: "normal", textTransform: "none", fontSize: 18, borderRadius: 13 }} onClick={handleSubmit} className="buttonChanger">Submit</Button>
                                    <div style={{ backgroundColor: "", width: "90%", margin: "auto" }}>

                                        By registering you consent to receive reminder emails which you can opt out of at any time

                                    </div>
                                </div>
                            </> : null
                        }
                        {listJoined &&
                            <>
                                <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center" }}>
                                    <div style={{ backgroundColor: "", width: "100%", margin: "auto" }}>
                                        Thanks {contactForm.firstName }, we'll be in touch
                                    </div>
                                    <Button style={{ backgroundColor: "rgb(28, 120, 254)", color: "#fff", width: "90%", fontWeight: "normal", textTransform: "none" }} onClick={() => resetContactForm()} className="buttonChanger">Great</Button>
                                </div>
                            </>
                        }
                        {listError !== "" &&
                            <>
                                <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center" }}>
                                    <div style={{ backgroundColor: "", width: "100%", margin: "auto" }}>
                                        {listError}
                                    </div>
                                    <Button style={{ backgroundColor: "rgb(28, 120, 254)", color: "#fff", width: "90%", fontWeight: "normal", textTransform: "none" }} onClick={() => resetContactForm()} className="buttonChanger">Ok</Button>
                                </div>
                            </>
                        }                          
                    </div>
                </div>
            }
            <Col>
                <Row>
                    <div style={{
                        position: "relative",
                        width: "100%",
                        minHeight: "70vh",
                        overflow: "hidden"
                    }}>
                        <div style={{
                            backgroundImage: `url(${require("assets/img/bg/bg.jpg")})`,
                            width: "100%",
                            height: "100%",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            filter: "brightness(0.6)",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 0
                        }} />
                        <div style={{ position: "relative", zIndex: 1, width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }} className="mt-4">
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <img src={KlaxonLogo} alt="Logo" style={{ height: 45 }} />
                            </div>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }} className="mt-5 pt-2">
                                <h1 style={{ width: "", textAlign: "center", color: "#fff" }} className="titleOpen">
                                    Transform your <span style={{ fontWeight: "bold" }}>newsletters</span> and <span style={{ fontWeight: "bold" }}>blogs</span> into <span style={{ fontWeight: "bold" }}>podcasts</span>
                                </h1>
                            </div>
                            <div>
                                <p style={{ color: "yellow", textAlign: "center", fontSize: 24 }}>Attract and engage customers with our 'done for you' service</p>
                                <p style={{ color: "#fff", textAlign: "center", fontSize: 14 }}>No need for a Script, Tech Skills, Recording Equipment, or even a ‘Broadcast’ Voice.</p>

                                <p style={{ color: "yellow", textAlign: "center", fontWeight: "bold", fontStyle: "italic", fontSize: 24 }}>GET A FREE SAMPLE PODCAST FOR YOUR BUSINESS</p>
                                <div style={{ textAlign: "center" }}>
                                    <Button style={{ backgroundColor: "darkorange", color: "#fff", width: "50%", fontWeight: "normal", textTransform: "none", fontSize: 18, borderRadius: 13 }} onClick={() => setIsContactFormOpen(true)} className="buttonChanger">Contact Us</Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </Row>
                <Row style={{ backgroundColor: "rgb(234, 241, 245)" }} className="pb-5">
                    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column", }} className="mt-5 pt-4">
                        <div>
                            <h3>As featured in</h3>
                        </div>
                        <div style={{
                            width: "80%"
                        }}>
                            <Row className="featuredCont">
                                <Col className="featuredItem">
                                    <img src={BBC} style={{}} />
                                </Col>
                                <Col className="featuredItem">
                                    <img src={MIC} style={{}} />
                                </Col>
                                <Col style={{ display: "flex", alignItems: "center" }} className="featuredItem">
                                    <img src={PODCASTINGTODAY} style={{}} />
                                </Col>
                                <Col style={{ display: "flex", alignItems: "center" }} className="featuredItem">
                                    <img src={PODNEWS} style={{}} />
                                </Col>
                                <Col style={{ display: "flex", alignItems: "center" }} className="featuredItem">
                                    <img src={DIGWATCH} style={{}} />
                                </Col>
                            </Row>


                        </div>
                    </div>
                </Row>
                <Row>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }} className="mt-5 pt-4">
                        <div>
                            <h3 style={{ textAlign: "center" }} className="definedTitle">Want to know more?</h3>
                            <h3 style={{ textAlign: "center" }} className="definedTitle">Listen to our Klaxon Podcast</h3>
                        </div>
                        <div className="ytCont">
                            <iframe className="ytSize" src="https://www.youtube.com/embed/UuQJ6uU3Ti8?si=qmlwLEtKv1FSeV9U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }} className="mt-5 pt-4 mb-5">
                        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <h3 style={{ textAlign: "center" }} className="definedTitle">Professional podcasts without the faff</h3>
                            <p style={{ textAlign: "center", fontSize: 14, width: "50%" }}>We use AI and deep journalism to create highly engaging B2B or B2C podcasts for you, saving you time, money, and stress.</p>
                            <p style={{ textAlign: "center", fontSize: 14, width: "50%" }}>You reap the benefits and attract and engage new audiences and prospects, all at a fraction of the cost of traditional podcast production.</p>
                        </div>

                        <div style={{ backgroundColor: "", width: "", display: "flex", justifyContent: "space-between" }} className="cardContain">
                            <div style={{ borderRadius: 35, width: "", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "", boxShadow: "0px 8px 28px 0px rgba(0, 0, 0, 0.09)" }} className="a13card">
                                <img src={CARD1} style={{ borderTopLeftRadius: 35, borderTopRightRadius: 35, width: "100%", borderBottomRightRadius: 0, borderBottomLeftRadius: 0}} />
                                <div style={{ width: "90%" }} className="mt-2 p-2">
                                    <p style={{ fontWeight: "bold", fontSize: 15 }}>Why your business needs podcasts</p>
                                    <p style={{ fontSize: 14 }}>1. Podcasts are the fastest growing media format in the world</p>
                                    <p style={{ fontSize: 14 }}>2. More than 20m people (32% of adults) in the UK listen to podcasts every month, and this is forecast to grow to 28m people by 2026</p>
                                    <p style={{ fontSize: 14 }}>3. Podcast advertising is the most effective advertising format around (better than radio, TV, online, or any other form of advertising)</p>
                                </div>
                            </div>

                            <div style={{ borderRadius: 35, width: "", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "", boxShadow: "0px 8px 28px 0px rgba(0, 0, 0, 0.09)" }} className="a13card">
                                <img src={CARD2} style={{ borderTopLeftRadius: 35, borderTopRightRadius: 35, width: "100%", borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }} />
                                <div style={{ width: "90%" }} className="mt-2 p-2">
                                    <p style={{ fontWeight: "bold", fontSize: 15 }}>About podcast listeners</p>
                                    <p style={{ fontSize: 14 }}><i className="far fa-check-circle mr-2" /> Podcast listeners are highly engaged – 93% listen to a podcast from start to finish</p>
                                    <p style={{ fontSize: 14 }}><i className="far fa-check-circle mr-2" />62% say they have acted on a brand message heard in a podcast</p>
                                    <p style={{ fontSize: 14 }}><i className="far fa-check-circle mr-2" />People of all ages listen to podcasts with 25 to 34-year olds the most avid listeners</p>
                                    <p style={{ fontSize: 14 }}><i className="far fa-check-circle mr-2" />66% of podcast listeners have a degree and an average household income of &pound;60k+</p>
                                </div>
                            </div>

                            <div style={{ borderRadius: 35, width: "", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "", boxShadow: "0px 8px 28px 0px rgba(0, 0, 0, 0.09)" }} className="a13card">
                                <img src={CARD3} style={{ borderTopLeftRadius: 35, borderTopRightRadius: 35, width: "100%", borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }} />
                                <div style={{ width: "90%" }} className="mt-2 p-2">
                                    <p style={{ fontWeight: "bold", fontSize: 15 }}>How it works</p>
                                    <p style={{ fontSize: 14 }}>1. You choose the content, subject and specifications for your podcasts – length, frequency, style, voice type, etc – or we can recommend a perfect spec for you</p>
                                    <p style={{ fontSize: 14 }}>2. We create your podcasts for you using content we create or you send to us (did we mention that Klaxon AI is unique in using AI and journalists?)</p>
                                    <p style={{ fontSize: 14 }}>3. We send your completed podcasts for you to share wherever you like (on social media, your website, email, Spotify and Apple, etc)</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </Row>

                <Row>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }} className="mt-5 pt-4 mb-5">
                        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", width: "" }} className="title2">
                            <h3 style={{ textAlign: "center" }} className="definedTitle">Create regular podcasts that demonstrate your expertise and knowledge</h3>
                        </div>

                        <div style={{ backgroundColor: "", width: "", display: "flex", justifyContent: "" }} className="cardContain pt-4">
                            <div style={{ borderRadius: 35, width: "", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "", boxShadow: "0px 8px 28px 0px rgba(0, 0, 0, 0.09)" }} className="a13card pt-3">
                                <img src={TESTIMONIAL1} style={{ borderRadius: "50%", height: 150, width: 150 }} />
                                <div style={{ width: "90%" }} className="mt-2">
                                    <ul style={{ fontSize: 13, display: "flex", flexDirection: "column", gap: 10 }} className="mt-2">
                                        <li>Episode 1 - Contracts and agreement</li>
                                        <li>Episode 2 - Company essentials</li>
                                        <li>Episode 3 - Shardholder rights</li>
                                        <li>Episode 4 - Director responsibilities</li>
                                        <li>Episode 5 - Employing staff</li>
                                        <li>Episode 6 - Trading overseas</li>
                                        <li>Episode 7 - Patents and IP</li>
                                        <li>Episode 8 - Scaling your business</li>
                                    </ul>
                                    <p style={{ textAlign: "center", margin: 0, fontSize: 14, fontWeight: "bold" }}>Hermionie Teasdale</p>
                                    <p style={{ textAlign: "center", fontSize: 13 }}>The Lawyer</p>
                                </div>
                            </div>

                            <div style={{ borderRadius: 35, width: "", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "", boxShadow: "0px 8px 28px 0px rgba(0, 0, 0, 0.09)" }} className="a13card pt-3">
                                <img src={TESTIMONIAL2} style={{ borderRadius: "50%", height: 150, width: 150, objectFit: "cover" }} />
                                <div style={{ width: "90%" }} className="mt-2">
                                    <ul style={{ fontSize: 13, display: "flex", flexDirection: "column", gap: 10 }} className="mt-2">
                                        <li>Episode 1 - Mindfulness</li>
                                        <li>Episode 2 - Emotions</li>
                                        <li>Episode 3 - Positive thinking</li>
                                        <li>Episode 4 - Tools and tips</li>
                                        <li>Episode 5 - Good practices</li>
                                        <li>Episode 6 - Overcoming negativity</li>
                                        <li>Episode 7 - NLP</li>
                                        <li>Episode 8 - Beliefs</li>
                                    </ul>
                                    <p style={{ textAlign: "center", margin: 0, fontSize: 14, fontWeight: "bold" }}>Jo Smythe</p>
                                    <p style={{ textAlign: "center", fontSize: 13 }}>The Business Coach</p>
                                </div>
                            </div>

                            <div style={{ borderRadius: 35, width: "", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "", boxShadow: "0px 8px 28px 0px rgba(0, 0, 0, 0.09)" }} className="a13card pt-3">
                                <img src={MIC} style={{ borderRadius: "50%", height: 150, width: 150 }} />
                                <div style={{ width: "90%" }} className="mt-2">

                                    <p style={{ textAlign: "center", margin: 0, fontSize: 13, fontStyle: "italic" }} className="mt-2">
                                        "By using technology to make the process of podcast production easier and more creator-accessible, Klaxon AI can really democratize the podcasting industry. This may result in a considerable amount of diverse voices and perspectives being brought into the limelight across the podcasting community."
                                    </p>
                                    <p style={{ textAlign: "center", margin: 0, fontSize: 14, fontWeight: "bold" }} className="mt-2">Podwires.com</p>
                                </div>
                            </div>


                        </div>
                    </div>

                </Row>

                <Row>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }} className="mt-5 pt-4 mb-5">
                        <div style={{ width: "", display: "flex", flexDirection: "column", alignItems: "center", width: "" }} className="title2">
                            <h3 style={{ textAlign: "center" }}>Create regular podcasts that demonstrate your expertise and knowledge</h3>
                        </div>

                        <div style={{ backgroundColor: "", width: "", display: "flex", justifyContent: "" }} className="cardContain">
                            <div style={{ width: "", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "", }} className="a13card1">
                                <div style={{ width: "90%", backgroundColor: "rgb(48, 56, 104)", color: "#fff", borderRadius: 35 }} className="mt-2 pt-2 pb-2">
                                    <div style={{ textAlign: "center" }} className="mt-2  pb-2">
                                        <h3 style={{ margin: 0, fontWeight: "bold" }}>MONTHLY PODCAST</h3>
                                        <p style={{fontWeight: "bold", fontSize: 14} }>&pound;130 a month</p>
                                    </div>

                                    <div style={{ width: "90%" }}>
                                        <ul style={{ fontSize: 13, display: "flex", flexDirection: "column", gap: 10 }} className="mt-2">
                                            <li style={{ fontWeight: "bold" }}>One podcast per month</li>
                                            <li>20 minutes per episode (max)</li>
                                            <li>Choice of more than 30 different high quality voices</li>
                                            <li>We turn your content into perfect podcast scripts</li>
                                            <li>We provide your podcasts on agreed dates for you to use as you wish</li>
                                            <li>We publish and host your podcasts on your own player page on Klaxon AI and provide your podcast as an MP3 to use elsewhere</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "", }} className="a13card1">
                                <div style={{ width: "90%", backgroundColor: "rgb(48, 56, 104)", color: "#fff", borderRadius: 35 }} className="mt-2  pt-2 pb-2">
                                    <div style={{ textAlign: "center" }} className="mt-2  pb-2">
                                        <h3 style={{ margin: 0, fontWeight: "bold" }}>FORTNIGHTLY PODCAST</h3>
                                        <p style={{ fontWeight: "bold", fontSize: 14 }}>&pound;240 a month</p>
                                    </div>

                                    <div style={{ width: "90%" }}>
                                        <ul style={{ fontSize: 13, display: "flex", flexDirection: "column", gap: 10 }} className="mt-2">
                                            <li style={{ fontWeight: "bold" }}>One podcast per fortnight</li>
                                            <li>20 minutes per episode (max)</li>
                                            <li>Choice of more than 30 different high quality voices</li>
                                            <li>We turn your content into perfect podcast scripts</li>
                                            <li>We provide your podcasts on agreed dates for you to use as you wish</li>
                                            <li>We publish and host your podcasts on your own player page on Klaxon AI and provide your podcast as an MP3 to use elsewhere</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "", }} className="a13card1">
                                <div style={{ width: "90%", backgroundColor: "rgb(48, 56, 104)", color: "#fff", borderRadius: 35 }} className="mt-2  pt-2 pb-2">
                                    <div style={{ textAlign: "center" }} className="mt-2 pb-2">
                                        <h3 style={{ margin: 0, fontWeight: "bold" }}>WEEKLY PODCAST</h3>
                                        <p style={{ fontWeight: "bold", fontSize: 14 }}>&pound;440 a month</p>
                                    </div>

                                    <div style={{ width: "90%" }}>
                                        <ul style={{ fontSize: 13, display: "flex", flexDirection: "column", gap: 10 }} className="mt-2">
                                            <li style={{ fontWeight: "bold" }}>One podcast per week</li>
                                            <li>20 minutes per episode (max)</li>
                                            <li>Choice of more than 30 different high quality voices</li>
                                            <li>We turn your content into perfect podcast scripts</li>
                                            <li>We provide your podcasts on agreed dates for you to use as you wish</li>
                                            <li>We publish and host your podcasts on your own player page on Klaxon AI and provide your podcast as an MP3 to use elsewhere</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>

                </Row>

                <Row className="ePadd">
                    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }} className="pt-4 pb-5">


                        <div style={{ backgroundColor: "", width: "", display: "flex", justifyContent: "", backgroundColor: "" }} className="btnCont">
                            <div style={{ borderRadius: 35, width: "", display: "flex", flexDirection: "column", justifyContent: "", backgroundColor: "" }} className="btnBtn">

                                <div>
                                    <h3 style={{ margin: 0 }}>Book a free demo</h3>
                                    <p>Diary a 30-minute slot and we'll show you the magic</p>
                                </div>

                                <a href="https://appt.link/meet-with-klaxon-ai-RipEPVZy/web-conference" target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                                    <Button style={{ backgroundColor: "darkorange", color: "#fff", width: "50%", fontWeight: "normal", textTransform: "none", fontSize: 18, borderRadius: 13 }} className="buttonChanger">Book a Demo</Button>
                                </a>
                            </div>

                            <div style={{ borderRadius: 35, width: "", display: "flex", flexDirection: "column", justifyContent: "", backgroundColor: ""}} className="btnBtn">

                                <div>
                                    <h3 style={{ margin: 0 }}>Drop us a line and let's talk</h3>
                                    <p>Leave your contact details and we'll come back to you by email or phone.</p>
                                </div>
                                <div >
                                    <Button style={{ backgroundColor: "darkorange", color: "#fff", width: "50%", fontWeight: "normal", textTransform: "none", fontSize: 18, borderRadius: 13 }} onClick={() => setIsContactFormOpen(true)} className="buttonChanger">Contact us</Button>
                                </div>
                            </div>
                        </div>


                    </div>

                </Row>

                {/*<Row className="bp">*/}
                {/*    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }} className="mt-5 pt-4 mb-5">*/}
                {/*        <div style={{ backgroundColor: "", width: "60%", display: "flex", flexDirection: "column" }}>*/}
                {/*            <div>Follow Klaxon AI on:</div>*/}
                {/*            <div style={{ display: "flex", justifyContent: "center" }} className="mt-2">*/}
                {/*                <div style={{ width: "", display: "flex", flexDirection: "column", gap: 15, justifyContent: "flex-end" }} className="links1">*/}
                {/*                    <a href="https://www.instagram.com/klaxon_ai/?igsh=OXFhcWJycWxydmk3" target="_blank" rel="noreferrer" style={{ color: "rgb(44, 44, 44)", textDecoration: "none" }}>*/}
                {/*                        <div style={{ display: "flex", gap: 25, borderBottom: "1px solid black", paddingTop: 8, paddingBottom: 8, cursor: "pointer" }}>*/}
                {/*                            <h2 style={{ margin: 0 }}>01</h2>*/}
                {/*                            <h2 style={{ margin: 0 }}>Instagram</h2>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="https://www.tiktok.com/@klaxonai" target="_blank" rel="noreferrer" style={{ color: "rgb(44, 44, 44)", textDecoration: "none" }}>*/}
                {/*                        <div style={{ display: "flex", gap: 25, borderBottom: "1px solid black", paddingTop: 8, paddingBottom: 8, cursor: "pointer" }}>*/}
                {/*                            <h2 style={{ margin: 0 }}>02</h2>*/}
                {/*                            <h2 style={{ margin: 0 }}>TikTok</h2>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="https://www.facebook.com/KlaxonAI1" target="_blank" rel="noreferrer" style={{ color: "rgb(44, 44, 44)", textDecoration: "none" }}>*/}
                {/*                        <div style={{ display: "flex", gap: 25, borderBottom: "1px solid black", paddingTop: 8, paddingBottom: 8, cursor: "pointer" }}>*/}
                {/*                            <h2 style={{ margin: 0 }}>03</h2>*/}
                {/*                            <h2 style={{ margin: 0 }}>Facebook</h2>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="https://www.linkedin.com/company/klaxonai/" target="_blank" rel="noreferrer" style={{ color: "rgb(44, 44, 44)", textDecoration: "none" }}>*/}
                {/*                        <div style={{ display: "flex", gap: 25, borderBottom: "1px solid black", paddingTop: 8, paddingBottom: 8, cursor: "pointer" }}>*/}
                {/*                            <h2 style={{ margin: 0 }}>04</h2>*/}
                {/*                            <h2 style={{ margin: 0 }}>LinkedIn</h2>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                </div>*/}
                {/*                <div style={{ width: "35%" }} className="noImg">*/}
                {/*                    <img src={TESTIMONIAL2} style={{ width: "100%", borderRadius: 0, height: "auto" }} />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Row>*/}
            </Col>
        </>
    )
}

export default Home