/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import classnames from "classnames";

// reactstrap components
import { Row, Col, Label, FormGroup, Button, UncontrolledTooltip, Input } from "reactstrap";

import Select from "react-select";
import ReactDatetime from "react-datetime";

import tooltipText from "../../../config/tooltipText";


const Step2 = React.forwardRef((props, ref) => {

    const [singleSelectFrequency, setSingleSelectFrequency] = useState([])
    const [singleSelectDuration, setSingleSelectDuration] = useState([])
    const [singleSelectReviewPeriod, setSingleSelectReviewPeriod] = useState([])
    const [alert, setAlert] = useState(null)
    const [isUnlimited, setIsUnlimited] = useState(false)
    const [multiSelectCategory, setMultiSelectCategory] = useState([])

    const handleDurationChange = (value) => {
        setSingleSelectDuration(value)
        setAlert(null)
        props.setBaseObject({ ...props.baseObject, durationId: value.value, durationName: value.label })
    }

    const handleReviewPeriodChange = (value) => {
        setSingleSelectReviewPeriod(value)
        setAlert(null)


        props.setBaseObject(prevState => {
            const updatedBaseObject = { ...prevState, reviewPeriodId: value.value, reviewPeriodName: value.label };

            const dateStringAsDate = new Date(updatedBaseObject.startDateTime);
            const dateNow = new Date();
            dateNow.setHours(dateNow.getHours() + value.value);

            if (dateNow > dateStringAsDate) {
                updatedBaseObject.startDateTime = dateNow.toISOString('O');
            } else {
                updatedBaseObject.startDateTime = dateStringAsDate.toISOString('O');
            }

            return updatedBaseObject;
        });

        
    }

    const handleFrequencyChange = (value) => {
        setSingleSelectFrequency(value)
        setAlert(null)
        props.setBaseObject({ ...props.baseObject, frequencyId: value.value, frequencyName: value.label })
    }

    const handleEpisodeDefault = () => {
        if (props.baseObject.maxEpisodes === 0) {
            setIsUnlimited(true)
        }
    }

    const handleSelectCategory = (value) => {

        if (value === null) {
            setMultiSelectCategory([])
            props.setBaseObject({ ...props.baseObject, categories: [] })
            return
        }
        setMultiSelectCategory(value)

        const reformatObject = value.map((cat) => {
            return (
                cat.value
            )
        }).join(", ")

        props.setBaseObject({ ...props.baseObject, categories: reformatObject })
    }

    React.useImperativeHandle(ref, () => ({
        isValidated: () => { 
            let isValid;
            if (props.baseObject.frequencyId === 1) {
                isValid = [props.baseObject.durationId, props.baseObject.startDateTime].every(e => e != 0)
            } else {
                isValid = [props.baseObject.durationId, props.baseObject.frequencyId, props.baseObject.startDateTime].every(e => e != 0) && props.baseObject.reviewPeriodName !== "" && props.baseObject.categories !== "" && props.baseObject.categories !== "0"
            }
            
            if (!isValid) {
                setAlert("Please complete all fields")
                return isValid
            }


            props.setTitleNumber(props.titleNumber + 1)
            return isValid
        },
    }));

    useEffect(() => {
        if (props.baseObject.frequencyId === 1) {
            props.setBaseObject({ ...props.baseObject, maxEpisodes: 1})
        }
    }, [props.baseObject.frequencyId])

    useEffect(() => {
        if (isUnlimited === true && props.baseObject.maxEpisodes !== 0) {
            props.setBaseObject({ ...props.baseObject, maxEpisodes: 0 })
        }

        if (isUnlimited === false && props.baseObject.maxEpisodes < 2) {
            props.setBaseObject({ ...props.baseObject, maxEpisodes: 2 })
        }
    }, [isUnlimited])

    useState(() => {

    }, [props.baseObject.startDateTime])

    const updateTime = (date) => {
        const newTime = new Date(date)

        if (props.baseObject.reviewPeriodId === 0) {

            const dateNow = new Date();
            dateNow.setMinutes(dateNow.getMinutes() + 10);
            if (dateNow > newTime) {
                props.setBaseObject({ ...props.baseObject, startDateTime: dateNow.toISOString('O') })
            } else {
                props.setBaseObject({ ...props.baseObject, startDateTime: newTime.toISOString('O') })
            }

        }

        //props.setBaseObject({ ...props.baseObject, startDateTime: newTime})
    }

    useEffect(() => {
        const getReviewPeriod = props.dropdownReviewPeriods.find((e) => e.value === props.baseObject.reviewPeriodId)
        setSingleSelectReviewPeriod(getReviewPeriod)
    }, [])



    return (
        <>
            <Col className="justify-content-center">
                <h4 className="text-center" style={{ color: "#F33B30" }}><strong>{alert}</strong></h4>
                <Row>
                    <Col sm="12" md="12" lg="3">
                        <FormGroup>
                            <Label>Start Date</Label>
                            <Button
                                className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                id="tooltip808966390111"
                                size="sm"
                                style={{ backgroundColor: "white" }}
                            >
                                <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip808966390111"
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                <p style={{ margin: 0 }}>{tooltipText.DETAILS.START_DATE}</p>
                            </UncontrolledTooltip>
                        </FormGroup>
                        <ReactDatetime
                            inputProps={{
                                className: "form-control",
                                initialValue: "",
                            }}
                            value={new Date(props.baseObject.startDateTime)}
                            onChange={(date) => updateTime(date._d)}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}

                        />
                    </Col>
                    <Col sm="12" md="12" lg="3">
                        <FormGroup>
                            <Label>Start Time</Label>
                            <Button
                                className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                id="tooltip808966390111"
                                size="sm"
                                style={{ backgroundColor: "white" }}
                            >
                                <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip808966390111"
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                <p style={{ margin: 0 }}>{tooltipText.DETAILS.START_DATE}</p>
                            </UncontrolledTooltip>
                        </FormGroup>
                        <ReactDatetime
                            inputProps={{
                                className: "form-control",
                                initialValue: "",
                            }}
                            value={new Date(props.baseObject.startDateTime)}
                            onChange={(date) => updateTime(date._d)}
                            dateFormat={false}
                        />
                    </Col>
                    <Col sm="12" md="12" lg="6">
                        <FormGroup>
                            <Label>Duration</Label>
                            <Button
                                className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                id="tooltip808966391222"
                                size="sm"
                                style={{ backgroundColor: "white" }}
                            >
                                <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip808966391222"
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                <p style={{ margin: 0 }}>{tooltipText.DETAILS.DURATION}</p>
                            </UncontrolledTooltip>
                        </FormGroup>
                        <Select
                            className="react-select "
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={singleSelectDuration}
                            onChange={(value) => handleDurationChange(value)}
                            options={props.dropdownDurations}
                        />
                    </Col>
                </Row>
                
                    <>
                    <Row>
                        <Col sm="12" md="12" lg="6">
                            <FormGroup>
                                <Label>Review Period</Label>
                                <Button
                                    className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                    id="tooltip808966392333"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966392333"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.DETAILS.REVIEW_PERIOD}</p>
                                </UncontrolledTooltip>
                            </FormGroup>
                            <Select
                                className="react-select "
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={singleSelectReviewPeriod}
                                onChange={(value) => handleReviewPeriodChange(value)}
                                options={props.dropdownReviewPeriods}
                            />
                        </Col>
                        {props.baseObject.frequencyId != 1 &&
                            <Col sm="12" md="12" lg="6">
                                <FormGroup>
                                    <Label>Frequency</Label>
                                    <Button
                                        className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                        id="tooltip808966393444"
                                        size="sm"
                                        style={{ backgroundColor: "white" }}
                                    >
                                        <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip808966393444"
                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                    >
                                        <p style={{ margin: 0 }}>{tooltipText.DETAILS.FREQUENCY}</p>
                                    </UncontrolledTooltip>
                                </FormGroup>
                                <Select
                                    className="react-select "
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={singleSelectFrequency}
                                    onChange={(value) => handleFrequencyChange(value)}
                                    options={props.dropdownFrequencys}
                                />
                            </Col>    
                        }
                    </Row>
                    {props.baseObject.frequencyId != 1 &&
                        <Row>
                            <Col sm="12" md="12" lg="6">
                                <FormGroup>
                                    <Label>Episodes</Label>
                                    <Button
                                        className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                        id="tooltip808966393123123"
                                        size="sm"
                                        style={{ backgroundColor: "white" }}
                                    >
                                        <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip808966393123123"
                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                    >
                                        <p style={{ margin: 0 }}>{tooltipText.DETAILS.EPISODES}</p>
                                    </UncontrolledTooltip>
                                </FormGroup>
                                <Row>

                                    <Col style={{ paddingBottom: 10 }} sm="12" md="12" lg="6">
                                        <FormGroup check>
                                            <Label check onChange={() => setIsUnlimited(!isUnlimited)}>
                                                <Input type="checkbox" checked={isUnlimited} />
                                                <span className="form-check-sign" />
                                                Unlimited
                                            </Label>
                                        </FormGroup>
                                    </Col>


                                </Row>
                            </Col>
                            {!isUnlimited && <Col sm="12" md="12" lg="6">
                                <FormGroup>
                                    <Label>Number of episodes</Label>
                                    <Button
                                        className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                        id="tooltip8089663931231255"
                                        size="sm"
                                        style={{ backgroundColor: "white" }}
                                    >
                                        <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip8089663931231255"
                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                    >
                                        <p style={{ margin: 0 }}>{tooltipText.DETAILS.AMOUNT}</p>
                                    </UncontrolledTooltip>
                                    <Input className="inputStyle" disabled={isUnlimited} value={props.baseObject.maxEpisodes} onChange={(e) => props.setBaseObject({ ...props.baseObject, maxEpisodes: Number(e.target.value) })} onBlur={handleEpisodeDefault} />
                                </FormGroup>
                            </Col>}
                        </Row>    
                    }
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label>Categories</Label>
                                <Select
                                    className="react-select klaxon-multi"
                                    classNamePrefix="react-select"
                                    placeholder="Categories"
                                    name="multipleSelect"
                                    closeMenuOnSelect={false}
                                    isMulti
                                    value={multiSelectCategory}
                                    onChange={(value) => handleSelectCategory(value)}
                                    options={props.categories}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    </>
                
            </Col>
        </>
    );
});

export default Step2;
