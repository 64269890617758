/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container, Row, Col } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { Link } from "react-router-dom"

import FacebookSVG from "assets/img/klaxonImages/facebookIcon.svg"
import InstaSVG from "assets/img/klaxonImages/instagramIcon.svg"
import XSVG from "assets/img/klaxonImages/xIcon.svg"

function AdminFooter() {
    return (
        <footer style={{ display: "flex", justifyContent: "center" }} >
            <div style={{ width: "90%", paddingTop: 40, paddingBottom: 10, fontSize: 18 }}>

                <Row>
                    <Col className="text-right">
                        <p>&copy; Copyright 2024 KLAXON AI LIMITED (15110517)</p>
                    </Col>
                </Row>
            </div>
        </footer>
    );
}


export default AdminFooter;
