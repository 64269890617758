/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";
import ReactBSAlert from "react-bootstrap-sweetalert"
import axios from "axios";
import { useNavigate } from "react-router-dom";

import ReactPlayer from "react-player";
import defaultImage from "../../assets/img/header.jpg"
import MyModal from "components/Shared/MyModal";
import CreateAd from "./components/CreateAd";
import TutorialModal from "../../components/Shared/TutorialModal";
import tutorialText from "config/tutorialText";



import "../../assets/css/custom-css.css"

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input,
    Spinner

} from "reactstrap";

import AdWizard from "../components/AdWizard";
import WizardAdCreate from "./AdWizardSteps/WizardAdCreate";
import WizardAdLanguage from "./AdWizardSteps/WizardAdLanguage";
import WizardAdTarget from "./AdWizardSteps/WizardAdTarget";
import WizardAdPayment from "./AdWizardSteps/WizardAdPayment";

import Credits from "./AdWizardComps/Credits";
import AdVoice from "./AdWizardComps/AdVoice";
import AdMusic from "./AdWizardComps/AdMusic";
import WizardMusicTest from "./AdWizardSteps/WizardMusicTest";
function AdsWithWizard() {


    const [modalOpen, setModalOpen] = useState(false)
    const [modalPage, setModalPage] = useState(null)
    const [modalComp, setModalComp] = useState(null)
    const [purchaseObject, setPurchaseObject] = useState(null)
    const [baseObject, setBaseObject] = useState({
        id: 0,
        userId: getJWTToken().decoded.nameid,
        name: "",
        length: 6,
        categories: [],
        isExactScript: false,
        geoLocations: [],
        age: [],
        interests: [],
        time: [],
        adPositions: [],
        runningFrom: new Date().toISOString(),
        runningTo: new Date().toISOString(),
        budget: 0,
        isAllCat: true,
        uploadedBase64: null
    })


    const [isMissingCredits, setIsMissingCredits] = useState(false)
    const toggleModal = () => {
        if (modalOpen) {
            setModalPage(null)
            setModalComp(null)
            setPurchaseObject(null)
        }

        setModalOpen(!modalOpen)
    }


    const [selectedModalObject, setSelectedModalObject] = useState(null)
    const configureModal = () => {
        let title
        let comp
        let nextText

        switch (modalPage) {
            case ("credits"):
                title = "Add more Credits"
                comp = <Credits />
                nextText = "Next"
                break;
            case ("voice"):
                title = "Select Voice"
                comp = <AdVoice />
                nextText = "Save"
                break;
            case ("music"):
                title = "Select Music"
                comp = <AdMusic />
                nextText = "Save"
                break;
        }

        setModalComp({ title, comp, nextText })
    }

    const handleSetModalPage = async (page, modalObject) => {
        if (modalObject) {
            await setSelectedModalObject(modalObject)
        }
        setModalPage(page)
    }


    useEffect(() => {
        if (modalPage)
            configureModal()
    }, [modalPage])

    useEffect(() => {
        if (modalComp)
            toggleModal()
    }, [modalComp])

    const [advertTargeting, setAdvertTargeting] = useState([])
    const getAdvertTargeting = async () => {
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.GET_ADVERT_TARGETING,
                "get"
            );
            if (resp.status === 200) {
                setAdvertTargeting(resp.data.map((targ) => {
                    return ({
                        value: targ.id,
                        label: targ.name,
                        typeId: targ.typeId
                    })
                })

                )
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const [categories, setCategories] = useState([])
    const getCategories = async () => {
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.GET_CATEGORIES,
                "get"
            );
            if (resp.status === 200) {
                setCategories(resp.data.map((cat) => {
                    return ({
                        value: cat.id,
                        label: cat.name
                    })
                })

                )
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const [adCredits, setAdCredits] = useState(0)
    const getCredits = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER_CREDIT.GET_CREDITS,
                "get"
            );
            if (resp.status === 200) {
                setAdCredits(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const getTransaction = async (id) => {
        try {
            const resp = await apiCall(
                apiConfig.TRANSACTION.GET_SINGLE + `?id=${id}`,
                "get"
            );
            if (resp.status === 200) {
                return resp.data
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const completePurchase = () => {
        toggleModal()
        getCredits()
        getUserBalance()
        window.dispatchEvent(new Event("balanceUpdated"))
    }

    const renewCredits = async () => {
        if (!purchaseObject?.id) {
            if (purchaseObject.productId === 0) return

            try {
                const resp = await apiCall(
                    apiConfig.TRANSACTION.INIT_PURCHASE,
                    "post", purchaseObject
                );
                if (resp.status === 200) {

                    if (resp.data.isPaid) {
                        completePurchase()
                    } else {
                        setPurchaseObject(resp.data)
                    }


                    //if (newTransaction.amountDue == 0) {
                    //    try {
                    //        const transResp = await apiCall(
                    //            apiConfig.TRANSACTION.CONFIRM_PURCHASE + `?id=${newTransaction.id}`,
                    //            "put"
                    //        );
                    //        if (transResp.status === 200) {
                    //            completePurchase()
                    //        }

                    //    } catch (error) {
                    //        console.error(error.message);
                    //    }
                    //} else {
                    //    setPurchaseObject({ ...purchaseObject, transactionId: resp.data, transaction: newTransaction })
                    //}


                }

            } catch (error) {
                console.error(error.message);
            }
        } else {
            //Stripe goes here
            try {
                const resp = await apiCall(
                    apiConfig.TRANSACTION.CONFIRM_PURCHASE + `?id=${purchaseObject.id}&advertId=${baseObject.id}`,
                    "put"
                );
                if (resp.status === 200) {
                    completePurchase()
                    setPurchaseComplete(true)
                }

            } catch (error) {
                console.error(error.message);
            }

        }
    }


    const [voices, setVoices] = useState([])
    const getVoices = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.VOICE.GET_VOICES}`
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const updatedVoices = res.data.map(voice => ({
                        ...voice,
                        voiceName: voice.voiceName.includes("-") ? voice.voiceName.split("-").pop() : voice.voiceName
                    }));

                    setVoices(updatedVoices);
                }
            })
            .catch((err) => {
                console.error(err)
            });

    }

    const [music, setMusic] = useState([])
    const getMusic = async () => {

        try {
            const resp = await apiCall(
                apiConfig.MUSIC.GET_ALL,
                "get"
            );
            if (resp.status === 200) {
                setMusic(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    };

    const [products, setProducts] = useState([])
    const getProducts = async () => {

        try {
            const resp = await apiCall(
                apiConfig.TRANSACTION.GET_ALL_TRANSACTION_PRODUCTS + `?typeId=1`,
                "get"
            );
            if (resp.status === 200) {
                setProducts(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    };

    const [userBalance, setUserBalance] = useState(0)
    const getUserBalance = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER.GET_USER_BALANCE,
                "get"
            );
            if (resp.status === 200) {
                setUserBalance(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const validateAdvertP1 = (ad) => {
        if (ad.length === 0)
            return false

        if (ad.script === "")
            return false

        return true
    }

    const [singleLoadId, setSingleLoadId] = useState(null)
    const regenerateContent = async (id) => {
        const copyObject = { ...baseObject };
        const contentSections = [...copyObject.content];
        const newGenerationObject = contentSections.find((content) => content.id === id)

        const isValidVoice = voices.find((voice) => voice.voiceId == newGenerationObject.voiceId)
        const isValidMusic = music.find((song) => song.id == newGenerationObject.musicId)

        if (!isValidVoice || !isValidMusic) {
            console.error("Invalid content")
            return
        }

        setSingleLoadId(id)

        try {
            const resp = await apiCall(
                apiConfig.ADVERT.REGENERATE_CONTENT + `?id=${id}`,
                "put", newGenerationObject
            );
            if (resp.status === 200) {
                const newContent = resp.data;

                const index = contentSections.findIndex((content) => content.id === newContent.id);

                if (index !== -1) {
                    contentSections[index] = newContent;
                }

                copyObject.content = contentSections;
                setBaseObject(copyObject)
                getCredits()

            }

        } catch (error) {
            if (error.data = "Insufficient credits") {
                setIsMissingCredits(true)
                setModalPage("credits")
            }
            console.error(error.message);
        }

        setSingleLoadId(null)
    }

    const getVoicePreview = async (voiceIdString) => {
        try {
            const resp = await apiCall(
                apiConfig.VOICE.GET_PREVIEW + `?voiceId=${voiceIdString}&voiceStyleId=0&languageId=0&isEL=true`,
                "get"
            );
            if (resp.status === 200) {
                return resp.data
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const [languages, setLanguages] = useState([])
    const getLanguages = async () => {
        try {
            const resp = await apiCall(
                apiConfig.VOICE.GET_LANGUAGES,
                "get"
            );
            if (resp.status === 200) {
                const formattedLang = resp.data.map((lang) => {
                    return ({
                        value: lang.id,
                        label: lang.name
                    })
                }).filter((lang) => lang.value != "en")
                setLanguages(formattedLang)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const [selectedVoiceUrl, setSelectedVoiceUrl] = useState(null)
    const [isVoicePreview, setIsVoicePreview] = useState(false)
    const [currentPlayingVoice, setCurrentPlayingVoice] = useState(null)
    const handlePreviewVoice = async (voiceIdString) => {

        await setSelectedVoiceUrl(null)
        await setIsVoicePreview(false)
        let previewUrl
        if (!voiceIdString)
            return



        if (!voiceIdString.startsWith("http")) {
            const findVoice = voices.find((voice) => voice.voiceAiValue == voiceIdString)
            if (currentPlayingVoice == findVoice.voiceId) {
                setCurrentPlayingVoice(null)
                return
            }

            previewUrl = await getVoicePreview(voiceIdString)
            setCurrentPlayingVoice(findVoice.voiceId)
        } else {
            const findMusic = music.find((voice) => voice.url == voiceIdString)

            if (currentPlayingVoice == findMusic.id) {
                setCurrentPlayingVoice(null)
                return
            }
            setCurrentPlayingVoice(findMusic.id)
            previewUrl = voiceIdString
        }



        await setSelectedVoiceUrl(previewUrl)
        await setIsVoicePreview(true)
    }

    const wizardSteps = [
        {
            title: "Create",
            component: < WizardAdCreate />,
            setModalPage: handleSetModalPage,
            baseObject: baseObject,
            setBaseObject: setBaseObject,
            selectedModalObject: selectedModalObject,
            setSelectedModalObject: setSelectedModalObject,
            regenerateContent: regenerateContent,
            handlePreviewVoice: handlePreviewVoice,
            voices: voices,
            music: music,
            singleLoadId: singleLoadId,
        },
        {
            title: "Languages",
            component: <WizardAdLanguage />,
            setModalPage: handleSetModalPage,
            baseObject: baseObject,
            setBaseObject: setBaseObject,
            languages: languages,
            voices: voices,
            music: music,
            baseObject: baseObject,
            setBaseObject: setBaseObject,
            //singleLoadId: singleLoadId
        },
        {
            title: "Targeting",
            component: <WizardAdTarget />,
            setModalPage: handleSetModalPage,
            advertTargeting: advertTargeting,
            categories: categories,
            baseObject: baseObject,
            setBaseObject: setBaseObject
        },
        {
            title: "Payment",
            component: <WizardAdPayment />,
            setModalPage: handleSetModalPage,
            baseObject: baseObject,
            setBaseObject: setBaseObject
        },
    ]


    const makeCalls = async () => {
        getAdvertTargeting()
        getCategories()
        getCredits()
        getVoices()
        getLanguages()
        getMusic()
        getProducts()
        getUserBalance()
    }

    useEffect(() => {
        makeCalls()
    }, [])



    const addVoice = () => {
        const copyObject = { ...baseObject };
        const contentSections = [...copyObject.content];

        const index = contentSections.findIndex((content) => content.id === selectedModalObject.id);

        if (index !== -1) {
            contentSections[index].voiceId = selectedModalObject.voiceId;
            contentSections[index].voiceAiValue = selectedModalObject.voiceAiValue
            contentSections[index].updateReq = true
        }

        copyObject.content = contentSections;
        setBaseObject(copyObject)
        toggleModal()
    }

    const addMusic = () => {
        const copyObject = { ...baseObject };
        const contentSections = [...copyObject.content];

        const index = contentSections.findIndex((content) => content.id === selectedModalObject.id);

        if (index !== -1) {
            contentSections[index].musicId = selectedModalObject.musicId;
            contentSections[index].updateReq = true
        }

        copyObject.content = contentSections;
        setBaseObject(copyObject)
        toggleModal()
    }

    const getSaveFunc = () => {
        const pageTitle = modalComp?.title

        switch (pageTitle) {
            case ("Add more Credits"):
                return renewCredits
            case ("Select Voice"):
                return addVoice
            case ("Select Music"):
                return addMusic
            default:
                break;
        }
    }

    useEffect(() => {
        if (!modalOpen) {
            setSelectedVoiceUrl(null)
            setIsVoicePreview(false)
            setCurrentPlayingVoice(null)
        }
    }, [modalOpen])

    return (
        <>
            <ReactPlayer url={selectedVoiceUrl}
                playing={isVoicePreview}
                controls={false}
                height='0px'
                width='0px'
                className="podcastPlayer"
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                onEnded={() => {
                    setSelectedVoiceUrl(null)
                    setIsVoicePreview(false)
                    setCurrentPlayingVoice(null)

                }}
            />
            <MyModal isOpen={modalOpen} toggle={toggleModal} title={modalComp?.title} buttonAction={getSaveFunc()} height={"50vh"} nextText={modalComp?.nextText} width={modalComp?.title != "Add more Credits" && "50%"} styleProp={{
                overflow: "auto"
            }}>
                {modalComp?.comp && React.cloneElement(modalComp.comp, { selectedModalObject, setSelectedModalObject, voices, music, handlePreviewVoice, products, setPurchaseObject, purchaseObject, userBalance, renewCredits, currentPlayingVoice })}
            </MyModal>
            <div className="content">
                <Card className="formStyle text-white">
                    <CardHeader>
                        <Col>
                            <Row className="justify-content-between">
                                <div>
                                    <CardTitle tag="h4">Create an Ad</CardTitle>
                                    <p style={{ margin: 0, fontSize: 14 }}>Create your own ads.</p>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: 5, cursor: "pointer" }} className="fadedKlaxonRed klaxonRed" onClick={() => setModalPage("credits")}>
                                    <i className="fa fa-info-circle" />
                                    <p style={{ margin: 0 }}>AI Generation Credits</p>
                                    <p style={{ margin: 0, backgroundColor: "rgba(243,59,48,255)", padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 25, color: "#fff" }}>{adCredits} of <span>{25}</span> remaining</p>
                                    <p style={{ margin: 0 }}>ADD</p>
                                </div>

                            </Row>
                        </Col>
                    </CardHeader>
                    <CardBody>
                        <AdWizard wizardSteps={wizardSteps} baseObject={baseObject} setBaseObject={setBaseObject} setIsMissingCredits={setIsMissingCredits} validateAdvertP1={validateAdvertP1} getCredits={getCredits} setModalPage={setModalPage} voices={voices} />
                    </CardBody>
                </Card>

            </div>
        </>
    );
}

export default AdsWithWizard;