/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
} from "reactstrap";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";
import ReactBSAlert from "react-bootstrap-sweetalert"
import { useNavigate } from "react-router-dom"
import Cookies from 'js-cookie';

import MyModal from "components/Shared/MyModal";
import ChangePassword from "./components/ChangePassword";

import CustomImageUpload from "../../components/CustomUpload/CustomImageUpload";
import ModalNoFoot from "../../components/Shared/ModalNoFoot";
import PaymentContainer from "./components/PaymentContainer";

function UserProfile() {

    const navigation = useNavigate()
    const modalWidth = window.innerWidth < 1050 ? "90%" : "500px" 

    const [userProfile, setUserProfile] = useState(getJWTToken().decoded.nameid)
    const [subscriptionTypes, setSubscriptionTypes] = useState([])
    const [profileData, setProfileData] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [oldPass, setOldPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [confirmNew, setConfirmNew] = useState("")
    const [userImage, setUserImage] = useState("")
    const [allowPassChange, setAllowPassChange] = useState(false)
    const [alert, setAlert] = useState(null)

    const getUser = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER.GET_SINGLE + `?id=${userProfile}`,
                "get"
            );
            //set as podcast data to maintain original
            setProfileData(resp.data)

        } catch (error) {
            console.error(error.message);
        }
    };

    const updateUser = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER.UPDATE,
                "put",
                profileData
            );
            if (resp.status === 200) {


                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "User Updated",
                    showConfirmButton: false,
                    timer: 2500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                });
                uploadUserImage()
                getUser()
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Something went wrong",
                    showConfirmButton: false,
                    timer: 2500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                });
            }

        } catch (error) {
            console.error(error.message);
        }
    };

    const uploadUserImage = async () => {
        const imageToUpload = userImage.split(",")[1]
        try {
            const resp = await apiCall(
                apiConfig.USER.SAVE_USER_AVATAR,
                "post", JSON.stringify(imageToUpload)
            );
            if (resp.status === 200) {
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const getUserImage = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER.GET_USER_AVATAR,
                "get"
            );
            if (resp.status === 200) {
                setUserImage(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const getSubscriptionTypes = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER_SUBSCRIPTION.GET_SUBSCRIPTIONS,
                "get"
            );
            if (resp.status === 200) {
                setSubscriptionTypes(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const handleChangePass = () => {
        setOldPass("")
        setNewPass("")
        setConfirmNew("")
        toggleModal()
    }

    const handleDeleteAccountClicked = () => {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="OK"
                cancelBtnText="Cancel"
                showCancel
                btnSize=""
            >
                Please contact support at info@klaxon.ai to deactivate your account.
            </ReactBSAlert>
        )
    }

    // const handleDeleteAccount = () => {
    //     const myStatus = 200

    //     if (myStatus === 200) {
    //         Cookies.remove('userData')
    //         navigation("/home")
    //     } else {
    //         Swal.fire({
    //             position: "center",
    //             icon: "error",
    //             title: "Something went wrong",
    //             showConfirmButton: false,
    //             timer: 2500,
    //         })
    //         hideAlert()
    //     }
    // }

    const hideAlert = () => {
        setAlert(null)
    }

    const updatePassword = async () => {
        if (allowPassChange === true) {
            try {
                const resp = await apiCall(
                    apiConfig.ACCOUNT.CHANGE_PASSWORD + `?userId=${userProfile}&oldPassword=${oldPass}&newPassword=${confirmNew}`,
                    "post"
                );
                if (resp.status === 200) {
                    toggleModal()

                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Password Changed",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    });
                } else {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Something went wrong",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    });
                }


            } catch (error) {
                console.error(error.message);
            }

        } else {
            Swal.fire({
                position: "center",
                icon: "error",
                title: "Something went wrong",
                showConfirmButton: false,
                timer: 2500,
                customClass: {
                    container: 'custom-swal-container',
                    popup: 'custom-swal-popup',
                    title: 'custom-swal-title',
                    icon: 'custom-swal-icon',
                },
            });
        }
    }

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const togglePaymentModal = () => {
        setPaymentModalOpen(!paymentModalOpen);
    };

    const completeSubscriptionUpdate = () => {
        getUser();
        setPaymentModalOpen(!paymentModalOpen);
    };

    useEffect(() => {
        getUser();
        getUserImage()
        getSubscriptionTypes()
    }, []);

    //console.log("profileData", profileData)

    return (
        <>
            {alert}
            <MyModal isOpen={modalOpen} toggle={toggleModal} title="Change Password" buttonAction={updatePassword} width={modalWidth}>
                <ChangePassword setAllowPassChange={setAllowPassChange}
                    oldPass={oldPass} setOldPass={setOldPass}
                    newPass={newPass} setNewPass={setNewPass}
                    confirmNew={confirmNew} setConfirmNew={setConfirmNew}
                />
            </MyModal>
            <ModalNoFoot isOpen={paymentModalOpen} toggle={togglePaymentModal} width="90%">
                <PaymentContainer toggle={togglePaymentModal} completeSubscriptionUpdate={completeSubscriptionUpdate}/>
            </ModalNoFoot>
            <div className="content">
                <Col>
                    <Col md="12">
                        <Card style={{ padding: 20, minHeight: "400px" }} className="formStyle text-white">
                            <Col>
                                <CardHeader>
                                    <Row className="justify-content-center">
                                        <CustomImageUpload avatar userImage={userImage} setUserImage={setUserImage} widthProp={100} heightProp={100} />
                                    </Row>
                                </CardHeader>
                            </Col>
                            <CardBody>

                                <p className="text-center">
                                    {profileData.firstName} {profileData.lastName}
                                </p>
                                <p className="text-center">
                                    Current Subscription: {subscriptionTypes?.find((e) => e.id === profileData.subscriptionTierId)?.name}
                                </p>
                                <p className="text-center">
                                    Minutes Used: {Math.round(profileData.minutesUsed)} / {subscriptionTypes?.find((e) => e.id === profileData.subscriptionTierId)?.minutes}
                                </p>
                                <p className="text-center">
                                    <button className="buttonStyleProfile buttonPrimary" onClick={togglePaymentModal}>Update Subscription</button>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ padding: 20, minHeight: "400px" }} className="formStyle text-white">
                            <CardHeader>
                                <h5 className="title">Edit Profile</h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>First Name</label>
                                                <Input
                                                    placeholder="First Name"
                                                    type="text"
                                                    value={profileData.firstName}
                                                    onChange={(e) => setProfileData({ ...profileData, firstName: e.target.value })}
                                                    className="placeholderWhite inputStyle"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Last Name</label>
                                                <Input
                                                    placeholder="Last Name"
                                                    type="text"
                                                    value={profileData.lastName}
                                                    onChange={(e) => setProfileData({ ...profileData, lastName: e.target.value })}
                                                    className="placeholderWhite inputStyle"
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Email</label>
                                                <Input
                                                    placeholder="Email"
                                                    type="email"
                                                    value={profileData.email}
                                                    onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
                                                    className="placeholderWhite inputStyle"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Phone Number</label>
                                                <Input
                                                    placeholder="Phone Number"
                                                    type="text"
                                                    value={profileData.phoneNumber != null ? profileData.phoneNumber : ""}
                                                    onChange={(e) => setProfileData({ ...profileData, phoneNumber: e.target.value })}
                                                    className="placeholderWhite inputStyle"
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col className="profileButton">
                                        <button className="buttonStyleProfile buttonPrimary" onClick={handleChangePass} type="submit">Change Password</button>
                                        <button className="buttonStyleProfile buttonSecondary buttonSpace" onClick={handleDeleteAccountClicked}>
                                            Deactivate Account
                                        </button>
                                    </Col>
                                    <Col className="text-right profileButton">
                                        <button className="buttonStyleProfile buttonPrimary" onClick={updateUser}>Save Changes</button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Col>
            </div>
        </>
    );
}

export default UserProfile;