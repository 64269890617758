import React, { useState, useEffect, useRef, useContext } from 'react';
import { FileUploader } from "react-drag-drop-files";

const UploadMusic = ({ history, fileURL, setFileURL, baseObject, setBaseObject }) => {
	const inputFile = useRef(null);
	const [file, setFile] = useState(null);

	useEffect(() => {
		if (file) {
			setFileURL(file);
		}
	}, [file, setFileURL, history]);

	const handleButtonClick = () => {
		inputFile.current.click();
	};

	const handleFileUpload = (e) => {
		//console.log(e.target.files);
		setFile(URL.createObjectURL(e.target.files[0]));
		//console.log(e.target)
	};

	const fileTypes = ["MP3", "WAV"]
	const handleFileChange = (uploadedFile) => {
		const previewURL = URL.createObjectURL(uploadedFile);
		setFileURL(previewURL);

		const reader = new FileReader();
		reader.onloadend = () => {
			const fileType = uploadedFile.name.split(".").pop();

			const base64String = reader.result.split(",")[1];
			setBaseObject({
				...baseObject,
				base64: base64String,
				fileType: fileType
			});
		};
		reader.readAsDataURL(uploadedFile);
	}

	useEffect(() => {
		if (baseObject?.url) {
			setFileURL(baseObject.url)
		}
	}, [baseObject])

	return (
		<div className='upload-audio'>
			
			<div style={{ backgroundColor: "" }}>
				<FileUploader handleChange={handleFileChange} name="file" types={fileTypes} classes="wideUpload" label="Click to upload or drag and drop" />
			</div>
			{/*<button className='upload-btn' onClick={handleButtonClick}>*/}
			{/*	Upload*/}
			{/*</button>*/}
			<input
				type='file'
				id='file'
				ref={inputFile}
				style={{ display: 'none' }}
				accept='audio/*'
				onChange={handleFileUpload}
			/>
		</div>
	);
};

export default UploadMusic;