import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";
import ReactBSAlert from "react-bootstrap-sweetalert"
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import ReactPlayer from "react-player";
import defaultImage from "../../assets/img/header.jpg"
import MyModal from "components/Shared/MyModal";
import CreatePodcast from "./components/CreatePodcast";


import "../../assets/css/custom-css.css"

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input

} from "reactstrap";

function ReviewPage() {
    const location = useLocation()
    const navigation = useNavigate()
    const [podcastEpisodeId, setPodcastEpisodeId] = useState(new URLSearchParams(location.search).get('episodeId'))
    const [podcastEpisodeReview, setPodcastEpisodeReview] = useState([])
    const [alert, setAlert] = useState(null)
    

    const getPodcastEpisodeReview = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.PODCAST_EPISODE_SECTIONS.GET_ALL}?podcastEpisodeId=${podcastEpisodeId}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setPodcastEpisodeReview(res.data)

                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }

    const handleSaveClicked = () => {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handleSaveSections()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, save!"
                cancelBtnText="Cancel"
                showCancel
                btnSize=""
            >
                You are about to update the sections.
            </ReactBSAlert>
        )
    }

    const handleSaveSections = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.PODCAST.UPDATE_SECTIONS}?userId=${getJWTToken().decoded.nameid}`;
        await axios
            .put(url, podcastEpisodeReview)
            .then((res) => {
                if (res.status === 200) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Sections updated",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                    navigation(`/admin/podcasts`)


                } else {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Something went wrong",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }

    const handleBackToPodcasts = () => {
        navigation("/admin/podcasts")
    }

    const hideAlert = () => {
        setAlert(null)
    }

    useEffect(() => {
        getPodcastEpisodeReview();
    }, []);
    

    return (
        <>
            <div className="content">
                {alert}
                <Row>
                    <Col md="12">
                        <Card style={{ boxShadow: "none" }} className="formStyle">
                            <CardHeader>
                                <Col>
                                    <Row>
                                        <Col><CardTitle tag="h4" className="text-white"><strong>Review Podcast</strong></CardTitle>
                                            <u style={{
                                                color: "#F33B30",
                                                textDecoration: "underline #F33B30",
                                                cursor: "pointer"
                                            }} onClick={handleBackToPodcasts}>Back to podcasts</u>
                                        </Col>
                                    </Row>

                                </Col>
                            </CardHeader>
                            <CardBody className="pb-4">
                                <Row className="d-flex justify-content-center">
                                    {podcastEpisodeReview.map((section, index) => {

                                        const updateSectionField = (e) => {
                                            const copySections = [...podcastEpisodeReview]
                                            copySections[index].content = e.target.value
                                            copySections[index].dateAmended = new Date().toISOString('O')
                                            setPodcastEpisodeReview(copySections)
                                        }


                                        return (

                                            <Col sm="8" className="pb-4 pt-4" key={index}>
                                                <p className="text-white"><strong>{section.name}</strong></p>
                                                <Input value={section.content} onChange={(e) => updateSectionField(e)} type="textarea" style={{ minHeight: "150px", paddingLeft: 20, paddingRight: 20 }} className="reviewInput inputStyle" />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </CardBody>
                            <CardFooter className="d-flex justify-content-center">
                                <Col sm="8">
                                    <Row className="d-flex justify-content-end">
                                        <button type="submit" className="buttonStyle buttonSecondary" onClick={handleBackToPodcasts} style={{ backgroundColor: "grey" }}>Back</button>
                                        <button className="ml-4 buttonStyle buttonPrimary" onClick={() => handleSaveClicked()} type="submit">Save</button>
                                    </Row>
                                </Col>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ReviewPage;
