/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2"

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";

import klaxonLogo from "assets/img/klaxonImages/klaxonFull.png"

import "assets/css/custom-css.css"
import axios from "axios";


function Verification() {


    const [confirmPassword, setConfirmPassword] = useState("")
    const [isFocused, setIsFocused] = useState("")
    const location = useLocation()
    const navigation = useNavigate();
    const [userEmail, setUserEmail] = useState(new URLSearchParams(location.search).get('email'))
    const [verificationCode, setVerificationCode] = useState("")
    const [alert, setAlert] = useState(null)

    

    const handleBlur = () => {
        setIsFocused("")
    }


    const createUser = async (e) => {
        e.preventDefault()
        setAlert(null)
        try {
            const resp = await axios.post(
                `${apiConfig.baseUrl}${apiConfig.USER.VERIFY_CODE}?email=${userEmail}&verificationCode=${verificationCode}`
            );
            if (resp.status === 200) {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Account Verified",
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                });
                setTimeout(() => navigation("/auth/login"), 2000)
            }
        } catch (error) {
            console.error(error.message);
            setAlert("Verification failed, please check your verification code and try again")
            
        }
    };

    const handleLoginNavigation = () => {
        navigation("/auth/login");
    }

    React.useEffect(() => {
        document.body.classList.toggle("register-page");
        return function cleanup() {
            document.body.classList.toggle("register-page");
        };
    });

    const handleNavigateHome = () => {
        navigation("/home")
    }

    useEffect(() => {
        if (alert !== null) {
            setAlert(null)
        }
    }, [verificationCode])
    
    return (
        <div className="register-page">
            <Container style={{
                position: "relative",
                zIndex: 2
            }}>
                <Row>
                    <Col className="ml-auto" lg="5" md="6">
                                <Form action="" className="form" method="">


                            <Card className="card-signup text-center p-4 formStyle" style={{ height: 600 }}>
                                <div style={{ color: "white", position: "absolute", top: "3%", right: "5%", justifyContent: "center", alignItems: "center", display: "flex", cursor: "pointer" }} onClick={handleNavigateHome}>
                                    <i className="nc-icon nc-minimal-left" style={{ marginRight: 10 }} />
                                    <p style={{ marginBottom: 0 }}>Back to site</p>
                                </div>
                            <CardHeader className="d-flex justify-content-center">

                                <img src={klaxonLogo} style={{ width: "80%" }} />

                            </CardHeader>
                                
                                <CardBody style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                    <p className="text-center text-white">{alert === null ? "Enter your verification code below" : alert}</p>
                                    <InputGroup style={{ width: "90%", justifyContent: "center", display: "flex" }} className={isFocused === "verify" ? "fakeHoverEffect" : ""}>
                                            
                                        <Input placeholder="Verification Code" type="text" onChange={(e) => setVerificationCode(e.target.value)} className="inputRemoveHover inputStyle placeholderWhite" onFocus={() => setIsFocused("verify")}
                                                onBlur={handleBlur} />
                                        </InputGroup>

                                    </CardBody>
                                    <CardFooter style={{ marginTop: 0, display: "flex", alignItems: "center", flexDirection: "column" }}>
                                    <button className="buttonStyle buttonPrimary" onClick={(e) => createUser(e)} style={{ width: "90%" }}>
                                            Verify
                                        </button>
                                    <p className="text-center text-white" style={{marginBottom: 0, marginTop: 16}}>Already have an account? <u onClick={handleLoginNavigation} style={{
                                            color: "white",
                                            textDecoration: "underline white",
                                            cursor: "pointer"
                                        }}>Login here</u></p>
                                        </CardFooter>
                                    

                        </Card>

                                </Form>
                    </Col>
                </Row>
            </Container>
            <div
                className=""
                style={{
                    backgroundImage: `url(${require("assets/img/bg/bg.jpg")})`,
                    position: "absolute",
                    zIndex: 1,
                    display: "block",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                    backgroundSize: "cover",

                }}
            />
        </div>
    );
}

export default Verification;
