/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";
import ReactBSAlert from "react-bootstrap-sweetalert"
import axios from "axios";
import { useNavigate } from "react-router-dom";

import ReactPlayer from "react-player";
import defaultImage from "../../assets/img/header.jpg"
import MyModal from "components/Shared/MyModal";
import CreateAd from "./components/CreateAd";
import TutorialModal from "../../components/Shared/TutorialModal";
import tutorialText from "config/tutorialText";



import "../../assets/css/custom-css.css"

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input,
    Spinner

} from "reactstrap";
import CreateMusic from "./components/CreateMusic";

function Music() {

    const modalWidth = window.innerWidth < 1050 ? "90%" : "1000px" 

    const navigation = useNavigate()
    const [searchResults, setSearchResults] = useState([])
    const [musicData, setMusicData] = useState([])
    const [musicTagData, setMusicTagData] = useState([])
    const [selectedMusic, setSelectedMusic] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [alert, setAlert] = useState(null)
    const [showTutorial, setShowTutorial] = useState(true);
    const [isLoading, setIsLoading] = useState(false)

    const [musicPreview, setMusicPreview] = useState(null)
    const [selectedMusicObject, setSelectedMusicObject] = useState(null)

    const [isPlaying, setIsPlaying] = useState(false)
    const playerRef = useRef(null)
    const [currentAudioElapsedFraction, setCurrentAudioElapsedFraction] = useState(0.0)

    const [missingFields, setMissingFields] = useState([])
    const [fileType, setFileType] = useState()

    const hideAlert = () => {
        setAlert(null)
    }


    const [baseObject, setBaseObject] = useState({
        id: 0,
        name: "",
        tags: [],
        userId: getJWTToken().decoded.nameid,
        base64: null,
        url: null,
        fileType: null,
        startTime: 0,
        endTime: 30,
        active: true
    })

   

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleSearchBar = (query) => {
        const originalSearch = musicData
        //console.log(query, originalSearch)
        const searchName = originalSearch.filter((e) => e.name.toLowerCase().includes(query.toLowerCase()))
        //const searchDescription = originalSearch.filter((e) => e.description.toLowerCase().includes(query.toLowerCase()))
        setSearchResults(Array.from(new Set([...searchName])))
    }

    const handleCreateMusic = () => {
        setMissingFields([])
        setSelectedMusic(baseObject)
        toggleModal()
    }

    const handleEditMusic = (id) => {
        setMissingFields([])
        setSelectedMusic(musicData.find((ad) => ad.id === id))
        toggleModal()
    }

    const handleDeleteMusicClicked = (id) => {
        setSelectedMusic(musicData.find((ad) => ad.id === id))
        const fastData = musicData.find((ad) => ad.id === id)

        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handleMusicDelete(id)}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, delete!"
                cancelBtnText="Cancel"
                showCancel
                btnSize=""
            >
                You are about to delete {fastData.name}.
            </ReactBSAlert>
        )
    }

    const handleMusicDelete = async (id) => {
        try {
            const resp = await apiCall(
                apiConfig.MUSIC.DELETE + `?id=${id}`,
                "delete"
            );
            if (resp.status === 200) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Music Deleted",
                    showConfirmButton: false,
                    timer: 2500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                })
                hideAlert()
                getMusic()
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Something went wrong",
                    showConfirmButton: false,
                    timer: 2500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                })
            }


        } catch (error) {
            console.error(error.message);
            hideAlert()
        }
    }

    const getMusic = async () => {
        setIsLoading(true)

        try {
            const resp = await apiCall(
                apiConfig.MUSIC.GET_ALL,
                "get"
            );
            if (resp.status === 200) {
                setIsLoading(false)
                setMusicData(resp.data)
                setSearchResults(resp.data)
            }

        } catch (error) {
            console.error(error.message);
            setIsLoading(false)
        }
    };

    const getMusicTag = async () => {
        setIsLoading(true)

        try {
            const resp = await apiCall(
                apiConfig.MUSIC_TAG.GET_ALL,
                "get"
            );
            if (resp.status === 200) {
                setIsLoading(false)
                setMusicTagData(resp.data.map((tag) => {
                    return ({
                        value: tag.id,
                        label: tag.name
                    })
                })
                )
            }

        } catch (error) {
            console.error(error.message);
            setIsLoading(false)
        }
    };

    const getMusicData = async () => {
        setIsLoading(true)

        await getMusic()
        await getMusicTag()

        setIsLoading(false)
    }

    const createEditMusic = async () => {
        if (!handleValidSubmit()) {
            return
        }

        if (selectedMusic.id === 0) {

            try {
                //console.log("add", selectedAd)
                const resp = await apiCall(
                    apiConfig.MUSIC.ADD,
                    "post", selectedMusic
                );
                if (resp.status === 200) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Music added",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                    toggleModal()
                    getMusic()
                } else {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Something went wrong",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                }


            } catch (error) {
                console.error(error.message);
            }
        } else {
            try {
                const resp = await apiCall(
                    apiConfig.MUSIC.UPDATE + `?id=${selectedMusic.id}`,
                    "put", selectedMusic
                );
                if (resp.status === 200) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Music Updated",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                    getMusic()
                    toggleModal()
                } else {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Something went wrong",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                }


            } catch (error) {
                console.error(error.message);
            }
        }
    };


    const convertTimeSpan = (timeString) => {
        const [timePart, milliseconds] = timeString.split('.');
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        let totalSeconds = hours * 3600 + minutes * 60 + seconds;

        if (milliseconds) {
            totalSeconds += parseFloat(`0.${milliseconds}`);
        }

        return totalSeconds;
    };

    const previewAdvert = (advert) => {
        setIsPlaying(true)
        setSelectedMusicObject(advert)
        setMusicPreview(advert.mP3FileUrl)
    }

    const handleTimes = (e) => {

        //console.log("Times")
        setCurrentAudioElapsedFraction(e.playedSeconds / convertTimeSpan(selectedMusicObject.duration))

        if (e.playedSeconds >= convertTimeSpan(selectedMusicObject.duration)) {
            setSelectedMusicObject(null)
            setMusicPreview(null)
            setIsPlaying(false)
        }
    } 

    const handleSelectedAdPlayPause = () => {
        setIsPlaying(!isPlaying)
    }

    const handleSelectedAdStop = () => {
        setSelectedMusicObject(null)
        setMusicPreview(null)
        setIsPlaying(false)
    }

    const handleSeek = (e) => {
        playerRef.current.seekTo(e.target.value)
    }

    const handleValidSubmit = () => {
        const missingInputs = []
        if (selectedMusic.name === "") missingInputs.push("name")

        if (!selectedMusic.tags.length > 0) missingInputs.push("tags")

        if (selectedMusic.base64 === "" && selectedMusic.url === "") missingInputs.push("audio")

        if (missingInputs.length > 0) {
            setMissingFields(missingInputs)
            return false
        }
        setMissingFields([])
        return true
    }

    //const userToken = getJWTToken().decoded.nameid
    //const superUser = [1, 2, 3, 4, 6]
    //const isSuperUser = superUser.includes(Number(userToken))
    const isSuperUser = true

    useEffect(() => {
        getMusicData();
    }, []);

    return (
        <>
        {
            isSuperUser?(
            <>
        { alert }
        < TutorialModal
                showTutorial = { showTutorial }
    pageName = 'ads'
    title = "Ads"
                            text={tutorialText.ADS}
    setShowTutorial = { setShowTutorial } />
                        <MyModal isOpen={modalOpen} toggle={toggleModal} title="Create Music" width={modalWidth} buttonAction={createEditMusic}>
                            <CreateMusic baseObject={selectedMusic} setBaseObject={setSelectedMusic} missingFields={missingFields} musicTagData={musicTagData} />
            </MyModal>
            <div className="content">

                <Row>
                    <Col md="12">
                        <ReactPlayer url={musicPreview}
                            playing={isPlaying}
                                        controls={false}
                                        ref={playerRef}
                                        onProgress={(e) => handleTimes(e)}
                            height='0px'
                            width='0px'
                            className="podcastPlayer"
                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        />
                                    <Card className="formStyle text-white">
                            <CardHeader>
                                <Col>
                                    <Row className="justify-content-between">
                                        <CardTitle tag="h4">Music</CardTitle>
                                                    <button className="buttonStyle buttonPrimary" onClick={handleCreateMusic}>Create Music</button>
                                    </Row>
                                                <Row sm="1" md="2" lg="4"><Input className="inputStyle placeholderWhite" type="text" placeholder="Search" onChange={(e) => handleSearchBar(e.target.value)} /></Row>
                                </Col>
                            </CardHeader>
                            <CardBody>
                                            <div className="tableOverflow">
                                                <Table className="table">
                                                    <thead className="text-primary">
                                                        <tr style={{ color: "grey" }}>
                                                            <th>Name</th>
                                                            <th>Tags</th>
                                                            
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {!isLoading ?
                                                            musicData.length > 0 ?
                                                                searchResults.map((e) => {
                                                                    const getTagNames = e.tags.map((tmpTag) => tmpTag.name)
                                                                    const formattedTags = getTagNames.join(", ")

                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{e.name}</td>
                                                                                <td>{formattedTags}</td>
                                                                                <td className="text-right">
                                                                                    <Button
                                                                                        className="btn-icon btn-neutral dropdownIgnore editButton"
                                                                                        style={{ color: "" }}
                                                                                        id="tooltip159182735"
                                                                                        size="sm"
                                                                                        type="button"
                                                                                        onClick={() => handleEditMusic(e.id)}
                                                                                    >
                                                                                        <i className="fa fa-edit" style={{ fontSize: 18 }} />
                                                                                    </Button>
                                                                                    <UncontrolledTooltip
                                                                                        delay={0}
                                                                                        target="tooltip159182735"
                                                                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                                                                    >
                                                                                        Edit
                                                                                    </UncontrolledTooltip>
                                                                                    <Button
                                                                                        className="btn-icon btn-neutral dropdownIgnore"
                                                                                        style={{ color: "#f33b30" }}
                                                                                        id="tooltip808966390"
                                                                                        size="sm"
                                                                                        type="button"
                                                                                        onClick={() => handleDeleteMusicClicked(e.id)}
                                                                                    >
                                                                                        <i className="fa fa-times" style={{ fontSize: 18 }} />
                                                                                    </Button>
                                                                                    <UncontrolledTooltip
                                                                                        delay={0}
                                                                                        target="tooltip808966390"
                                                                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                                                                    >
                                                                                        Delete
                                                                                    </UncontrolledTooltip>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })
                                                                : <tr>
                                                                    <td colSpan="8">
                                                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                                                                            <p>No music available</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            : <tr>
                                                                <td colSpan="8">
                                                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                                                                        <Spinner color="danger" />
                                                                    </div>
                                                                </td>
                                                            </tr>}

                                                    </tbody>
                                                </Table>
                                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
                ) : (
                        <div className="content">
                            <h2>You do not have permission to view this page</h2>
                        </div>
                )}</>
    );
}

export default Music;