import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";
import ReactBSAlert from "react-bootstrap-sweetalert"
import { useNavigate, useLocation } from "react-router-dom";
import MyModal from "components/Shared/MyModal";
import PodcastEditImage from "../../components/CustomUpload/PodcastEditImage";

import "../../assets/css/custom-css.css"

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input,
    FormGroup,
    Label

} from "reactstrap";

function ManageBusinesses() {
    const modalWidth = window.innerWidth < 1050 ? "90%" : "1000px"
    const location = useLocation()
    const navigation = useNavigate()
    const [modalOpen, setModalOpen] = useState(false)
    const [alert, setAlert] = useState(null)

    const toggleModal = () => {
        setModalOpen(!modalOpen)
    }

    const [clientData, setClientData] = useState([])
    const [selectedClient, setSelectedClient] = useState({})
    const getClientData = async () => {
        try {
            const resp = await apiCall(
                apiConfig.CLIENT.GET_ALL,
                "get"
            );
            if (resp.status === 200) {
                setClientData(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    }
    const [newClient, setNewClient] = useState({
        id: 0,
        name: "",
        description: "",
        monthlyCost: 0,
        monthlyMinutes: 0,
        image: "",
        slug: ""
    })
    const handleCreateBusiness = () => {
        setSelectedClient(newClient)
        toggleModal()
    }

    const handleEditClient = (id) => {
        const findItem = clientData.find((e) => e.id === id)
        setSelectedClient(findItem)
        toggleModal()
    }

    const isValidClient = (client) => {
        let missingFields = []

        if (client.slug === "") {
            missingFields.push("slug")
        }

        if (client.name === "") {
            missingFields.push("name")
        }


        return missingFields
    }

    const [clientMissingFields, setClientMissingFields] = useState([])
    const [invalidSocials, setInvalidSocials] = useState([])
    const createEditClient = async () => {

        const getValidClient = isValidClient(selectedClient)

        if (getValidClient.length > 0) {
            setClientMissingFields(getValidClient)
            return
        }

        const socialFields = ["websiteUrl", "facebookUrl", "instagramUrl", "linkedInUrl", "tikTokUrl", "twitterUrl", "youtubeUrl"]
        const invalidSocialFields = []
        for (let i = 0; i < socialFields.length; i++) {
            if (selectedClient[socialFields[i]] !== null && selectedClient[socialFields[i]] !== "" && selectedClient[socialFields[i]] !== undefined) {
                

                if (!selectedClient[socialFields[i]].startsWith("https")) {
                    invalidSocialFields.push(socialFields[i])
                }

            }
        }

        if (invalidSocialFields.length > 0) {
            setInvalidSocials(invalidSocialFields)
            return
        }

        if (selectedClient.id === 0) { //New
            try {
                const resp = await apiCall(
                    apiConfig.CLIENT.ADD,
                    "post", selectedClient
                );
                if (resp.status === 200) {
                    getClientData()
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Business added",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                    toggleModal()
                }

            } catch (error) {
                console.error(error.message);
            }
        } else { //Editing
            try {

                if (selectedClient.image !== "" && selectedClient.image !== null) {
                    if (selectedClient.image.startsWith("http")) {
                        selectedClient.image = ""
                    }
                }

                const resp = await apiCall(
                    apiConfig.CLIENT.UPDATE,
                    "put", selectedClient
                );
                if (resp.status === 200) {
                    getClientData()
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Business updated",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                    toggleModal()
                }

            } catch (error) {
                console.error(error.message);
            }
        }
    }

    const handleClientDelete = async (id) => {
        setAlert(null)
        try {
            const resp = await apiCall(
                apiConfig.CLIENT.DELETE + `?id=${id}`,
                "delete"
            );
            if (resp.status === 200) {
                getClientData()
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Business deleted",
                    showConfirmButton: false,
                    timer: 2500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                })
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const handleDeleteClientClicked = async (id) => {
        const findItem = clientData.find((e) => e.id === id)
        setSelectedClient(findItem)

        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handleClientDelete(id)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, delete!"
                cancelBtnText="Cancel"
                showCancel
                btnSize=""
            >
                You are about to delete {findItem.name}.
            </ReactBSAlert>
        )
    }

    useEffect(() => {
        getClientData()
    }, [])

    useEffect(() => {
        setClientMissingFields([])
    }, [selectedClient])



    return (
        <>
            {alert}
            <MyModal isOpen={modalOpen} toggle={toggleModal} title={selectedClient.id === 0 ? "Add Business" : "Edit Business"} width={modalWidth} buttonAction={createEditClient}>
                <Col>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="labelText">Name {clientMissingFields.includes("name") && <span style={{ color: "#F33B30" }}>*</span>}</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.name} onChange={(e) => setSelectedClient({ ...selectedClient, name: e.target.value })} />
                                {clientMissingFields.includes("name") && <span style={{ color: "#F33B30" }}>Name cannot be empty</span>}
                            </FormGroup>

                            <FormGroup>
                                <Label className="labelText">Description</Label>
                                <Input className="inputStyle placeholderWhite" type="textarea" value={selectedClient.description} onChange={(e) => setSelectedClient({ ...selectedClient, description: e.target.value })} />
                            </FormGroup>

                            <FormGroup>
                                <Label className="labelText">Slug {clientMissingFields.includes("slug") && <span style={{ color: "#F33B30" }}>*</span>}</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.slug} onChange={(e) => setSelectedClient({ ...selectedClient, slug: e.target.value })} />
                                {clientMissingFields.includes("slug") && <span style={{ color: "#F33B30" }}>Slug cannot be empty</span>}
                            </FormGroup>
                            {selectedClient.slug !== "" && <p className="labelText">klaxon.ai/company/{selectedClient.slug}</p> }
                        </Col>

                        <Col className="text-center">
                            <Label className="labelText">Client Image</Label>
                                <FormGroup>
                                    <PodcastEditImage currentData={selectedClient} setData={setSelectedClient} />
                                </FormGroup>
                        </Col>

                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="labelText">Email</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.email} onChange={(e) => setSelectedClient({ ...selectedClient, email: e.target.value })} placeholder="Email"/>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label className="labelText">Website {invalidSocials.includes("websiteUrl") && <span style={{ color: "#F33B30" }}>*</span>}</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.websiteUrl} onChange={(e) => setSelectedClient({ ...selectedClient, websiteUrl: e.target.value })} placeholder="Website" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="labelText">Facebook Url {invalidSocials.includes("facebookUrl") && <span style={{ color: "#F33B30" }}>*</span>}</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.facebookUrl} onChange={(e) => setSelectedClient({ ...selectedClient, facebookUrl: e.target.value })} placeholder="Facebook Url" />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label className="labelText">LinkedIn Url {invalidSocials.includes("linkedInUrl") && <span style={{ color: "#F33B30" }}>*</span>}</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.linkedInUrl} onChange={(e) => setSelectedClient({ ...selectedClient, linkedInUrl: e.target.value })} placeholder="LinkedIn Url" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="labelText">Instagram Url {invalidSocials.includes("instagramUrl") && <span style={{ color: "#F33B30" }}>*</span>}</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.instagramUrl} onChange={(e) => setSelectedClient({ ...selectedClient, instagramUrl: e.target.value })} placeholder="Instagram Url" />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label className="labelText">X/Twitter Url {invalidSocials.includes("twitterUrl") && <span style={{ color: "#F33B30" }}>*</span>}</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.twitterUrl} onChange={(e) => setSelectedClient({ ...selectedClient, twitterUrl: e.target.value })} placeholder="Twitter Url" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="labelText">TikTok Url {invalidSocials.includes("tikTokUrl") && <span style={{ color: "#F33B30" }}>*</span>}</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.tikTokUrl} onChange={(e) => setSelectedClient({ ...selectedClient, tikTokUrl: e.target.value })} placeholder="TikTok Url" />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label className="labelText">Youtube Url {invalidSocials.includes("youtubeUrl") && <span style={{ color: "#F33B30" }}>*</span>}</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.youtubeUrl} onChange={(e) => setSelectedClient({ ...selectedClient, youtubeUrl: e.target.value })} placeholder="Youtube Url" />
                            </FormGroup>
                        </Col>
                    </Row>

                    {invalidSocials.length > 0 &&
                        <Row>
                            <Col>
                                <span style={{ color: "#F33B30" }}>All websites must be a full valid Url eg: https://www.klaxon.ai</span>
                            </Col>
                        </Row>    
                    }

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="labelText">Monthly Cost</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.monthlyCost} onChange={(e) => setSelectedClient({ ...selectedClient, monthlyCost: e.target.value })} />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label className="labelText">Monthly Minutes</Label>
                                <Input className="inputStyle placeholderWhite" value={selectedClient.monthlyMinutes} onChange={(e) => setSelectedClient({ ...selectedClient, monthlyMinutes: e.target.value })} />
                            </FormGroup>
                        </Col>
                    </Row>

                </Col>
            </MyModal>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card className="formStyle text-white">
                            <CardHeader>
                                <Col>
                                    <Row className="justify-content-between">
                                        <CardTitle tag="h4">Manage Businesses</CardTitle>
                                        <button className="buttonStyle buttonPrimary" onClick={handleCreateBusiness}>Add Business</button>
                                    </Row>
                                    <Row sm="1" md="2" lg="4"><Input className="inputStyle placeholderWhite" type="text" placeholder="Search" onChange={(e) => handleSearchBar(e.target.value)} /></Row>
                                </Col>
                            </CardHeader>
                            <CardBody>
                                <div className="tableOverflow">
                                    <Table className="table">
                                        <thead className="text-primary">
                                            <tr style={{ color: "grey" }}>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Monthly Cost</th>
                                                <th>Monthly Minutes</th>
                                                <th>Minutes Used</th>
                                                <th>Next Renewal</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {clientData.length > 0 ?
                                                clientData.map((e) => {
                                                    return (
                                                        <tr>
                                                            <td>{e.name}</td>
                                                            <td>{e.description.length > 200 ? `${e.description.slice(0, 200)}...` : e.description}</td>
                                                            <td>&pound;{e.monthlyCost}</td>
                                                            <td>{e.monthlyMinutes}</td>
                                                            <td>{e.minutesUsed}</td>
                                                            <td>{new Date(e.minuteResetDate).toLocaleString('en-GB')}</td>
                                                            <td className="text-right" style={{minWidth: 100} }>
                                                                <Button
                                                                    className="btn-icon btn-neutral dropdownIgnore editButton"
                                                                    style={{ color: "" }}
                                                                    id="tooltip159182735"
                                                                    size="sm"
                                                                    type="button"
                                                                    onClick={() => handleEditClient(e.id)}
                                                                >
                                                                    <i className="fa fa-edit" style={{ fontSize: 18 }} />
                                                                </Button>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    target="tooltip159182735"
                                                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                                                >
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                                <Button
                                                                    className="btn-icon btn-neutral dropdownIgnore"
                                                                    style={{ color: "#f33b30" }}
                                                                    id="tooltip808966390"
                                                                    size="sm"
                                                                    type="button"
                                                                    onClick={() => handleDeleteClientClicked(e.id)}
                                                                >
                                                                    <i className="fa fa-times" style={{ fontSize: 18 }} />
                                                                </Button>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    target="tooltip808966390"
                                                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                                                >
                                                                    Delete
                                                                </UncontrolledTooltip>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : null
                                            }

                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ManageBusinesses;
