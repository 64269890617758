/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";


// reactstrap components
import {
    Button,
    ButtonGroup,
    Container,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input,
    InputGroup,
    Tag

} from "reactstrap";
function LibraryLight() {
    const location = useLocation()
    const getTopic = new URLSearchParams(location.search).get('topic')
    const [podcastData, setPodcastData] = useState([])
    const [trendingData, setTrendingData] = useState([])
    const [suggestedData, setSuggestedData] = useState([])
    const [topicData, setTopicData] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [searchBar, setSearchBar] = useState("")

    const [tagOption, setTagOption] = useState(getTopic ? getTopic : "all")
    const [selectedPodcast, setSelectedPodcast] = useState("")
    const [showTutorial, setShowTutorial] = useState(true);

    const scrollRef = useRef(null)
    const [canScrollLeft, setCanScrollLeft] = useState(false)
    const [canScrollRight, setCanScrollRight] = useState(true)
    const [lastScroll, setLastScroll] = useState({ direction: "left", distance: 0 })
    const [userSearchData, setUserSearchData] = useState([])
    const [userTopicData, setUserTopicData] = useState([])
    
    const navigation = useNavigate()
    const getPodcasts = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_BY_TOPIC}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setSearchResults(res.data)
                    setPodcastData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }


    const getTrending = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_TRENDING}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setTrendingData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }


    const getSuggested = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_SUGGESTED}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setSuggestedData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }

    const getTopics = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_SUGGESTED_TOPICS}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setTopicData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }

    const handleSearchBar = (query) => {
        setSearchBar(query)
        const originalSearch = podcastData
        const searchName = originalSearch.filter((e) => e.name.toLowerCase().includes(query.toLowerCase()))

        setSearchResults(searchName)

        // When topics come in, this should work
        //const searchTag = searchName.filter((e) => e.topic.includes(tagOption))
        //setSearchResults(searchTag)
        
    }

    const handleClearSearch = () => {
        setSearchResults(podcastData)
        setSearchBar("")
    }

    const handleSelectPodcast = (podcastId) => {
        navigation(`/player?podcastId=${podcastId}`)
    }


    useEffect(() => {
        getPodcasts();
        getTrending();
        getSuggested();
        getTopics();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft -= window.innerWidth / 4
        }

        setLastScroll(prevState => ({
            ...prevState,
            direction: "left",
            distance: scrollRef.current.scrollLeft
        }));
        //if (scrollRef.current.scrollLeft > (window.innerWidth / 4) * podcastData.length) {
        //    setCanScrollLeft(true)
        //} else {
        //    setCanScrollLeft(false)
        //}

        if (scrollRef.current.scrollLeft === 0) {
            setCanScrollLeft(false)
        }
    }

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += window.innerWidth / 4
        }

        if (lastScroll.direction === "right") {
            if (lastScroll.distance === scrollRef.current.scrollLeft) {
                scrollRef.current.scrollLeft = 0
            }
        }

        setLastScroll(prevState => ({
            ...prevState,
            direction: "right",
            distance: scrollRef.current.scrollLeft
        }));
        
        if (scrollRef.current.scrollLeft !== 0) {
            setCanScrollLeft(true)
        } else {
            setCanScrollLeft(false)
        }
    }

    const getUserSearch = async (query) => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.SEARCH_PODCASTS}?search=${query}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setUserSearchData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }

    useEffect(() => {
        const userSearch = new URLSearchParams(location.search).get('search')
        if (userSearch !== null) {
            getUserSearch(userSearch)
        } else {
            setSearchBar([])
        }
    }, [location])

    const formatDate = (isoDate) => {
        const date = new Date(isoDate)
        return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`
    }

    const formatDuration = (duration) => {
        const durationParts = duration.split(':').map(part => parseInt(part));
        const hours = durationParts.length > 2 ? durationParts[0] : 0;
        const minutes = durationParts.length > 1 ? durationParts[durationParts.length - 2] : 0;
        const seconds = durationParts[durationParts.length - 1];
        let formattedDuration = '';

        if (hours > 0) {
            formattedDuration += hours.toString().padStart(2, '0') + ':';
        }

        formattedDuration += minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');

        return formattedDuration;
    }
    const getUserTopicData = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_BY_TOPIC}?topic=${tagOption}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setUserTopicData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }


    useEffect(() => {
        if (tagOption !== "all") {
            getUserTopicData()
        } else {
            setUserTopicData([])
        }
    }, [tagOption])

    return (
        <>
            {/* <TutorialModal
                showTutorial={showTutorial}
                pageName='home'
                title="Home"
                text={tutorialText.HOME}
                setShowTutorial={setShowTutorial} /> */}
            <div className="content" style={{ marginTop: 156 }}>
                <Col>

                    <Row className="pt-4 pb-4">
                        <Col sm="12" md="12" lg="6" xl="2" onClick={() => setTagOption("all")} className="topicDiv" style={{ cursor: "pointer" }}>
                            <p className={`lightmodeTopic ${tagOption === "all" ? "topicItemActiveLight" : ""}`}>All</p>
                        </Col>
                        {topicData?.length > 0 && (
                            topicData.map((topic) => {
                                return (
                                    <Col sm="12" md="12" lg="6" xl="2" onClick={() => setTagOption(topic)} className="topicDiv" style={{cursor: "pointer"}}>
                                        <p className={`text-center lightmodeTopic ${tagOption === topic ? "topicItemActiveLight" : ""}`}>{topic}</p>
                                    </Col>
                                )
                            })
                        )}
                    </Row>
                    {userTopicData.length > 0 ?
                        <Row className="pt-4 pb-4">
                            <Col>
                                <Row style={{ paddingLeft: "2%", paddingRight: "2%" }} className="subText"><p>Your Chosen Topic</p></Row>
                                <Row style={{ paddingLeft: "2%", paddingRight: "2%" }}><h3>Find something unique</h3></Row>
                                <Row style={{}}>
                                    <div className="scrollingItems">
                                        {userTopicData.map((e) => {
                                            return <div className="lightmodeCard" onClick={() => handleSelectPodcast(e.id)} >
                                                <img src={e.image ? e.image : null} className="lightmodeCardImage" />
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <p style={{ fontWeight: "bold", marginLeft: 5, marginTop: 5 }}>{e.name}</p>
                                                    <p style={{ fontWeight: "bold", marginRight: 5, marginTop: 5 }}>{formatDate(e.releaseDate)}</p>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <p style={{ fontWeight: "bold", marginLeft: 5, marginBottom: 5 }}>Episode {e.episodeNumber}</p>
                                                    <p style={{ fontWeight: "bold", marginRight: 5, marginBottom: 5 }}>Length: {formatDuration(e.duration)}</p>
                                                </div>
                                            </div>
                                        })}

                                    </div>
                                </Row>
                            </Col>
                        </Row> : null    
                    }

                    {userSearchData.length > 0 ?
                        userSearchData.length > 4 ?
                            <Col>
                                <Row style={{ paddingLeft: "2%", paddingRight: "2%" }} className="subText"><p>Your Search</p></Row>
                                <Row style={{ paddingLeft: "2%", paddingRight: "2%" }} className="justify-content-between"><h3>Find what you're looking for</h3><div className="scrollButtonContainer"><i className="nc-icon nc-minimal-left scrollButton" style={{ color: canScrollLeft ? "white" : "black", backgroundColor: canScrollLeft ? "black" : "white", cursor: canScrollLeft && "pointer" }} onClick={canScrollLeft ? scrollLeft : null} /><i className="nc-icon nc-minimal-right scrollButton" style={{ color: canScrollRight ? "white" : "black", backgroundColor: canScrollRight ? "black" : "white", cursor: canScrollRight && "pointer" }} onClick={canScrollRight ? scrollRight : null} /></div></Row>
                                <Row style={{ paddingLeft: "2%", paddingRight: "2%" }}>
                                    <div className="scrollingItemsScroll" ref={scrollRef}>
                                        {userSearchData.map((e) => {
                                                return <div className="lightmodeCardScroll" onClick={() => handleSelectPodcast(e.id)}>
                                                    <img src={e.image ? e.image : null} className="lightmodeCardImage" />
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <p style={{ fontWeight: "bold", marginLeft: 5, marginTop: 5 }}>{e.name}</p>
                                                        <p style={{ fontWeight: "bold", marginRight: 5, marginTop: 5 }}>{formatDate(e.releaseDate)}</p>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <p style={{ fontWeight: "bold", marginLeft: 5, marginBottom: 5 }}>Episode {e.episodeNumber}</p>
                                                        <p style={{ fontWeight: "bold", marginRight: 5, marginBottom: 5 }}>Length: {formatDuration(e.duration)}</p>
                                                    </div>
                                                </div>
                                            })}

                                    </div>
                                </Row>
                            </Col>
                        :
                        <Row className="pt-4 pb-4">
                        <Col>
                            <Row style={{ paddingLeft: "2%", paddingRight: "2%" }} className="subText"><p>Your Search</p></Row>
                            <Row style={{ paddingLeft: "2%", paddingRight: "2%" }}><h3>Find what you're looking for</h3></Row>
                            <Row style={{}}>
                                <div className="scrollingItems">
                                    {userSearchData.map((e) => {
                                        return <div className="lightmodeCard" onClick={() => handleSelectPodcast(e.id)} >
                                            <img src={e.image ? e.image : null} className="lightmodeCardImage" />
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <p style={{ fontWeight: "bold", marginLeft: 5, marginTop: 5 }}>{e.name}</p>
                                                <p style={{ fontWeight: "bold", marginRight: 5, marginTop: 5 }}>{formatDate(e.releaseDate)}</p>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <p style={{ fontWeight: "bold", marginLeft: 5, marginBottom: 5 }}>Episode {e.episodeNumber}</p>
                                                <p style={{ fontWeight: "bold", marginRight: 5, marginBottom: 5 }}>Length: {formatDuration(e.duration)}</p>
                                            </div>
                                        </div>
                                    })}

                                </div>
                            </Row>
                        </Col>
                    </Row> : null}
                    <Row className="pt-4 pb-4">
                        <Col>
                            <Row style={{ paddingLeft: "2%", paddingRight: "2%" }} className="subText"><p>Klaxon Suggested</p></Row>
                            <Row style={{ paddingLeft: "2%", paddingRight: "2%" }}><h3>Suggested For You</h3></Row>
                            <Row style={{  }}>
                                <div className="scrollingItems">
                                    {suggestedData.length > 0 ?
                                        suggestedData.map((e) => {
                                            return <div className="lightmodeCard" onClick={() => handleSelectPodcast(e.id) } >
                                                <img src={e.image ? e.image : null} className="lightmodeCardImage"/>
                                                <div style={{display: "flex", justifyContent: "space-between"} }>
                                                    <p style={{ fontWeight: "bold", marginLeft: 5, marginTop: 5 }}>{e.name}</p>
                                                    <p style={{ fontWeight: "bold", marginRight: 5, marginTop: 5 }}>{formatDate(e.releaseDate)}</p>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <p style={{ fontWeight: "bold", marginLeft: 5, marginBottom: 5 }}>Episode {e.episodeNumber}</p>
                                                    <p style={{ fontWeight: "bold", marginRight: 5, marginBottom: 5 }}>Length: {formatDuration(e.duration)}</p>
                                                </div>
                                            </div>
                                        })
                                        : null}
                                    
                                </div>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="altBackground pt-4 pb-4">
                            <Row><Col className="text-center"><h2>What's Popular?</h2></Col></Row>
                            <Row style={{ paddingLeft: "2%", paddingRight: "2%" }} className="subText"><p>Currently Popular</p></Row>
                            <Row style={{ paddingLeft: "2%", paddingRight: "2%" }}><h3>Trending</h3></Row>
                            <Row style={{ }}>
                                <div className="scrollingItems">
                                    {trendingData.length > 0 ?
                                        trendingData.map((e) => {
                                            return <div className="lightmodeCardAlt" onClick={() => handleSelectPodcast(e.id)} >
                                                <img src={e.image ? e.image : null} className="lightmodeCardImage" />
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <p style={{ fontWeight: "bold", marginLeft: 5, marginTop: 5 }}>{e.name}</p>
                                                    <p style={{ fontWeight: "bold", marginRight: 5, marginTop: 5 }}>{formatDate(e.releaseDate)}</p>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <p style={{ fontWeight: "bold", marginLeft: 5, marginBottom: 5 }}>Episode {e.episodeNumber}</p>
                                                    <p style={{ fontWeight: "bold", marginRight: 5, marginBottom: 5 }}>Length: {formatDuration(e.duration)}</p>
                                                </div>
                                            </div>
                                        })
                                        : null}
                                </div>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="pt-4 pb-4">
                        <Col>
                            <Row style={{ paddingLeft: "2%", paddingRight: "2%" }} className="subText"><p>Everything</p></Row>
                            <Row style={{ paddingLeft: "2%", paddingRight: "2%" }} className=""><h3>All Podcasts</h3></Row>
                            <Row style={{  }}>
                                <div className="scrollingItemsDrop">
                                    {podcastData.length > 0 ?
                                        podcastData.map((e) => {
                                            return <div className="lightmodeCard" onClick={() => handleSelectPodcast(e.id)} >
                                                <img src={e.image ? e.image : null} className="lightmodeCardImage" />
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <p style={{ fontWeight: "bold", marginLeft: 5, marginTop: 5 }}>{e.name}</p>
                                                    <p style={{ fontWeight: "bold", marginRight: 5, marginTop: 5 }}>{formatDate(e.releaseDate)}</p>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <p style={{ fontWeight: "bold", marginLeft: 5, marginBottom: 5 }}>Episode {e.episodeNumber}</p>
                                                    <p style={{ fontWeight: "bold", marginRight: 5, marginBottom: 5 }}>Length: {formatDuration(e.duration)}</p>
                                                </div>
                                            </div>
                                        })
                                        : null}
                                    
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                    
               
                
            </div>
        </>
    );
}

export default LibraryLight;
