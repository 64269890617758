import React, { useState, useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';

const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

const isBase64 = (str) => {
    try {
        // Regex to check if the string is base64
        return /^([A-Za-z0-9+/=]{2,4})+$/.test(str);
    } catch {
        return false;
    }
};

const WizardPlayer = ({ fileURL, changeScheme }) => {
    const wavesurferRef = useRef(null); // Container ref for WaveSurfer
    const timelineRef = useRef(null); // Container ref for timeline
    const wsRef = useRef(null); // Reference to the WaveSurfer instance

    const [playing, setPlaying] = useState(false);
    const [duration, setDuration] = useState(0); 
    const [currentTime, setCurrentTime] = useState(0); 


    useEffect(() => {
        // Initialize a new WaveSurfer instance for each component
        const ws = WaveSurfer.create({
            container: wavesurferRef.current,
            scrollParent: true,
            autoCenter: true,
            cursorColor: 'violet',
            loopSelection: true,
            waveColor: '#211027',
            progressColor: '#69207F',
            responsive: true,
            plugins: [
                RegionsPlugin.create(),
                TimelinePlugin.create({ container: timelineRef.current }),
            ],
        });

        wsRef.current = ws; // Set the ref to the current instance

        ws.on('ready', () => {
            setDuration(ws.getDuration()); 
        });

        ws.on('audioprocess', () => {
            setCurrentTime(ws.getCurrentTime()); 
        });

        ws.on('play', () => setPlaying(true));
        ws.on('pause', () => setPlaying(false));

        // Clean up function to destroy WaveSurfer when component unmounts
        return () => {
            if (ws) ws.destroy();
        };
    }, []);

    useEffect(() => {
        const ws = wsRef.current;

        // Check if the fileURL is a base64 string
        if (fileURL && ws) {
            if (isBase64(fileURL)) {
                // Convert base64 to Blob and create an object URL
                const byteCharacters = atob(fileURL);
                const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'audio/mp3' }); // Adjust the MIME type if necessary
                const blobUrl = URL.createObjectURL(blob);

                ws.load(blobUrl);

                // Cleanup object URL to prevent memory leaks
                return () => URL.revokeObjectURL(blobUrl);
            } else {
                ws.load(fileURL);
            }
        }
    }, [fileURL]);

    const togglePlayPause = () => {
        const ws = wsRef.current;
        if (ws.isPlaying()) {
            ws.pause();
        } else {

            ws.play();
        }
    };

    return (
        <section className="waveform-container" style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "" }} className="labelText">
                <p style={{ margin: 0 }}>
                    {formatTime(currentTime)}
                </p>
                <p style={{ margin: 0 }}>
                    {formatTime(duration)} 
                </p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "90%" }}>
                    <div ref={wavesurferRef} className="waveform labelText" />
                    <div ref={timelineRef} className="wave-timeline labelText" />
                </div>
                <div style={{ width: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <i className={`${!playing ? "fa fa-play" : "fa fa-pause"} labelText`} style={{ fontSize: 26, cursor: "pointer" }} onClick={togglePlayPause} />
                </div>
            </div>
        </section>
    );
};

export default WizardPlayer;
