/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";

import defaultImage from "../../assets/img/header.jpg";
import MyModal from "components/Shared/MyModal";
import CreatePodcast from "./components/CreatePodcast";

import AboutImage from "assets/img/monks.jpg";

import { useNavigate } from "react-router-dom"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Input,
} from "reactstrap";

function AboutUs() {

    const navigation = useNavigate()

    const handleNavigatePricing = () => {
        navigation("/pricing")
    }

    const handleNavigateContact = () => {
        navigation("/contact-us")
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

  return (
    <>
      

          <div className="content" style={{ marginTop: 78, paddingLeft: "12%", paddingRight: "12%" }}>
              <Col>
                  <Row>
                      <Col md="12" className="text-white">
                          <Col>
                              <Row className="justify-content-center">
                                  <h3 className="text-center" style={{ color: "white", marginTop: 20 }}><b>About us</b></h3>
                              </Row>

                          </Col>

                          <CardBody>

                              <Row>

                                  <Col>
                                      <p>
                                          We love telling stories. And we love that technology makes
                                          it easier for everyone to tell stories.
                                      </p>
                                      <p>
                                          As former journalists our three co-founders are all too
                                          aware that in the past the ability to produce and
                                          disseminate news and information was in the hands of those
                                          with the skills and deep pockets to do so.
                                      </p>
                                      <p>
                                          Monks in the Middle Ages created a global
                                          multi-million-pound industry handwriting copies of the bible
                                          and manuscripts for the rich and powerful.
                                      </p>
                                      <p>
                                          They were replaced by the Gutenberg printing press which
                                          lasted a few hundred years until radio and television and
                                          the internet arrived last century.
                                      </p>
                                      <p>
                                          Now we have people producing video on their smartphones and
                                          reaching millions every day. But podcasting remains beyond
                                          the reach of most people. There are still huge barriers
                                          preventing most people around the world from creating their
                                          own podcasts – time, knowledge, skills and access to
                                          expensive recording equipment studios – are some.{" "}
                                      </p>
                                      <p>
                                          Language is another barrier. With 80% of the world not
                                          speaking English translating words, written or spoken, is a
                                          hugely costly hurdle to anyone trying to distribute news and
                                          information globally.
                                      </p>
                                      <p>
                                          We believe advances in artificial intelligence now mean the
                                          barriers are gone. And we are on a mission.
                                      </p>
                                      <p>
                                          A mission to democratise podcasting by making it accessible
                                          to anyone with access to the internet, anywhere in the
                                          world, while sharing the financial rewards with our users.
                                      </p>
                                  </Col>

                                  <Col
                                      md="6"
                                      style={{
                                          display: "flex",
                                          alignContent: "center",
                                          justifyContent: "center",
                                      }}
                                  >
                                      <img
                                          src={AboutImage}
                                          style={{
                                              width: "100%",
                                              height: "80%",
                                              objectFit: "cover",
                                          }}
                                      />
                                  </Col>

                              </Row>
                              
                          </CardBody>


                      </Col>
                  </Row>
              </Col>
          </div>
    </>
  );
}

export default AboutUs;
