import { useEffect, useState } from "react";
import VoiceCard from "../BusinessWizardSteps/VoiceCard";

const AdVoice = ({ setSelectedModalObject, selectedModalObject, voices, handlePreviewVoice, currentPlayingVoice }) => {

    const [selectedVoice, setSelectedVoice] = useState(null)
    useEffect(() => {
        const isValidVoice = voices.find(voice => voice?.voiceId == selectedModalObject.voiceId)?.voiceName

        if (isValidVoice) {
            setSelectedVoice(isValidVoice)
        } else {
            setSelectedVoice(null)
        }
    }, [selectedModalObject.voiceId])


    //console.log(voices)

    return (
        <div className="formStyle pt-2">
            <p className="pl-4">Choose from our library of voices.</p>
            <div style={{ display: "flex", flexWrap: "wrap", gap: 10, justifyContent: "center" } }>
                {voices.map((e, index) => {
                    return (
                        <div style={{width: "47%", backgroundColor: ""} }>
                            <VoiceCard voiceData={e} index={index} colorSchemeTheme={"dark"} selectedVoice={selectedVoice && selectedVoice} isAdVoice={true} setSelectedModalObject={setSelectedModalObject} selectedModalObject={selectedModalObject} showId={false} handlePreviewVoice={handlePreviewVoice} currentPlayingVoice={currentPlayingVoice} />
                        </div>
                    )
                }) }
            </div>
        </div>
    )
}

export default AdVoice