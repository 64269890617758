/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

function CustomImageUpload(props) {
  const [fileState, setFileState] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
      props.userImage ? props.userImage : defaultAvatar
  );
  const fileInput = React.useRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
        setFileState(file);
        props.setUserImage(reader.result)
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      }
      
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // fileState is the file/image uploaded
    // in this function you can save the image (fileState) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    fileInput.current.value = null;
    setFileState(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
  };

    const updateUserImage = () => {
        setImagePreviewUrl(props.userImage)
    }
    
    useEffect(() => {
            updateUserImage()
    }, [props.userImage])
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
          <div className={"thumbnail" + (props.avatar ? " img-circle" : "")} onClick={() => handleClick()}>
              <img src={imagePreviewUrl ? imagePreviewUrl : defaultImage} alt="..." style={{ width: props.widthProp, height: props.heightProp }} />
      </div>
      
    </div>
  );
}

CustomImageUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default CustomImageUpload;
