import { useState, useEffect } from "react"
import {
    Row, Col, Badge, Card, UncontrolledTooltip, Tooltip
} from "reactstrap"

function VoiceCard({ voiceData, index, handlePreviewVoice = null, handleSelectVoice = null, showId = true, colorSchemeTheme = "dark", selectedVoice = null, isAdVoice = false, isAdMusic = false, setSelectedModalObject, selectedModalObject, currentPlayingVoice }) {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const [colorScheme, setColorScheme] = useState({
        backgroundColor: "#404040",
        color: "#FFF"
    })

    useEffect(() => {
        if (colorSchemeTheme !== "dark") {
            setColorScheme({
                ...colorScheme,
                backgroundColor: "#F0F0F0",
                color: "#000"
            })
        }
    }, [])

    const handleCopyToClipboard = (voiceId) => {
        if (!navigator.clipboard) {
            return
        }

        navigator.clipboard.writeText(voiceId)
            .then(() => {
                setIsTooltipOpen(true)
                setTimeout(() => {
                    setIsTooltipOpen(false)
                }, 1500)
            })
            .catch(err => {
                console.error("Something went wrong", err)
            })
    }

    const [voiceTags, setVoiceTags] = useState([])
    const formatTags = () => {

        let labelArrObject
        const colorCodes = {
            3: "#bf7fff",
            5: "#aec6cf",
            2: "#ff7f7f",
            1: "#ffa94d",
            4: "#7fc7ff"
        }
        if (isAdVoice) {

            const labelArr = voiceData.labels;
            if (labelArr && labelArr.length > 0) {
                labelArrObject = labelArr.map((tag) => {
                    return {
                        label: tag.name,
                        value: tag.name,
                        textColor: colorCodes[tag.typeId]
                    }
                })
            } else {
                return null
            }
        }

        if (isAdMusic) {
            const labelArr = voiceData.tags

            if (labelArr && labelArr.length > 0) {
                const colorValues = Object.values(colorCodes)
                labelArrObject = labelArr.map((tag, index) => {
                    return {
                        label: tag.name,
                        value: tag.name,
                        textColor: colorValues[index % colorValues.length]
                    }
                })

            } else {
                return null
            }
        }




        setVoiceTags(labelArrObject)

    }

    useEffect(() => {
        if (voiceData && (voiceData?.labels || voiceData?.tags)) formatTags()
    }, [voiceData])

    const [voiceName, setVoiceName] = useState(null)
    useEffect(() => {
        if (voiceData?.voiceName) {
            setVoiceName(voiceData.voiceName)
        } else if (voiceData.name) {
            setVoiceName(voiceData.name)
        }
    }, [voiceData])


    return (
        <Card className="" style={{ backgroundColor: colorScheme.backgroundColor, color: colorScheme.color, border: !showId && selectedVoice !== null ? selectedVoice === voiceName ? "2px solid #F33B30" : "2px solid transparent" : null }}>
            <Col
                onClick={
                    (handleSelectVoice)
                        ? () => handleSelectVoice(voiceData)
                        : (!isAdMusic && isAdVoice)
                            ? () => setSelectedModalObject({ ...selectedModalObject, voiceId: voiceData.voiceId, voiceAiValue: voiceData.voiceAiValue })
                            : (isAdMusic && !isAdVoice)
                                ? () => setSelectedModalObject({ ...selectedModalObject, musicId: voiceData.id })
                                : undefined
                }
            >
                <Row className="justify-content-between pl-3 pr-3 pt-2">
                    <h3 style={{ margin: 0 }}>{isAdMusic ? voiceData.name : voiceData.voiceName}</h3>

                    {showId &&
                        <>
                            <Badge
                                id={`voice${index}`}
                                pill
                                style={{ color: colorScheme.color, cursor: "pointer" }}
                                onClick={() => handleCopyToClipboard(voiceData.voiceId)}
                            >ID</Badge>
                            <UncontrolledTooltip
                                delay={0}
                                target={`voice${index}`}
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                {voiceData.voiceId}
                            </UncontrolledTooltip>
                            <Tooltip
                                isOpen={isTooltipOpen}
                                target={`voice${index}`}
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                Copied to clipboard!
                            </Tooltip>
                        </>
                    }

                </Row>
                <div className="pb-2 pt-2" style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                    {voiceTags.map((e) => {
                        return (
                            <Badge
                                pill
                                style={{ color: "#fff", backgroundColor: e.textColor, border: e.textColor, margin: 0 }}
                            >{e.value}</Badge>
                        )
                    })}
                </div>
                <Row>
                    <button style={{ width: "100%", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, border: "none", minHeight: 50, borderTop: `1px solid ${colorScheme.color}`, display: "flex", justifyContent: "center", alignItems: "center", gap: 15, backgroundColor: colorScheme.backgroundColor, color: colorScheme.color, fontSize: 20 }}
                        onClick={() => handlePreviewVoice(isAdVoice ? voiceData.voiceAiValue : isAdMusic ? voiceData.url : voiceData.voicePreview)}
                    >
                        {!isAdMusic ?
                            currentPlayingVoice == voiceData.voiceId ? 
                                <i className="fa-solid fa-play" style={{ fontSize: 18 }} /> :
                                <i className="fa-solid fa-volume-high" style={{ fontSize: 18 }} /> 
                             : 
                        currentPlayingVoice == voiceData.id ? 
                        <i className="fa-solid fa-play" style={{ fontSize: 18 }} /> :
                        <i className="fa-solid fa-volume-high" style={{ fontSize: 18 }} />
                            
                            

                            
                        }
                        Preview</button>
                </Row>
            </Col>
        </Card>
    )
}

export default VoiceCard