/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";


// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input

} from "reactstrap";

function CookiePolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>

            <div className="content" style={{ marginTop: 78, paddingLeft: "5vh", paddingRight: "5vh" }}>
                <CardBody>
                    <Col className="text-white">
                        <h1>Cookie Policy</h1>
                        <h2 style={{textTransform: "uppercase"} }>Information about our use of cookies</h2>

                        <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</p>
                        <p className="mb-4 pb-4">We use the following cookies:</p>

                        

                        <table style={{ borderCollapse: "collapse", width: "90%", margin: "auto" }}>
                            <thead>
                                <tr style={{ border: "1px solid white" }}>
                                    <th style={{ paddingLeft: 10, paddingRight: 10}}><strong>Cookie Title / Name</strong></th>
                                    <th style={{ borderLeft: "1px solid white", paddingLeft: 10, paddingRight: 10 }}><strong>Purpose</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: "1px solid white" }}>
                                    <td style={{ paddingLeft: 10, paddingRight: 10 }}><strong>Strictly necessary cookies.</strong></td>
                                    <td style={{ borderLeft: "1px solid white", paddingLeft: 10, paddingRight: 10 }}>These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.</td>
                                </tr>
                                <tr style={{ border: "1px solid white" }}>
                                    <td style={{ paddingLeft: 10, paddingRight: 10 }}><strong>Analytical/ performance cookies.</strong></td>
                                    <td style={{ borderLeft: "1px solid white", paddingLeft: 10, paddingRight: 10 }}>They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</td>
                                </tr>
                                <tr style={{ border: "1px solid white" }}>
                                    <td style={{ paddingLeft: 10, paddingRight: 10 }}><strong>Functionality cookies.</strong></td>
                                    <td style={{ borderLeft: "1px solid white", paddingLeft: 10, paddingRight: 10 }}>These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</td>
                                </tr>
                                <tr style={{ border: "1px solid white" }}>
                                    <td style={{ paddingLeft: 10, paddingRight: 10 }}><strong>Targeting cookies.</strong></td>
                                    <td style={{ borderLeft: "1px solid white", paddingLeft: 10, paddingRight: 10 }}>These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</td>
                                </tr>
                            </tbody>
                        </table>

                        <p className="mt-4 pt-4">Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control and we cannot accept any liability for the third party’s compliance with its legal obligations. These cookies are likely to be analytical/performance cookies or targeting cookies:</p>
                        <p style={{color: "yellow"}}>[DETAILS OF THIRD PARTIES]</p>
                        <p>You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.</p>
                        <p>Except for essential cookies, all cookies will expire after <span style={{ color: "yellow" }}>[EXPIRATION PERIOD]</span></p>
                    </Col>
                </CardBody>
            </div>
        </>
    );
}

export default CookiePolicy;
