/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";
import ReactBSAlert from "react-bootstrap-sweetalert"
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import ReactPlayer from "react-player";
import defaultImage from "../../assets/img/header.jpg"
import MyModal from "components/Shared/MyModal";
import CreatePodcast from "./components/CreatePodcast";


import "../../assets/css/custom-css.css"

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input

} from "reactstrap";

function PodcastBlocking() {
    const navigation = useNavigate()
    const [podcastEpisodeId, setPodcastEpisodeId] = useState(null)
    const [podcastEpisodeReview, setPodcastEpisodeReview] = useState(null)
    const [alert, setAlert] = useState(null)

    const getPodcastEpisodeReview = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.PODCAST_EPISODE_SECTIONS.GET_ALL}?podcastEpisodeId=${podcastEpisodeId}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setPodcastEpisodeReview(res.data)

                }

            })
            .catch((err) => {
                console.error(err.response.data);
            });
    }

    const resetSections = () => {
        setPodcastEpisodeReview(null)
        setPodcastEpisodeId(null)
        //This is gross but scroll isn't working
        navigation("/admin/podcast-blocking")
        
    }

    const handleBlockClicked = () => {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handleBlockAction(podcastEpisodeId)}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, block!"
                cancelBtnText="Cancel"
                showCancel
                btnSize=""
            >
                You are about to block this episode.
            </ReactBSAlert>
        )
    }

    const handleBlockAction = async (id) => {
        let url = `${apiConfig.baseUrl}${apiConfig.PODCAST_EPISODE.BLOCK}?id=${podcastEpisodeId}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Episode blocked",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                    setAlert(null)
                    resetSections()
                } else {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Something went wrong",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                }

            })
            .catch((err) => {
                console.error(err.response.data);
            });
    } 

    const hideAlert = () => {
        setAlert(null)
    }

    const handleBackToPodcasts = () => {
        navigation("/admin/podcasts")
    }


    const userToken = getJWTToken().decoded.nameid
    const superUser = [1, 2, 3, 4, 6]
    const isSuperUser = superUser.includes(Number(userToken))

    return (
        <>
            {isSuperUser ? (<>
                {alert}
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card className="formStyle text-white">
                                <CardHeader>
                                    <Col>
                                        <Row>
                                            <CardTitle tag="h4">Podcast blocking</CardTitle>
                                                {/*<u style={{*/}
                                                {/*    color: "red",*/}
                                                {/*    textDecoration: "underline red",*/}
                                                {/*    cursor: "pointer"*/}
                                                {/*}} onClick={handleBackToPodcasts}>Back to podcasts</u>*/}
                                            
                                        </Row>

                                    </Col>
                                </CardHeader>
                                <CardBody className="pb-4">
                                    <Row className="d-flex justify-content-center">
                                        {podcastEpisodeReview != null ? (
                                            podcastEpisodeReview.length > 0 ? (
                                                podcastEpisodeReview.map((section) => {
                                                    return (

                                                        <Col sm="12" md="12" lg="8" className="pb-4 pt-4">
                                                            <p><strong>{section.name}</strong></p>
                                                            <Input className="placeholderWhite inputStyle reviewInput" value={section.content} type="textarea" style={{ minHeight: "150px", paddingLeft: 20, paddingRight: 20, color: "" }} />
                                                        </Col>
                                                    )
                                                })
                                            ) : <p>No episode found with this id</p>
                                        ) : (
                                                <Col sm="12">
                                                    <Col><p>Enter podcast Id</p></Col>
                                                    <Col sm="6" md="4" lg="4"><Input className="inputStyle" type="number" onChange={(e) => setPodcastEpisodeId(e.target.value)} /></Col>
                                                    <Col><button className="buttonStyle buttonPrimary mt-3"  value={podcastEpisodeId} onClick={() => getPodcastEpisodeReview()}>Search</button></Col>
                                            </Col>
                                        )}
                                    </Row>
                                </CardBody>
                                <CardFooter className="d-flex justify-content-center">
                                    {podcastEpisodeReview != null &&
                                        <Col sm="8">
                                            <Row className="d-flex justify-content-end">
                                                <button style={{ margin: !podcastEpisodeReview.length > 0 ? "auto" : ""}} className="buttonStyle buttonSecondary" onClick={() => resetSections()}>{podcastEpisodeReview.length > 0 ? "Cancel" : "Back"}</button>
                                                {podcastEpisodeReview.length > 0 && <button className="ml-4 buttonStyle buttonPrimary" onClick={() => handleBlockClicked()}>Block</button> }
                                            </Row>
                                        </Col>
                                    }
                                </CardFooter>

                            </Card>
                        </Col>
                    </Row>
                </div>
            </>) : <div className="content"><h2>You do not have permission to view this page</h2></div> }
        </>
    );
}

export default PodcastBlocking;
