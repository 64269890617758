import React, { useState, useEffect } from "react"
import apiConfig from "../../config/apiConfig"
import apiCall from "../../utils/apiCall"
import { useNavigate } from "react-router-dom"


const AdWizard = ({ wizardSteps, baseObject, setBaseObject, validateAdvertP1, getCredits, setModalPage, setIsMissingCredits, voices }) => {
    const [currentPage, setCurrentPage] = useState(0)
    const [isFirstPage, setIsFirstPage] = useState(true)
    const [isLastPage, setIsLastPage] = useState(false)
    const [isAdvertCreated, setIsAdvertCreated] = useState(false) // back to false
    const [isLoading, setIsLoading] = useState(false)
    const [isGenerating, setIsGenerating] = useState(false)
    const [isOwnAdvert, setIsOwnAdvert] = useState(false)
    const [isGenerate, setIsGenerate] = useState(true)

    const navigation = useNavigate()


    const handleNavigateWizard = async (isNext) => {
        //if (currentPage == 0 && baseObject.id == 0 && !isAdvertCreated) {
        //    setIsLoading(true)
        //    const isAdCreated = await handleAddAdvert()
        //    setIsLoading(false)
        //    if (!isAdCreated)
        //        return
        //} else if (currentPage == 2 && baseObject.id != 0 && isAdvertCreated) {
        //    setIsLoading(true)
        //    const isAdUpdated = await handleUpdateAdvert()
        //    if (!isAdUpdated)
        //        return
        //    setIsLoading(false)
        //}

        setCurrentPage(prevPage => {
            const newPage = isNext ? prevPage + 1 : prevPage - 1;
            return Math.max(0, Math.min(newPage, wizardSteps.length - 1));
        });
    };

    useEffect(() => {
        setIsFirstPage(currentPage === 0);
        setIsLastPage(currentPage === wizardSteps.length - 1);
    }, [currentPage, wizardSteps.length]);

    const handleAddAdvert = async () => {
        const copyObject = { ...baseObject }
        copyObject.categories = null

        const isValidAd = validateAdvertP1(copyObject)
        if (!isValidAd) {
            return
        }
        setIsGenerating(true)
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.ADD,
                "post", copyObject
            )
            if (resp.status === 200) {
                setIsAdvertCreated(true)
                setBaseObject({ ...baseObject, id: resp.data.id, content: resp.data.content, selectedContent: resp.data.content[0].id })
                getCredits()
                setIsGenerating(false)
                return true
            }
        } catch (error) {
            if (error.data = "Insufficient credits") {
                setIsMissingCredits(true)
                setModalPage("credits")
            }
            setIsGenerating(false)
            console.error(error.message)
        }
    }

    const [requiredMissing, setRequiredMissing] = useState([])
    const [invalidDateRange, setInvalidDateRange] = useState(false)

    const handleUpdateAdvert = async (ignoreValidation = false, isJustDownload = false) => {
        const copyObject = { ...baseObject }
        setRequiredMissing([])

        if (!ignoreValidation) {
            let tempReq = []
            if (copyObject.name == "") {
                tempReq.push("name")
            }

            if (!copyObject.runningFrom) {
                tempReq.push("runningFrom")
            }

            if (!copyObject.runningTo) {
                tempReq.push("runningTo")
            }

            if(!isJustDownload)
                if (copyObject.budget == 0) {
                    tempReq.push("budget")
                }

            // If required fields not entered
            if (tempReq.length > 0) {
                setRequiredMissing(tempReq)
                return false
            }

            //Check that dates are valid
            const st = new Date(copyObject.runningFrom)
            const end = new Date(copyObject.runningTo)

            if (st > end) {
                setInvalidDateRange(true)
                return false
            }



            copyObject.geoLocations = copyObject.geoLocations.length > 0 ? copyObject.geoLocations : null
            copyObject.age = copyObject.age.length > 0 ? copyObject.age : null
            copyObject.interests = copyObject.interests.length > 0 ? copyObject.interests : null
            copyObject.time = copyObject.time.length > 0 ? copyObject.time : null
            copyObject.adPositions = copyObject.adPositions.length > 0 ? copyObject.adPositions : null

        }
        copyObject.categories = copyObject.categories && copyObject.categories.length > 0 ? copyObject.categories.map((cat) => cat.id).join(", ") : ignoreValidation ? null : "0"

        try {
            const resp = await apiCall(
                apiConfig.ADVERT.UPDATE + `?id=${copyObject.id}`,
                "put", copyObject
            )
            if (resp.status === 200) {
                setCurrentPage(currentPage + 1)
                return true
            }
            return false
        } catch (error) {
            console.error(error.message)
        }
    }


    //Make the translations a bit easier to work with
    const base64ToBlob = (base64String, mimeType) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    };

    const createAudioUrl = (blob) => {
        return URL.createObjectURL(blob);
    };


    const handleGenerateTranslations = async (languageCodes) => {
        const copyObject = { ...baseObject }
        const usedContent = copyObject.content.find((content) => content.id === copyObject.selectedContent)

        const voiceId = voices.find((voice) => voice.voiceId == usedContent.voiceId).voiceAiValue

        setIsGenerating(true)
        //return

        try {
            const resp = await apiCall(
                apiConfig.ADVERT.ADD_TRANSLATIONS + `?languageCodes=${languageCodes}`,
                "post", usedContent
            )
            if (resp.status === 200) {
                setIsGenerating(false)
                return resp.data
            }
        } catch (error) {
            console.error(error.message)
        }

        setIsGenerating(false)

    }

    const [purchaseObject, setPurchaseObject] = useState(null)
    const [isPurchaseLoading, setIsPurchaseLoading] = useState(false)
    const [isPurchaseExist, setIsPurchaseExist] = useState(false)
    const [purchaseComplete, setPurchaseComplete] = useState(false)
    const [advertCost, setAdvertCost] = useState({
        isDownload: false,
        isNetwork: true,
    })

    const handleInitAdvertSpend = async () => {

        let totalDue = 0
        const downloadCost = 50
        const {isDownload, isNetwork } = advertCost

        if (isDownload) {
            totalDue = totalDue + downloadCost
        }

        if (isNetwork) {
            totalDue = totalDue + Number(baseObject.budget)
        }

        const tempProductObject = {
            transactionType: 2,
            advertId: baseObject.apiCall,
            productId: null,
            description: "Advert Spend",
            amount: totalDue
        }

        try {
            const resp = await apiCall(
                apiConfig.TRANSACTION.INIT_PURCHASE,
                "post", tempProductObject
            );
            if (resp.status === 200) {
                setPurchaseObject(resp.data)
                return true
            }
            return false

        } catch (error) {
            console.error(error.message);
        }
    }

    const handleNavigatePayment = async () => {
        setIsPurchaseLoading(true)
        const isAdMade = await handleUpdateAdvert(false, true)
        if (isAdMade) {
            const isPurchaseReady = await handleInitAdvertSpend()
            setIsPurchaseExist(isPurchaseReady)
            setIsPurchaseLoading(false)
        }
    }

    const handleFinishWizard = () => {
        navigation("/admin/ads")
    }

    useEffect(() => {
        const panel = document.querySelector('.main-panel');
        if (panel) {
            panel.scrollTop = 0;
        }
    }, [currentPage])


    const handleUploadOwnAdvert = async () => {

        if (baseObject.id != 0) {
            setCurrentPage(2)
            return
        }

        const copyObject = { ...baseObject }
        copyObject.categories = null

        
        setIsGenerating(true)
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.ADD,
                "post", copyObject
            )
            if (resp.status === 200) {
                const newAd = resp.data
                newAd.selectedContent = resp.data.content[0].id
                setBaseObject(newAd)
                getCredits()
                setIsGenerating(false)
                setCurrentPage(2)
                setIsAdvertCreated(true)
                return true
            }
        } catch (error) {
            if (error.data = "Insufficient credits") {
                setIsMissingCredits(true)
                setModalPage("credits")
            }
            setIsGenerating(false)
            console.error(error.message)
        }
    }

    const [isSkipForward, setIsSkipForward] = useState(false)
    useEffect(() => {
        if (isOwnAdvert) {
            if (currentPage == 1) {
                if (isSkipForward) {
                    setCurrentPage(2)
                } else {
                    setCurrentPage(0)
                }
                setIsSkipForward(!isSkipForward)
            }
        }
    }, [currentPage])


    return (
        <div style={{ minHeight: "410px" }}>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {wizardSteps && wizardSteps.length > 0 &&
                    wizardSteps.map((step, index) => {
                        return (
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: index === 0 || index === 3 ? "20%" : "30%" }} key={index}>
                                {index > 0 && (
                                    <div style={{
                                        flexGrow: 1,
                                        height: "2px",
                                        backgroundColor: "rgba(100, 100, 100, 0.3",
                                        flexShrink: 0,  // Prevents shrinking
                                        marginRight: "10px",  // Adjust as needed
                                        marginTop: -15
                                    }} />
                                )}

                                {/* Step content */}
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div style={{
                                        backgroundColor: currentPage >= index ? "rgb(243, 59, 48)" : "#404040",
                                        padding: 5,
                                        borderRadius: 35,
                                        minHeight: 35,
                                        maxHeight: 35,
                                        minWidth: 35,
                                        maxWidth: 35,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }} className="text-white">
                                        {index + 1}
                                    </div>
                                    <div className="">{step.title}</div>
                                </div>

                                {index < wizardSteps.length - 1 && (
                                    <div style={{
                                        flexGrow: 1,
                                        height: "2px",
                                        backgroundColor: "rgba(100, 100, 100, 0.3",
                                        flexShrink: 0,  // Prevents shrinking
                                        marginLeft: "10px",  // Adjust as needed
                                        marginTop: -15
                                    }} />
                                )}
                            </div>
                        );
                    })
                }
            </div>

            <div className="mt-2 mb-5">
                {wizardSteps && wizardSteps.length > 0 &&
                    React.cloneElement(wizardSteps[currentPage].component, { ...wizardSteps[currentPage], handleAddAdvert, handleGenerateTranslations, isGenerating, purchaseObject, isPurchaseLoading, isPurchaseExist, purchaseComplete, setPurchaseComplete, requiredMissing, invalidDateRange, advertCost, setAdvertCost, setIsOwnAdvert, isGenerate, setIsGenerate })
                }
            </div>
            <div style={{ position: "absolute", bottom: 0, width: "100%", display: "flex", justifyContent: "center", }}>
                <div style={{ display: "flex", width: "98%", justifyContent: isFirstPage ? "flex-end" : "flex-end", margin: 10, gap: 15, padding: 10 }}>
                    {!isFirstPage && !purchaseComplete && <button onClick={() => handleNavigateWizard(false)} type="submit" className="buttonStyle buttonPrimary">Back</button>}
                    {!isLastPage && isAdvertCreated && currentPage != 0 && currentPage != 2 && currentPage != 3 && <button onClick={() => handleNavigateWizard(true)} type="submit" className="buttonStyle buttonPrimary">Next</button>}
                    {isAdvertCreated && currentPage == 0 && <button onClick={() => handleUpdateAdvert(true)} type="submit" className="buttonStyle buttonPrimary">Next</button>}
                    {!isFirstPage && isAdvertCreated && currentPage == 2 && <button onClick={() => handleNavigatePayment()} type="submit" className="buttonStyle buttonPrimary">Next</button>}
                    {!isFirstPage && isAdvertCreated && currentPage == 3 && purchaseComplete && <button onClick={() => handleFinishWizard()} type="submit" className="buttonStyle buttonPrimary">Finish</button>}
                    {isFirstPage && isOwnAdvert && <button className="buttonStyle buttonPrimary" onClick={handleUploadOwnAdvert}>Next</button> }
                </div>
            </div>
        </div>
    )
}

export default AdWizard