/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";

import defaultImage from "../../assets/img/header.jpg"
import MyModal from "components/Shared/MyModal";
import CreatePodcast from "./components/CreatePodcast";
import ContactForm from "./components/ContactForm"

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input

} from "reactstrap";

function ContactUs() {

    return (
        <>

            <div className="content" style={{ marginTop: 78, paddingLeft: "5%", paddingRight: "5%" }}>
                <Row>
                    <Col md="12">
                        
                        
                            <CardBody className="mt-4 mb-4">
                                <Row>
                                    <Col className="text-center d-flex flex-column" lg="6" md="12">
                                        <Row className="flex-fill" style={{ alignItems: "center" }}>
                                        <Col style={{ display: "flex", height: "150px", width: "100px", alignItems: "flex-end" }}><p style={{ backgroundColor: "#F33B30", color: "white", fontWeight: 600, padding: 20, borderRadius: 25}}>"Wow such a great website"</p></Col>
                                            <Col style={{ display: "flex", height: "150px", width: "100px", alignItems: "flex-start", justifyContent: "center" }}><p style={{ backgroundColor: "grey", color: "white", fontWeight: 600, padding: 20, borderRadius: 25 }}>"You should add unicorns"</p></Col>
                                        </Row>
                                        <Row className="flex-fill">
                                            <Col className="text-center mt-4">
                                                <h3 className="text-white">What do <strong>you</strong> have to say?</h3>
                                            </Col>
                                        </Row>
                                        <Row className="flex-fill" style={{ alignItems: "center" }}>
                                            <Col style={{ display: "flex", height: "150px", width: "100px", alignItems: "flex-start", justifyContent: "center" }}><p style={{ backgroundColor: "grey", color: "white", fontWeight: 600, padding: 20, borderRadius: 25 }}>"More boxes? Hell yeah!"</p></Col>
                                        <Col style={{ display: "flex", height: "150px", width: "100px", alignItems: "flex-end" }}><p style={{ backgroundColor: "#F33B30", color: "white", fontWeight: 600, padding: 20, borderRadius: 25 }}>"John Johnson lives!"</p></Col>
                                        </Row>
                                    </Col>
                                <Col className="d-flex justify-content-center" lg="6" md="12">
                                        <ContactForm />
                                    </Col>
                                </Row>
                            </CardBody>
                        
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ContactUs;
