/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import AudioPlayerLight from "./AudioPlayerLight";

import AudioPlayerLightBusiness from "./AudioPlayerLightBusiness";
import defaultImage from "assets/img/header.jpg"
import klaxonLogo from "assets/img/klaxonImages/KlaxonLogoBlack.png"


// reactstrap components
import {
    Button,
    ButtonGroup,
    Container,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input,
    InputGroup,
    Tag,
    Tooltip,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem

} from "reactstrap";
function ClientLibrary() {
    const location = useLocation()
    const navigation = useNavigate()
    const { clientSlug } = useParams()
    const [clientData, setClientData] = useState(null)
    const [clientPodcasts, setClientPodcasts] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    const [selectedPodcastEpisode, setSelectedPodcastEpisode] = useState(null)
    const [podcastSelected, setPodcastSelected] = useState(false)
    const [selectedPodcastId, setSelectedPodcastId] = useState(null)
    const [podcastDuration, setPodcastDuration] = useState(0)
    const [selectedPodcastTitle, setSelectedPodcastTitle] = useState("")

    const [selectedPodcastImage, setSelectedPodcastImage] = useState("")
    const [selectedPodcast, setSelectedPodcast] = useState()
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)

    const [isAudioPlayerHidden, setIsAudioPlayerHidden] = useState(false)
    const [autoPlaySelected, setAutoPlaySelected] = useState(false)
    const toggleAudioVisibility = () => {
        const event = new CustomEvent("playerToggled", {
            detail: { mode: !isAudioPlayerHidden }
        });
        window.dispatchEvent(event)

        setIsAudioPlayerHidden(!isAudioPlayerHidden)
    }

    const handleSelectPodcast = (podcast) => {
        const { id, image } = podcast
        setSelectedPodcastId(id)
        let podcastImage = image !== null ? image : defaultImage
        setSelectedPodcastImage(podcastImage)
        //window.scrollTo(0, 0)
        setSelectedPodcast(podcast)
        setIsAudioPlayerHidden(false)
    }

    const [sortByObject, setSortByObject] = useState({
        order: "asc",
        sortBy: "dateCreated",
        isString: false
    })

    const [singleSelectSortBy, setSingleSelectSortBy] = useState({ value: 4, label: "Date Created (Newest First)" })
    const [singleSelectOptions, setSingleSelectOptions] = useState([
        { value: 1, label: "Title (Ascending)" },
        { value: 2, label: "Title (Descending)" },
        { value: 3, label: "Date Created (Oldest First)" },
        { value: 4, label: "Date Created (Newest First)" },
    ])

    const handleFilterSelect = (sortBySelection) => {
        setSingleSelectSortBy(sortBySelection)

        const order = sortBySelection % 2 === 0 ? "desc" : "asc"
        const sortBy = sortBySelection > 2 ? "dateCreated" : "name"
        const isString = sortBy === "name" ? true : false


        setSortByObject({
            ...sortByObject,
            order: order,
            sortBy: sortBy,
            isString: isString
        })
    }

    const getClientBySlug = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.CLIENT.GET_SINGLE_BY_SLUG}?slug=${clientSlug}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setClientData(res.data)
                    getClientPodcasts(res.data.id)
                }

            })
            .catch((err) => {
                console.error(err);
            });
    }

    const getClientEpisodes = (podcastData) => {
        if (podcastData && podcastData.length > 0) {
            let tempEpisodes = []
            podcastData.forEach((podcast) => {
                const episodeImage = podcast.image !== null ? podcast.image : defaultImage

                podcast.episodes.forEach((episode) => {
                    episode.image = episodeImage
                    tempEpisodes.push(episode)
                })

            })
            return tempEpisodes
        }
    }

    const sortList = (sortBy, podcasts) => {
        const { sortBy: filterBy, order, isString } = sortBy;
        const asc = order === "asc";

        // Ensure we are creating a new sorted array
        const sortedData = [...podcasts].sort((a, b) => {
            let itemA = a[filterBy];
            let itemB = b[filterBy];

            if (!isString) {
                itemA = new Date(`${itemA}Z`);
                itemB = new Date(`${itemB}Z`);
            } else {
                itemA = itemA.toLowerCase();
                itemB = itemB.toLowerCase();
            }

            if (asc) {
                if (itemA < itemB) return -1;
                if (itemA > itemB) return 1;
                return 0;
            } else {
                if (itemA > itemB) return -1;
                if (itemA < itemB) return 1;
                return 0;
            }
        });

        return sortedData;
    };

    // Was having some issues getting it to sort when first coming in, hard coded sort is the solution for now
    const initSort = (podcasts) => {
        const sortedData = [...podcasts].sort((a, b) => {
            let itemA = new Date(`${a.dateCreated}Z`);
            let itemB = new Date(`${b.dateCreated}Z`);


            if (itemA > itemB) return -1;
            if (itemA < itemB) return 1;
            return 0;
        });

        return sortedData
    }

    useEffect(() => {
        if (clientPodcasts && clientPodcasts.length > 0) {
            const newSort = sortList(sortByObject, clientPodcasts)
            setClientPodcasts(newSort)
            setSearchResults(newSort)
        }
    }, [sortByObject])

    const [mostRecent, setMostRecent] = useState(null)
    const getClientPodcasts = async (clientId) => {
        let url = `${apiConfig.baseUrl}${apiConfig.PODCAST.GET_ALL_BY_CLIENT}?clientId=${clientId}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const clientEpisodes = getClientEpisodes(res.data)
                    //const sortInitialData = sortList( sortByObject, clientEpisodes)
                    const sortInitialData = initSort(clientEpisodes)
                    setClientPodcasts(sortInitialData)
                    setSearchResults(sortInitialData)
                    setMostRecent(sortInitialData[0])
                }

            })
            .catch((err) => {
                console.error(err)
            });
    }

    const formatDate = (isoDate) => {
        const date = new Date(isoDate)
        return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`
    }

    const formatDuration = (duration) => {
        const clipDuration = duration.split(".")[0]
        const durationParts = clipDuration.split(':').map(part => parseInt(part));
        const hours = durationParts.length > 2 ? durationParts[0] : 0;
        const minutes = durationParts.length > 1 ? durationParts[durationParts.length - 2] : 0;
        const seconds = durationParts[durationParts.length - 1];
        let formattedDuration = '';

        if (hours > 0) {
            formattedDuration += hours.toString().padStart(2, '0') + ':';
        }

        formattedDuration += minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');

        return formattedDuration;
    }

    const formatSeconds = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const formattedHours = hours > 0 ? String(hours).padStart(2, '0') : '';
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        const formattedTime = [formattedHours, formattedMinutes, formattedSeconds]
            .filter((value) => value !== '')
            .join(':');

        return formattedTime;
    }

    const convertTimeSpan = (timeString) => {
        const [timePart, milliseconds] = timeString.split('.');
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        let totalSeconds = hours * 3600 + minutes * 60 + seconds;

        if (milliseconds) {
            totalSeconds += parseFloat(`0.${milliseconds}`);
        }

        return totalSeconds;
    };

    useEffect(() => {
        getClientBySlug()        
    }, [])

    useEffect(() => {  
        if(mostRecent!=null)
            handleSelectPodcast(mostRecent)
    }, [mostRecent])

    const handleSearchBar = (query) => {
        setSearchQuery(query)
        const tempData = [...clientPodcasts]
        const searchName = tempData.filter((e) => e.name.toLowerCase().includes(query.toLowerCase()))
        setSearchResults(Array.from(new Set([...searchName])))
    }


    const getPodcastEpisode = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.PODCAST_EPISODE.GET_BUSINESS_EPISODE}?id=${selectedPodcastId}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const podcastObject = {
                        podcastId: selectedPodcastId,
                        audioUrls: [res.data]
                    }

                    setSelectedPodcastEpisode(podcastObject)
                    setPodcastSelected(true)
                    const podcastName = clientPodcasts.find((podcast) => podcast.id === selectedPodcastId).name
                    setSelectedPodcastTitle(podcastName)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }

    useEffect(() => {
        if (selectedPodcastId != null) {
            getPodcastEpisode()
        }
    }, [selectedPodcastId])


    const getDuration = () => {
        let durationObject = { durationSeconds: 0, durationTimeSpan: "00:00:00" };
        selectedPodcastEpisode.audioUrls.forEach((e) => {
            if (e.canSeek === true) {
                durationObject.durationSeconds += convertTimeSpan(e.duration)
            }
        })
        durationObject.durationTimeSpan = formatSeconds(durationObject.durationSeconds)
        setPodcastDuration(durationObject)
    }

    useEffect(() => {
        if (selectedPodcastEpisode !== null) {
            getDuration()
        }
    }, [selectedPodcastEpisode])



    const handleDownloadClicked = async () => {
        const { id } = selectedPodcast
        const base64String = await getDownloadLink(id)

        if (/^[A-Za-z0-9+/]+={0,2}$/.test(base64String)) {
            const decodedData = atob(base64String);
            const dataArray = new Uint8Array(decodedData.length);

            for (let i = 0; i < decodedData.length; i++) {
                dataArray[i] = decodedData.charCodeAt(i);
            }

            const blob = new Blob([dataArray], { type: "audio/mpeg" });
            const link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.download = `${selectedPodcast.name}`;
            link.click();
        } else {
            console.error("Invalid base64 string");
        }
    }

    const getDownloadLink = async (id) => {

        let url = `${apiConfig.baseUrl}${apiConfig.PODCAST_EPISODE.DOWNLOAD_BUSINESS_EPISODE}?id=${id}`;
        let downloadURL
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    downloadURL = res.data
                }
            })
            .catch((err) => {
                //setError(err.response.data);
            });
        return downloadURL
    }

    const handleShareClicked = async () => {
        if (!navigator.clipboard) {
            return
        }

        const shareLink = await getShareLink()

        navigator.clipboard.writeText(shareLink)
            .then(() => {
                setIsTooltipOpen(true)
                setTimeout(() => {
                    setIsTooltipOpen(false)
                }, 1500)
            })
            .catch(err => {
                console.error("Something went wrong", err)
            })
    }

    const getShareLink = async () => {

        const { id } = selectedPodcast

        let url = `${apiConfig.baseUrl}${apiConfig.PODCAST_EPISODE.SHARE_BUSINESS_EPISODE}?id=${id}`;
        let shareURL
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    shareURL = res.data
                }
            })
            .catch((err) => {
                //setError(err.response.data);
            });
        return shareURL
    }

    const formatUrl = (url) => {
        // Check if url is a valid string before processing
        if (typeof url === "string" && url.trim() !== "") {
            if (!url.startsWith("http://") && !url.startsWith("https://")) {
                return `https://${url}`;
            }
            return url;
        }
        // Return an empty string or a placeholder if the URL is not defined
        return "#";
    };

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const toggle = () => setDropdownOpen((prevState) => !prevState)

    const handlePlayLatest = () => {
        handleSelectPodcast(mostRecent)
        setAutoPlaySelected(true)
    }

    const [isSocials, setIsSocials] = useState(false)
    const handleSocials = () => {
        const socialsArr = ["facebookUrl", "instagramUrl", "linkedInUrl", "tikTokUrl", "twitterUrl", "websiteUrl", "youtubeUrl", "email"]
        for (let i = 0; i < socialsArr.length; i++) {
            if (clientData[socialsArr[i]]) {
                setIsSocials(true);
                break;
            }
        }
    }

    useEffect(() => {
        if (clientData) {
            handleSocials()
        }
    }, [clientData])


    return (
        <div className="content" style={{ marginTop: 0 }}>
            {clientData ?

                <div style={{ display: "flex", flexDirection: "column", }}>
                    <div style={{ width: "100%", margin: "auto", display: "flex", flexDirection: "column", gap: 25 }} className="rtbGrad2">

                        <div style={{ backgroundColor: "#fff" } }>
                            <div style={{ padding: 15,  }} className="clientNav">
                                <a href="https://www.klaxon.ai/" rel="noreferrer">
                                    <img src={klaxonLogo} style={{ width: 150, marginLeft: 15, cursor: "pointer" }} />

                                </a>
                                <div style={{color: "#000"} }>
                                    <a href="https://appt.link/meet-with-klaxon-ai-RipEPVZy/web-conference" target="_blank" rel="noreferrer">
                                        <button className="whiteButton clientPageButton" style={{ border: "1px solid rgba(0,0,0,0.1)" }}><span>Book a demo</span></button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", backgroundColor: "", gap: 40, paddingBottom: 25, paddingLeft: "5%", paddingRight: "5%" }} className="clientContain">
                            <div style={{width: "33%"} }>
                                <img src={clientData.image} style={{ maxWidth: "100%", height: "auto", objectFit: "contain", borderRadius: "" }} />
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 25}}>
                                <div style={{ justifyContent: "", paddingBottom: "3%", display: !isSocials ? "none" : "flex" }} className="clientLinks">
                                    <div style={{ display: "flex", gap: 25, fontSize: 20 }}>
                                        {clientData.websiteUrl &&
                                            <a
                                                href={formatUrl(clientData.websiteUrl)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: "none" }}
                                                className="unhide"
                                            >
                                                <div style={{ display: "flex", gap: 15, alignItems: "center", cursor: "pointer" }}>
                                                    <div style={{ backgroundColor: "#fff", borderRadius: "50%", minHeight: 35, minWidth: 35, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <i className="fa fa-globe gradient-text" />
                                                    </div>
                                                    <p className="clientLinkText" style={{ fontSize: 16, margin: 0 }}>{clientData.websiteUrl}</p>
                                                </div>
                                            </a>
                                        }
                                        {clientData.email &&
                                            <a
                                                href={`mailto:${clientData.email}`}
                                                style={{ textDecoration: "none" }}
                                                className="unhide"
                                            >
                                                <div style={{ display: "flex", gap: 15, alignItems: "center", cursor: "pointer" }}>
                                                    <div
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            borderRadius: "50%",
                                                            minHeight: 35,
                                                            minWidth: 35,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <i className="fa fa-envelope gradient-text" />
                                                    </div>
                                                    <p className="clientLinkText" style={{ fontSize: 16, margin: 0 }}>{clientData.email}</p>
                                                </div>
                                            </a>
                                        }
                                    </div>
                                    <div style={{ display: "flex", gap: 25, fontSize: 20 }}>
                                        <div style={{ }} className="clientIconLinks">
                                            {clientData.email &&
                                                <a
                                                    href={`mailto:${clientData.email}`}
                                                    style={{ textDecoration: "none" }}
                                                    className="hiddenStart"
                                                >
                                                    <div style={{ display: "flex", gap: 15, alignItems: "center", cursor: "pointer" }}>
                                                        <div
                                                            style={{
                                                                backgroundColor: "#fff",
                                                                borderRadius: "50%",
                                                                minHeight: 35,
                                                                minWidth: 35,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <i className="fa fa-envelope gradient-text" />
                                                        </div>
                                                    </div>
                                                </a>
                                            }
                                            {clientData.websiteUrl &&
                                                <a
                                                    href={formatUrl(clientData.websiteUrl)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: "none" }}
                                                    className="hiddenStart"
                                                >
                                                    <div style={{ display: "flex", gap: 15, alignItems: "center", cursor: "pointer" }}>
                                                        <div style={{ backgroundColor: "#fff", borderRadius: "50%", minHeight: 35, minWidth: 35, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <i className="fa fa-globe gradient-text" />
                                                        </div>
                                                    </div>
                                                </a>
                                            }
                                            {clientData.instagramUrl &&
                                                <a
                                                    href={formatUrl(clientData.instagramUrl)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            borderRadius: "50%",
                                                            minHeight: 35,
                                                            minWidth: 35,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <i className="fa fa-instagram gradient-text" />
                                                    </div>
                                                </a>
                                            }
                                            {clientData.youtubeUrl &&
                                                <a
                                                    href={formatUrl(clientData.youtubeUrl)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            borderRadius: "50%",
                                                            minHeight: 35,
                                                            minWidth: 35,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <i className="fa fa-youtube gradient-text" />
                                                    </div>
                                                </a>
                                            }
                                            {clientData.facebookUrl &&
                                                <a
                                                    href={formatUrl(clientData.facebookUrl)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            borderRadius: "50%",
                                                            minHeight: 35,
                                                            minWidth: 35,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <i className="fa-brands fa-facebook-f gradient-text" />
                                                    </div>
                                                </a>
                                            }
                                            {clientData.twitterUrl &&
                                                <a
                                                    href={formatUrl(clientData.twitterUrl)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            borderRadius: "50%",
                                                            minHeight: 35,
                                                            minWidth: 35,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <i className="fa-brands fa-x-twitter gradient-text" />
                                                    </div>
                                                </a>
                                            }
                                            {clientData.linkedInUrl &&
                                                <a
                                                    href={formatUrl(clientData.linkedInUrl)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            borderRadius: "50%",
                                                            minHeight: 35,
                                                            minWidth: 35,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <i className="fa fa-linkedin gradient-text" />
                                                    </div>
                                                </a>
                                            }
                                            {clientData.tikTokUrl &&
                                                <a
                                                    href={formatUrl(clientData.tikTokUrl)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            borderRadius: "50%",
                                                            minHeight: 35,
                                                            minWidth: 35,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <i className="fab fa-tiktok gradient-text" />
                                                    </div>
                                                </a>
                                            }
                                        </div>

                                    </div>
                                </div>
                                <h2 style={{ margin: 0, fontWeight: "bold" }}>{clientData.name}</h2>
                                <p style={{ margin: 0, fontSize: 18 }}>{clientData.description}</p>
                                <button className="whiteButton clientPageButton" onClick={() => handlePlayLatest() }>Play Latest Episode</button>
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#fff", display: "flex", flexDirection: "column" }}>
                        <div style={{ width: "90%", margin: "auto", color: "#323232", display: "flex", flexDirection: "column", paddingTop: 50 }}>
                            <div>
                                <h2>Latest Podcast Episodes</h2>
                            </div>
                            <Row style={{ display: "", justifyContent: "", width: "", alignItems: "center" }}>
                                <Col style={{ }} lg="6" sm="6" xs="12">
                                    <input className="navSearch" placeholder="Search" value={searchQuery} onChange={(e) => handleSearchBar(e.target.value)} style={{ border: "1px solid rgba(0, 0, 0, 0.2)", padding: 10, paddingLeft: 15, borderRadius: 15, minHeight: 50, width: "100%" }} />
                                </Col>
                                <Col style={{ display: "flex", width: "", justifyContent: "", backgroundColor: "" }} className="moveSortBy">
                                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                        <DropdownToggle className="editDropdown">
                                            <h3 style={{ margin: 0 }}>Sort By</h3>
                                            <i className="fa fa-filter" />
                                        </DropdownToggle>
                                        <DropdownMenu className="editDropdownMenu">
                                            {singleSelectOptions.map((option) => {
                                                return (
                                                    <DropdownItem onClick={() => handleFilterSelect(option.value) }>{option.label}</DropdownItem>
                                                )
                                            }) }
                                        </DropdownMenu>
                                    </Dropdown>

                                </Col>
                            </Row>
                        </div>

                        <Row style={{
                            display: "",
                            flexWrap: "",
                            justifyContent: "space-between",
                            width: "90%",
                            margin: "auto",
                            marginTop: 50,
                            marginBottom: 20
                        }}
                            className="toggleCenter"
                        >


                            {clientPodcasts.length > 0 ?
                                searchResults.map((e) => {
                                    return (
                                        <Col lg="3" md="4" sm="6" xs="12" style={{display: "flex", alignItems: "center", justifyContent: "center"} }>
                                            <div className="lightmodeCard2 mb-2" style={{ maxHeight: 300, minHeight: 300, cursor: "pointer", marginTop: 10, marginBottom: 10 }} onClick={() => handleSelectPodcast(e)} >
                                                <img src={e.image ? e.image : null} className="lightmodeCardImage2" style={{ maxWidth: "100%", height: "auto", objectFit: "contain", borderRadius: "" }} />
                                                <div style={{ width: "90%" }}>
                                                    <div style={{ display: "flex", justifyContent: "flex-start", backgroundColor: "", width: "100%" }}>
                                                        <p style={{ fontWeight: "bold", marginTop: 5, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "#000" }}>{e.name}</p>
                                                        {/*<p style={{ fontWeight: "bold", marginRight: 5, marginTop: 5 }}>{formatDate(e.dateCreated)}</p>*/}
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", color: "#8B8A8A" }}>
                                                        {/*<p style={{ fontWeight: "", marginBottom: 5 }}>Episode {e.episodeNumber}</p>*/}
                                                        <p style={{ fontWeight: "", marginBottom: 5 }}>{formatDuration(e.duration)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                                : null}
                            {clientPodcasts.length > 0 && searchResults.length % 4 !== 0 &&
                                Array(4 - (searchResults.length % 4))
                                    .fill(null)
                                    .map((_, index) => (
                                        <Col key={`filler-${index}`} lg="3" style={{ visibility: "hidden", maxHeight: 300, minHeight: 300 }}>
                                            {/* Empty filler to maintain grid alignment */}
                                        </Col>
                                    ))
                            }

                        </Row>

                    </div>
                    <div className={`clientAudioPlayerContainer ${isAudioPlayerHidden && "clientAudioPlayerHide"}`}>
                        <div style={{
                            width: "100%",
                            height: 20,
                            backgroundColor: "",
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "#000",
                            paddingRight: 25,
                            position: "absolute",  // Added to position above without affecting flow
                            top: -20,                // Position at the top of the parent container
                            left: 0,               // Align to the left of the parent container
                        }}>
                            <i className={`${!isAudioPlayerHidden ? "fa fa-chevron-down" : "fa fa-chevron-up" }` } onClick={toggleAudioVisibility} />
                        </div>
                        <AudioPlayerLightBusiness podcast={selectedPodcastEpisode} podcastDuration={podcastDuration} isAdvertPlaying={false} selectedPodcastImage={selectedPodcastImage} selectedPodcastTitle={selectedPodcastTitle} autoPlaySelected={autoPlaySelected} setAutoPlaySelected={setAutoPlaySelected} />
                    </div>
                </div>
                : <Col className="text-center"><p>This client cannot be found</p></Col>
            }
        </div>
    );
}

export default ClientLibrary;
