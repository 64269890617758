/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import ReactPlayer from "react-player";

// reactstrap components
import { FormGroup, Input, Row, Col, Label, Button, UncontrolledTooltip } from "reactstrap";
import GBFlag from "assets/img/flags/GB.png"
import ESFlag from "assets/img/flags/ES.png"
import CNFlag from "assets/img/flags/CN.png"
import FRFlag from "assets/img/flags/FR.png"
import USFlag from "assets/img/flags/US.png"
import IEFlag from "assets/img/flags/IE.png"
import AUFlag from "assets/img/flags/AU.png"
import CAFlag from "assets/img/flags/CA.png"
import NZFlag from "assets/img/flags/NZ.png"
import SAFlag from "assets/img/flags/SA.png"

import tooltipText from "../../../config/tooltipText";


const Step3 = React.forwardRef((props, ref) => {

    const [holdFlags, setHoldFlags] = useState({
        english: GBFlag,
        zh: CNFlag,
        fr: FRFlag,
        es: ESFlag,
        us: USFlag,
        irish: IEFlag,
        australian: AUFlag,
        canadian: CAFlag,
        southafrican: SAFlag,
        newzealand: NZFlag
    })
    const getFlag = (code) => {
        const formatCode = code.toLowerCase()
        const getCountry = formatCode.split(" ").slice(0, -1).join("")
        return holdFlags[getCountry]
    }

    const [searchResults, setSearchResults] = useState([])
    const [singleSelectLanguages, setSingleSelectLanguages] = useState({
        value: 11,
        label: "English"
    })
    const [selectedVoiceName, setSelectedVoiceName] = useState(null)
    const [alert, setAlert] = useState(null)
    const [selectedResult, setSelectedResult] = useState(0)

    const handleLanguagesChange = (value) => {
        setSingleSelectLanguages(value)
        props.setBaseObject({ ...props.baseObject, defaultLanguageId: value.value })

        const originalSearch = props.dropdownVoices
       
        setSearchResults(originalSearch.filter((e) => e.voiceAiValue.substring(0, 2) === value.aiValue))
    }

    const handleSelectVoice = (voice,e) => {
        if (!e.target.closest('.nc-headphones')) {
            props.setBaseObject({ ...props.baseObject, defaultVoiceId: voice.voiceId })
            setAlert(null)
            setSelectedVoiceName(voice.voiceName)
        }
    }

    const handleGetPreview = (voice, e) => {
        if (e.target.closest('.nc-headphones')) {
            props.setVoicePreviewModel({
                ...props.voicePreviewModel,
                voiceId: voice.voiceId,
                voiceStyleId: voice.voiceStyleId,
                languageId: singleSelectLanguages.value,
            })
        } 
    }


    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            if (selectedVoiceName != null) {
                props.setTitleNumber(props.titleNumber + 1)
                return true
            }
            setAlert("Please select a voice")
            return false
        },
    }));

    useEffect(() => {
        setSearchResults(props.dropdownVoices.filter((e) => e.voiceAiValue.substring(0, 2) === "en"))
    }, [props.dropdownVoices])


    //console.log(searchResults)
  return (
    <>
          <Col>
              <h4 className="text-center" style={{ color: "#F33B30" }}><strong>{alert}</strong></h4>
              {props.voicePreview.length > 0 &&
                  <ReactPlayer url={props.voicePreview}
                      playing={true}
                      controls={false}
                      height='0px'
                      width='0px'
                      className="podcastPlayer"
                      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  />    
              }
              <Row className="justify-content-center pb-4">
                  <Col  sm="12" md="12" lg="6">
                      <FormGroup>
                          <Label>Languages</Label>
                          <Button
                              className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                              id="tooltip8089663901212"
                              size="sm"
                              style={{ backgroundColor: "white" }}
                          >
                              <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                          </Button>
                          <UncontrolledTooltip
                              delay={0}
                              target="tooltip8089663901212"
                              style={{ backgroundColor: "#F33B30", color: "white" }}
                          >
                              <p style={{ margin: 0 }}>{tooltipText.VOICE.LANGUAGE}</p>
                          </UncontrolledTooltip>
                      </FormGroup>
                      <Select
                          className="react-select "
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={singleSelectLanguages}
                          onChange={(value) => handleLanguagesChange(value)}
                          options={props.dropdownLanguages}
                      />
                  </Col>
              </Row>

              <Row className="justify-content-center">
                  <Col>
                      {props.dropdownVoices.length > 0 ? (
                          
                          <Col className="justify-content-center">
                              {searchResults.map((item, index) => (
                                  index % 2 === 0 && (
                                      <Row key={index} className="justify-content-center">
                                          <Col sm="12" md="12" lg="5" className="p-2 d-flex justify-content-between align-items-center inputStyle mb-2 voicesContainer" style={{ backgroundColor: props.baseObject.defaultVoiceId === item.voiceId ? "#F33B30" : "", color: ""}} onClick={(e) => handleSelectVoice(item, e) }>
                                              <img src={getFlag(item.voiceName)} /> <p style={{margin: 0} }>{item.voiceName}</p> <i style={{ cursor: "pointer" }} className="nc-icon nc-headphones p-2 ignoreButton" onClick={(e) => handleGetPreview(item, e) } />
                                          </Col>
                                          {searchResults[index + 1] && (
                                              <Col sm="12" md="12" lg="5" className="p-2 d-flex justify-content-between align-items-center inputStyle mb-2" style={{ backgroundColor: props.baseObject.defaultVoiceId === searchResults[index + 1].voiceId ? "#F33B30" : "", color: ""}} onClick={(e) => handleSelectVoice(searchResults[index +1], e)} >
                                                  <img src={getFlag(searchResults[index + 1].voiceName)} /> <p style={{ margin: 0 }}>{searchResults[index + 1].voiceName}</p> <i style={{ cursor: "pointer" }} className="nc-icon nc-headphones p-2" onClick={(e) => handleGetPreview(searchResults[index + 1], e) } />
                                              </Col>
                                          )}
                                      </Row>
                                  )
                              ))}
                          </Col>

                          
                      ) : null}
                  </Col>
              </Row>
              <Row>
                  <Col className="text-center">
                      <h4>Selected voice: {selectedVoiceName != null ? selectedVoiceName : "No selection"}</h4>
                  </Col>
              </Row>
      </Col>
    </>
  );
});

export default Step3;
