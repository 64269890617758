/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Buttons from "views/components/Buttons.js";
import Calendar from "views/Calendar.js";
//import Dashboard from "views/Dashboard.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import GridSystem from "views/components/GridSystem.js";
import Icons from "views/components/Icons.js";
import LockScreen from "views/pages/LockScreen.js";
import Login from "views/pages/Login.js";
import Notifications from "views/components/Notifications.js";
import Panels from "views/components/Panels.js";
import ReactTables from "views/tables/ReactTables.js";
import Register from "views/pages/Register.js";
import RegularForms from "views/forms/RegularForms.js";
import RegularTables from "views/tables/RegularTables.js";
import SweetAlert from "views/components/SweetAlert.js";
import Timeline from "views/pages/Timeline.js";
import Typography from "views/components/Typography.js";
import UserProfile from "views/pages/UserProfile.js";
import ValidationForms from "views/forms/ValidationForms.js";
import VectorMap from "views/maps/VectorMap.js";
import Widgets from "views/Widgets.js";
//import Wizard from "views/forms/Wizard.js";

//Klaxon
import Podcasts from "./views/klaxonai/Podcasts";
import ProfilePage from "./views/klaxonai/UserProfile"
import Library from "./views/klaxonai/Library";
import RegisterPage from "./views/klaxonai/Register"
import Feedback from "./views/klaxonai/Feedback"
import PodcastBlocking from "./views/klaxonai/PodcastBlocking"
//Klaxon site nav
import LoginPage from "./views/klaxonai/Login"
import AboutUs from "./views/klaxonai/AboutUs";
import Pricing from "./views/klaxonai/Pricing";
import Advertise from "./views/klaxonai/Advertise";
import ContactUs from "./views/klaxonai/ContactUs";
import ForgotPassword from "./views/klaxonai/ForgotPassword";
import Privacy from "./views/klaxonai/Privacy";
import PodcastPlayer from "./views/klaxonai/PodcastPlayer";
import ReviewPage from "./views/klaxonai/ReviewPage";
import Payment from "./views/klaxonai/Payment"
import Dashboard from "./views/klaxonai/Dashboard"
import CreatePodcast from "./views/klaxonai/CreatePodcast";
import Ads from "./views/klaxonai/Ads";
import TermsAndConditions from "./views/klaxonai/TermsAndConditions";
import ComingSoon from "./views/klaxonai/ComingSoon";
import Verification from "./views/klaxonai/Verification"
import ResetPassword from "./views/klaxonai/ResetPassword";
import HowItWorks from "views/klaxonai/HowItWorks";
import FAQ from "./views/klaxonai/FAQ";

import ManageBusinesses from "./views/klaxonai/ManageBusinesses";

import CookiePolicy from "./views/klaxonai/CookiePolicy";
import ModernSlaveryStatement from "./views/klaxonai/ModernSlaveryStatement";
import AcceptableUsePolicy from "./views/klaxonai/AcceptableUsePolicy";
import TermsOfWebsiteUse from "./views/klaxonai/TermsOfWebsiteUse";

//Lightmode test
import LibraryLight from "./views/klaxonai/lightmodeTest/LibraryLight";
import PodcastPlayerLight from "./views/klaxonai/lightmodeTest/PodcastPlayerLight";
import ClientLibrary from "./views/klaxonai/lightmodeTest/ClientLibrary";

import Voices from "./views/klaxonai/Voices"

// New test stuff
import AdsWithWizard from "./views/klaxonai/AdsWithWizard";
import Music from "./views/klaxonai/Music";
import MusicTags from "./views/klaxonai/MusicTag";

import Home from "./views/klaxonai/lightmodeTest/Home"
import KlaxonWaitList from "./views/klaxonai/lightmodeTest/KlaxonWaitList";


const routes = [
    // {
    //     path: "/home",
    //     name: "Home",
    //     icon: "nc-icon nc-bank",
    //     component: <LibraryLight />,
    //     layout: "/light",
    // },
    {
        path: "/home",
        name: "Home",
        icon: "nc-icon nc-bank",
        component: <Home />,
        layout: "/light",
    },
    {
        path: "/waitlist",
        name: "Wait List",
        icon: "nc-icon nc-bank",
        component: <KlaxonWaitList />,
        layout: "/light",
    },
    {
        path: "/player",
        name: "player",
        icon: "nc-icon nc-bank",
        component: <PodcastPlayerLight />,
        layout: "/light",
    },
    {
        path: "/company/:clientSlug",
        name: "Podcasts",
        icon: "nc-icon nc-bank",
        component: <ClientLibrary />,
        layout: "/light",
    },
    //{
    //    path: "/home",
    //    name: "Home",
    //    icon: "nc-icon nc-bank",
    //    component: <Home />,
    //    layout: "/",
    //},
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "nc-icon nc-layout-11",
        component: <Dashboard />,
        layout: "/admin",
    },
    {
        path: "/music-library",
        name: "Music",
        icon: "nc-icon nc-layout-11",
        component: <Music />,
        layout: "/admin",
    },
    {
        path: "/music-tag",
        name: "Music Tags",
        icon: "nc-icon nc-layout-11",
        component: <MusicTags />,
        layout: "/admin",
    },
    {
        path: "/voices",
        name: "Voices",
        icon: "nc-icon nc-layout-11",
        component: <Voices />,
        layout: "/light",
    },
    {
        path: "/manage-businesses",
        name: "Manage Businesses",
        icon: "nc-icon nc-layout-11",
        component: <ManageBusinesses />,
        layout: "/admin",
    },
    {
        path: "/ads",
        name: "Ads",
        icon: "nc-icon nc-bank",
        component: <Ads />,
        layout: "/admin",
    },
     {
         path: "/create-ad",
         name: "Advert Wizard",
         icon: "nc-icon nc-bank",
         component: <AdsWithWizard />,
         layout: "/admin",
     },
    {
        path: "/create-podcast",
        name: "Create Podcast",
        icon: "nc-icon nc-bank",
        component: <CreatePodcast />,
        layout: "/admin",
    },
    {
        path: "/podcast-blocking",
        name: "Podcast Blocking",
        icon: "nc-icon nc-lock-circle-open",
        component: <PodcastBlocking />,
        layout: "/admin",
    },
    {
        path: "/feedback",
        name: "Feedback",
        icon: "nc-icon nc-bank",
        component: <Feedback />,
        layout: "/admin",
    },
    //{
    //    path: "/player",
    //    name: "Podcast Player",
    //    icon: "nc-icon nc-bank",
    //    component: <PodcastPlayer />,
    //    layout: "/",
    //},
    {
        path: "/privacy",
        name: "Privacy",
        icon: "nc-icon nc-bank",
        component: <Privacy />,
        layout: "/light",
    },
    {
        path: "/terms-and-conditions",
        name: "Terms and Conditions",
        icon: "nc-icon nc-bank",
        component: <TermsAndConditions />,
        layout: "/light",
    },
    {
        path: "/cookie-policy",
        name: "Cookie Policy",
        icon: "nc-icon nc-bank",
        component: <CookiePolicy />,
        layout: "/",
    },
    {
        path: "/modern-slavery-statement",
        name: "Modern Slavery Statement",
        icon: "nc-icon nc-bank",
        component: <ModernSlaveryStatement />,
        layout: "/",
    },
    {
        path: "/acceptable-use-policy",
        name: "Acceptable Use Policy",
        icon: "nc-icon nc-bank",
        component: <AcceptableUsePolicy />,
        layout: "/",
    },
    {
        path: "/terms-of-website-use",
        name: "Terms of Website Use",
        icon: "nc-icon nc-bank",
        component: <TermsOfWebsiteUse />,
        layout: "/",
    },
    {
        path: "/library",
        name: "Library",
        icon: "nc-icon nc-bank",
        component: <Library />,
        layout: "/",
    },
    {
        path: "/how-it-works",
        name: "How It Works",
        icon: "nc-icon nc-bank",
        component: <HowItWorks />,
        layout: "/",
    },
    {
        path: "/about-us",
        name: "About Us",
        icon: "nc-icon nc-bank",
        component: <AboutUs />,
        layout: "/",
    },
    {
        path: "/faq",
        name: "FAQ",
        icon: "nc-icon nc-bank",
        component: <FAQ />,
        layout: "/",
    },
    {
        path: "/pricing",
        name: "Pricing",
        icon: "nc-icon nc-bank",
        component: <Pricing />,
        layout: "/",
    },
    {
        path: "/advertise",
        name: "Advertise",
        icon: "nc-icon nc-bank",
        component: <Advertise />,
        layout: "/",
    },
    {
        path: "/contact-us",
        name: "Contact Us",
        icon: "nc-icon nc-bank",
        component: <ContactUs />,
        layout: "/",
    },
    {
        path: "/podcasts",
        name: "Podcasts",
        icon: "nc-icon nc-headphones",
        component: <Podcasts />,
        layout: "/admin",
    },
    {
        path: "/review",
        name: "Review",
        icon: "nc-icon nc-bank",
        component: <ReviewPage />,
        layout: "/admin",
    },
    {
        path: "/profile",
        name: "Profile",
        icon: "nc-icon nc-bank",
        component: <ProfilePage />,
        layout: "/admin",
    },
    {
        path: "/login",
        name: "Login",
        mini: "L",
        component: <LoginPage />,
        layout: "/auth",
    },
    {
        path: "/verification",
        name: "Verification",
        mini: "L",
        component: <Verification />,
        layout: "/auth",
    },
    {
        path: "/forgot-password",
        name: "Forgot Password",
        mini: "L",
        component: <ForgotPassword />,
        layout: "/auth",
    },
    {
        path: "/reset-password",
        name: "Reset Password",
        mini: "L",
        component: <ResetPassword />,
        layout: "/auth",
    },
    {
        path: "/register",
        name: "Register",
        mini: "R",
        component: <RegisterPage />,
        layout: "/auth",
    },
    {
        collapse: true,
        name: "Pages",
        icon: "nc-icon nc-book-bookmark",
        state: "pagesCollapse",
        views: [
            {
                path: "/timeline",
                name: "Timeline",
                mini: "T",
                component: <Timeline />,
                layout: "/admin",
            },
            //{
            //  path: "/login",
            //  name: "Login",
            //  mini: "L",
            //  component: <Login />,
            //  layout: "/auth",
            //},
            //{
            //  path: "/register",
            //  name: "Register",
            //  mini: "R",
            //  component: <Register />,
            //  layout: "/auth",
            //},
            {
                path: "/lock-screen",
                name: "LockScreen",
                mini: "LS",
                component: <LockScreen />,
                layout: "/auth",
            },
            {
                path: "/user-profile",
                name: "UserProfile",
                mini: "UP",
                component: <UserProfile />,
                layout: "/admin",
            },
        ],
    },
    {
        collapse: true,
        name: "Components",
        icon: "nc-icon nc-layout-11",
        state: "componentsCollapse",
        views: [
            {
                path: "/buttons",
                name: "Buttons",
                mini: "B",
                component: <Buttons />,
                layout: "/admin",
            },
            {
                path: "/grid-system",
                name: "Grid System",
                mini: "GS",
                component: <GridSystem />,
                layout: "/admin",
            },
            {
                path: "/panels",
                name: "Panels",
                mini: "P",
                component: <Panels />,
                layout: "/admin",
            },
            {
                path: "/sweet-alert",
                name: "Sweet Alert",
                mini: "SA",
                component: <SweetAlert />,
                layout: "/admin",
            },
            {
                path: "/notifications",
                name: "Notifications",
                mini: "N",
                component: <Notifications />,
                layout: "/admin",
            },
            {
                path: "/icons",
                name: "Icons",
                mini: "I",
                component: <Icons />,
                layout: "/admin",
            },
            {
                path: "/typography",
                name: "Typography",
                mini: "T",
                component: <Typography />,
                layout: "/admin",
            },
        ],
    },
    {
        collapse: true,
        name: "Forms",
        icon: "nc-icon nc-ruler-pencil",
        state: "formsCollapse",
        views: [
            {
                path: "/regular-forms",
                name: "Regular Forms",
                mini: "RF",
                component: <RegularForms />,
                layout: "/admin",
            },
            {
                path: "/extended-forms",
                name: "Extended Forms",
                mini: "EF",
                component: <ExtendedForms />,
                layout: "/admin",
            },
            {
                path: "/validation-forms",
                name: "Validation Forms",
                mini: "VF",
                component: <ValidationForms />,
                layout: "/admin",
            },
            //{
            //    path: "/wizard",
            //    name: "Wizard",
            //    mini: "W",
            //    component: <Wizard />,
            //    layout: "/admin",
            //},
        ],
    },
    {
        collapse: true,
        name: "Tables",
        icon: "nc-icon nc-single-copy-04",
        state: "tablesCollapse",
        views: [
            {
                path: "/regular-tables",
                name: "Regular Tables",
                mini: "RT",
                component: <RegularTables />,
                layout: "/admin",
            },
            {
                path: "/extended-tables",
                name: "Extended Tables",
                mini: "ET",
                component: <ExtendedTables />,
                layout: "/admin",
            },
            {
                path: "/react-tables",
                name: "React Tables",
                mini: "RT",
                component: <ReactTables />,
                layout: "/admin",
            },
        ],
    },
    {
        collapse: true,
        name: "Maps",
        icon: "nc-icon nc-pin-3",
        state: "mapsCollapse",
        views: [
            {
                path: "/google-maps",
                name: "Google Maps",
                mini: "GM",
                component: <GoogleMaps />,
                layout: "/admin",
            },
            {
                path: "/full-screen-map",
                name: "Full Screen Map",
                mini: "FSM",
                component: <FullScreenMap />,
                layout: "/admin",
            },
            {
                path: "/vector-map",
                name: "Vector Map",
                mini: "VM",
                component: <VectorMap />,
                layout: "/admin",
            },
        ],
    },
    // {
    //     path: "/widgets",
    //     name: "Widgets",
    //     icon: "nc-icon nc-box",
    //     component: <Widgets />,
    //     layout: "/admin",
    // },
    // {
    //     path: "/charts",
    //     name: "Charts",
    //     icon: "nc-icon nc-chart-bar-32",
    //     component: <Charts />,
    //     layout: "/admin",
    // },
    // {
    //     path: "/calendar",
    //     name: "Calendar",
    //     icon: "nc-icon nc-calendar-60",
    //     component: <Calendar />,
    //     layout: "/admin",
    // },
    {
        path: "/subscribers",
        name: "Subscribers",
        icon: "nc-icon nc-bell-55",
        component: <ComingSoon />,
        layout: "/admin",
    },
    {
        path: "/newsletters",
        name: "Newsletters",
        icon: "nc-icon nc-email-85",
        component: <ComingSoon />,
        layout: "/admin",
    },
];

export default routes;
