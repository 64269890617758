import { Col, Row, Label, FormGroup, Input, Button, Spinner } from "reactstrap"
import { useState, useEffect } from "react"
import Select from "react-select";
import StripeGenericContainer from "../stripeGeneric/StripeGenericContainer";
import apiCall from "../../../utils/apiCall"
import apiConfig from "../../../config/apiConfig"

const WizardAdPayment = (props) => {
    const { purchaseObject, isPurchaseLoading, isPurchaseExist, purchaseComplete, setPurchaseComplete, advertCost, baseObject } = props
    

    const [downloadLink, setDownloadLink] = useState(null)
    const [fileName, setFileName] = useState('')

    useEffect(() => {
        if (baseObject.selectedContent !== 0 && baseObject.content && baseObject.content.length > 0) {
            const selectedContent = baseObject.content.find((con) => con.id === baseObject.selectedContent)
            if (selectedContent) {
                const downloadUrl = selectedContent.urls[0].url
                const title = baseObject.name || 'Your Podcast'
                setFileName(title.replace(/\s+/g, '-').toLowerCase() + '.mp3')
                setDownloadLink(downloadUrl)
            }
        }
    }, [baseObject]);

    const handleDownload = async () => {
        try {
            const response = await fetch(downloadLink)
            const blob = await response.blob()

            const url = URL.createObjectURL(blob)

            const link = document.createElement('a')
            link.href = url
            link.download = fileName
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(url)
        } catch (error) {
            console.error('Download failed:', error)
        }
    };

    const handlePurchase = async () => {
        //console.log("here")
        try {
            const resp = await apiCall(
                apiConfig.TRANSACTION.CONFIRM_PURCHASE + `?id=${purchaseObject.id}&advertId=${baseObject.id}`,
                "put"
            );
            if (resp.status === 200) {
                //console.log("Success")
                setPurchaseComplete(true)
            }

        } catch (error) {
            console.error(error.message);
        }
    }


    return (
        <div className="pb-5">

            {!purchaseComplete ?
                purchaseObject ?
                    <Col>
                        <Row>
                            <Col>
                                <div style={{display: "flex", flexDirection: "column", width: "40%"}}>
                                    {advertCost.isNetwork &&
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p>Ad Budget:</p>
                                            <p>&pound;{advertCost.isDownload ? purchaseObject?.amountDue - 50 : purchaseObject?.amountDue}</p>
                                        </div>
                                    }
                                    {advertCost.isDownload &&
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p>Downloading of the advert:</p>
                                            <p>&pound;50</p>
                                        </div>
                                    }
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <p>Total:</p>
                                        <p>&pound;{purchaseObject?.amountDue}</p>
                                    </div>
                                </div>
                            </Col>

                            <Col>
                                {/*<Row>*/}
                                {/*    <Col>*/}
                                {/*        <FormGroup className="">*/}
                                {/*            <Label className="labelText">Email</Label>*/}

                                {/*            <Input className="inputStyle placeholderWhite" placeholder="Enter Email" />*/}

                                {/*        </FormGroup>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {/*<Row>*/}
                                {/*    <Col>*/}
                                {/*        <FormGroup className="">*/}
                                {/*            <Label className="labelText">Cardholder name</Label>*/}

                                {/*            <Input className="inputStyle placeholderWhite" placeholder="Full name on card" />*/}

                                {/*        </FormGroup>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {/*<Row>*/}
                                {/*    <Col>*/}
                                {/*        <FormGroup>*/}
                                {/*            <Label className="labelText">Country</Label>*/}
                                {/*            <Select*/}
                                {/*                className="react-select "*/}
                                {/*                classNamePrefix="react-select"*/}
                                {/*                name="singleSelect"*/}
                                {/*                options={[{ value: 1, label: "United Kingdom" }, { value: 2, label: "United States" }, { value: 3, label: "Spain" },]}*/}
                                {/*            />*/}
                                {/*        </FormGroup>*/}
                                {/*        <FormGroup className="">*/}
                                {/*            <Label className="labelText">Postcode</Label>*/}

                                {/*            <Input className="inputStyle placeholderWhite" placeholder="Postcode" />*/}

                                {/*        </FormGroup>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <Row>
                                    <Col>
                                        <Label className="labelText">Card</Label>
                                        <StripeGenericContainer baseObject={purchaseObject} isAdSpend={true} setPurchaseComplete={setPurchaseComplete} handlePurchase={handlePurchase} />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col> : <Col className="text-center mt-4">
                        <Spinner />
                    </Col>
                
                : 
            <Col>
                <Row>
                        <Col className="text-center mt-4">
                            {advertCost.isNetwork &&
                                <p>We'll send you an email when your campaign starts</p>
                                }
                            {advertCost.isDownload &&
                                <button className="buttonStyle buttonPrimary" onClick={handleDownload}>Download</button>    
                            }
                        </Col>
                </Row>
                </Col>
            }
        </div>
    )
}

export default WizardAdPayment 