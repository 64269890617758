import { CardBody, CardHeader, CardFooter, CardTitle, Row, Col, FormGroup, Input, Button, Label, UncontrolledTooltip } from "reactstrap"

import { useState, useEffect } from 'react'
import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import Swal from "sweetalert2";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import ReactDatetime from "react-datetime";
import MP3Upload from "../../../components/CustomUpload/MP3Upload";
import Select from "react-select";
import tooltipText from "../../../config/tooltipText";
import WizardPlayer from "../AdWizardComps/audioEditorComp/WizardPlayer";



function CreateAd(props) {
    
    const [audioUrls, setAudioUrls] = useState([])

    const handleUrls = () => {
        const chosenContent = props.baseObject.content.find((con) => con.id == props.baseObject.selectedContent).urls
        setAudioUrls(chosenContent)
    }

    useEffect(() => {
        if (props.baseObject?.content && props.baseObject?.content.length > 0) {
            handleUrls()
        }
    }, [props.baseObject])
    
    return (
        <>
            <div className="content">
                <CardBody>
                    <Row>
                        <Col>

                            <FormGroup>
                                <Label className="labelText">Name</Label>
                                <Button
                                    className="btn-icon dropdownIgnore tooltipHover"
                                    color="danger"
                                    id="tooltip808966390111"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390111"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                    
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.ADVERTS.ADVERT_NAME}</p>
                                </UncontrolledTooltip>
                                <Input className="inputStyle placeholderWhite" type="text" value={props.baseObject.name} onChange={(e) => props.setBaseObject({ ...props.baseObject, name: e.target.value })} />
                                {props.missingFields.includes("name") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>Please enter a name for the advert</p> : null }
                            </FormGroup>

                            {/*<FormGroup>*/}
                            {/*    <Label className="labelText">Description</Label>*/}
                            {/*    <Button*/}
                            {/*        className="btn-icon btn-neutral dropdownIgnore tooltipHover"*/}
                            {/*        color="danger"*/}
                            {/*        id="tooltip808966390122"*/}
                            {/*        size="sm"*/}
                            {/*        style={{ backgroundColor: "white" }}*/}
                            {/*    >*/}
                            {/*        <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />*/}
                            {/*    </Button>*/}
                            {/*    <UncontrolledTooltip*/}
                            {/*        delay={0}*/}
                            {/*        target="tooltip808966390122"*/}
                            {/*        style={{ backgroundColor: "#F33B30", color: "white" }}*/}
                            {/*    >*/}
                            {/*        <p style={{ margin: 0 }}>{tooltipText.ADVERTS.ADVERT_DESCRIPTION}</p>*/}
                            {/*    </UncontrolledTooltip>*/}
                            {/*    <Input className="inputStyle placeholderWhite" type="textarea" value={props.baseObject.description} onChange={(e) => props.setBaseObject({ ...props.baseObject, description: e.target.value })} />*/}
                            {/*</FormGroup>*/}

                           

                            {/*<Row>*/}
                                

                            {/*    <Col>*/}
                            {/*        <FormGroup>*/}
                            {/*            <Label className="labelText">{props.baseObject.fileType === "wav" ? "WAV" : "MP3"} File</Label>*/}
                            {/*            <Button*/}
                            {/*                className=" btn-icon btn-neutral dropdownIgnore tooltipHover"*/}
                                            
                            {/*                id="tooltip80896639011133"*/}
                            {/*                size="sm"*/}
                                            
                            {/*            >*/}
                            {/*                <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />*/}
                            {/*            </Button>*/}
                            {/*            <UncontrolledTooltip*/}
                            {/*                delay={0}*/}
                            {/*                target="tooltip80896639011133"*/}
                            {/*                style={{ backgroundColor: "#F33B30", color: "white" }}*/}
                            {/*            >*/}
                            {/*                <p style={{ margin: 0 }}>{tooltipText.ADVERTS.MP3}</p>*/}
                            {/*            </UncontrolledTooltip>*/}
                                       
                            {/*        </FormGroup>*/}
                            {/*        <MP3Upload baseObject={props.baseObject} setBaseObject={props.setBaseObject} />*/}
                            {/*        {props.missingFields.includes("mp3") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>Please include an advert</p> : null}*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            

                        </Col>

                        <Col>
                            

                            <FormGroup className="mr-4">
                                <Label className="labelText">Budget</Label>
                                <Button
                                    className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                   
                                    id="tooltip808966390111323"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390111323"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.ADVERTS.BUDGET}</p>
                                </UncontrolledTooltip>
                                <Input className="inputStyle placeholderWhite" placeholder="0" type="number" value={props.baseObject.budget} onChange={(e) => props.setBaseObject({ ...props.baseObject, budget: e.target.value })} />
                                {props.missingFields.includes("budget") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>Budget must be more than 0</p> : null}
                            </FormGroup>

                            {/*<FormGroup>*/}
                            {/*    <Label className="labelText">CPM Prices</Label>*/}
                            {/*    <Button*/}
                            {/*        className="btn-icon btn-neutral dropdownIgnore tooltipHover"*/}
                                    
                            {/*        id="tooltip80896639011133343"*/}
                            {/*        size="sm"*/}
                            {/*        style={{ backgroundColor: "white" }}*/}
                            {/*    >*/}
                            {/*        <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />*/}
                            {/*    </Button>*/}
                            {/*    <UncontrolledTooltip*/}
                            {/*        delay={0}*/}
                            {/*        target="tooltip80896639011133343"*/}
                            {/*        style={{ backgroundColor: "#F33B30", color: "white" }}*/}
                            {/*    >*/}
                            {/*        <p style={{ margin: 0 }}>{tooltipText.ADVERTS.CPM_PRICES}</p>*/}
                            {/*    </UncontrolledTooltip>*/}
                            {/*    {props.missingFields.includes("cpm") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>At least one CPM field must be more than 0</p> : null}*/}
                            {/*</FormGroup>*/}

                            {/*<Row>*/}
                            {/*    <Label md="3" className="labelText">Pre roll</Label>*/}
                            {/*    <Col md="9">*/}
                            {/*        <FormGroup>*/}
                            {/*            <Input className="inputStyle placeholderWhite" placeholder="0" type="number" value={props.baseObject.cpmPre} onChange={(e) => props.setBaseObject({ ...props.baseObject, cpmPre: Number(e.target.value) })} />*/}
                            {/*        </FormGroup>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*    <Label md="3" className="labelText">Mid roll</Label>*/}
                            {/*    <Col md="9">*/}
                            {/*        <FormGroup>*/}
                            {/*            <Input className="inputStyle placeholderWhite" placeholder="0" type="number" value={props.baseObject.cpmMid} onChange={(e) => props.setBaseObject({ ...props.baseObject, cpmMid: Number(e.target.value) })} />*/}
                            {/*        </FormGroup>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*    <Label md="3" className="labelText">Post roll</Label>*/}
                            {/*    <Col md="9">*/}
                            {/*        <FormGroup>*/}
                            {/*            <Input className="inputStyle placeholderWhite" placeholder="0" type="number" value={props.baseObject.cpmPost} onChange={(e) => props.setBaseObject({ ...props.baseObject, cpmPost: Number(e.target.value) })} />*/}
                            {/*        </FormGroup>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {audioUrls && audioUrls.length > 0 &&
                                audioUrls.map((aud) => {
                                    return (
                                        <WizardPlayer fileURL={aud.url} changeScheme={true} />
                                    )
                                })    
                            }
                        </Col>
                    </Row>
                    
                </CardBody>
            </div>

        </>
    )
}

export default CreateAd