import { useState, useEffect } from "react"
import { Collapse, CardBody, Card } from "reactstrap"

const CollapseForm = ({ title, children, setOpen = null, startOpen = false }) => {
    const [isOpen, setIsOpen] = useState(startOpen);
    const toggle = () => {
        if (isOpen) {
            setTimeout(() => setIsOpen(!isOpen), 5)
            if (setOpen != null) {
                setOpen(false)
            }

        } else {
            setIsOpen(!isOpen)
        }
    };

    useEffect(() => {
        if (isOpen && setOpen != null) {
            setTimeout(() => {
                setOpen(true), 500
            })
        }
    }, [isOpen])

    return (
        <div className="mb-3">
            <div onClick={toggle} style={{ backgroundColor: "rgba(225, 225, 225, 0.3)", padding: "5px 10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p style={{ margin: 0 }}>{title}</p>
                {!isOpen ? <i className="fas fa-chevron-down" /> : <i className="fas fa-chevron-up" />}
            </div>
            <Collapse isOpen={isOpen}>
                <div>
                    {children}
                </div>
            </Collapse>
        </div>
    )
}

export default CollapseForm